//一般用药史
import {
  computed,
  defineComponent,
  getCurrentInstance,
  onMounted,
  reactive,
  toRefs,
  watch,
} from "@vue/composition-api";
import moment from "moment";
import {
  ApiGeneralMedicationHistorySave,
  ApiDeleteMedicine,
  ApiAddMedicine,
} from "@/apis/index";

interface Module {
  pharmacyForm: object;

  test: () => void;
}
function pharmacyFun(ins: any, MedicalHistoryDetailsFun: any) {
  const pharmacyModule: any = reactive({
    pharmacyForm: {
      data: [
        {
          hismed_id: "", // 一般用药史id
          hismed_type: "", // 分类
          hismed_description: "", //服药说明
          hismed_name: "", // 药物名称
          hismed_iseffect: "", // 是否有效
          hismed_indate: "", // 开始服药时间
          hismed_isgoon: "", // 是否继续服用
          hismed_dose: "", // 日均总剂量
          hismed_offdate: "", // 停药时间
          hismed_isadverse: "", // 有无不良反应
          hismed_adversecnt: "--", // 不良反应内容
          hismed_freq: "", // 服药方案
          hismed_offreason: "", // 停药原因
          hismed_note: "", // 备注
        },
      ],
    } as any,

    //药名
    drugName: [
      "多巴丝肼",
      "普拉克索",
      "吡贝地尔",
      "雷沙吉兰",
      "司来吉兰",
      "沙芬酰胺",
      "金刚烷胺",
      "盐酸苯海索",
      "溴隐亭",
      "卡左双多巴",
      "恩他卡朋",
      "托卡朋",
      "奥匹卡朋",
      "罗替高汀",
      "罗匹尼罗",
      "斯达力沃/达灵复",
      "麦角乙脲",
      "培高利特",
      "氟桂利嗪",
      "桂利嗪",
      "利血平",
      "复方降压0号",
      "黛力新",
      "氟哌啶醇",
      "维思通",
      "奋乃静",
      "舒必利",
      "安律凡",
      "胺碘酮",
      "氟西汀",
      "帕罗西汀",
      "文拉法辛",
      "舍曲林",
      "奥氮平",
      "喹硫平",
      "其它",
    ],
    pspDrugName: [
      "氟桂利嗪",
      " 桂利嗪",
      " 利血平",
      " 复方降压0号",
      " 黛力新",
      " 氟哌啶醇",
      " 维思通",
      " 奋乃静",
      " 舒必利",
      " 安律凡",
      " 胺碘酮",
      " 氟西汀",
      " 帕罗西汀",
      " 文拉法辛",
      " 舍曲林",
      " 奥氮平",
      " 喹硫平",
      "其它",
    ],
    //服药说明
    specification: [
      "餐前半小时",
      "餐前一小时",
      "餐后半小时",
      "餐后俩小时",
      "其它",
    ],
    pspSpecification: [
      "餐前半小时",
      "餐前一小时",
      "餐后半小时",
      "餐后两小时",
      "其它",
    ],
    //不良反应内容
    adverseReaction: [
      "无",
      "恶心",
      "呕吐",
      "头晕",
      "幻觉",
      "智力下降",
      "运动并发症",
      "其它",
    ],

    // 停药原因
    stopDrug: ["无", "不良反应", "药效不佳", "费用", "药物撤市", "其它"],
    pharmacyDrawer: false,
    //新增
    addPharmacy() {
      const formRef = ins.$refs["pharmacyRuleForm"] as any;
      formRef.validate((valid: boolean) => {
        if (valid) {
          const arr = {
            //一般用药史
            hismed_id: "", // 一般用药史id
            user_id: Number(ins.$route.query.user_id),
            hismed_type: "", // 分类
            hismed_description: "", //服药说明
            hismed_name: "", // 药物名称
            hismed_iseffect: "", // 是否有效
            hismed_indate: "", // 开始服药时间
            hismed_isgoon: "", // 是否继续服用
            hismed_dose: "", // 日均总剂量
            hismed_offdate: "", // 停药时间
            hismed_isadverse: "", // 有无不良反应
            hismed_adversecnt: "", // 不良反应内容
            hismed_freq: "", // 服药方案
            hismed_offreason: "", // 停药原因
            hismed_note: "", // 备注
          };
          ins.pharmacyForm.data.push(arr);
          // ins.flags();
        }
      });
    },
    reducPharmacye(index: number) {
      const h = ins.$createElement;
      ins
        .$msgbox({
          title: "提示",
          message: h("p", undefined, [
            h("img", {
              attrs: {
                src: require("../../../../../assets/img/icon-confirm-tips.png"),
              },
            }),
            h("span", { class: "message_text" }, " 确认删除吗？ "),
          ]),
          showCancelButton: true,
          confirmButtonText: "确 认",
          cancelButtonText: "取 消",
          cancelButtonClass: "message_cancel",
          confirmButtonClass: "message_confirm",
          closeOnClickModal: false,
        })
        .then(() => {
          ins.pharmacyForm.data.splice(index, 1);
        })
        .catch(() => {
          console.log("取消");
        });
    },

    deleteMedicine(medicineId: number) {
      const h = ins.$createElement;
      ins
        .$msgbox({
          title: "提示",
          message: h("p", undefined, [
            h("img", {
              attrs: {
                src: require("../../../../../assets/img/icon-confirm-tips.png"),
              },
            }),
            h("span", { class: "message_text" }, " 确认删除吗？ "),
          ]),
          showCancelButton: true,
          confirmButtonText: "确 认",
          cancelButtonText: "取 消",
          cancelButtonClass: "message_cancel",
          confirmButtonClass: "message_confirm",
          closeOnClickModal: false,
        })
        .then(() => {
          ApiDeleteMedicine(medicineId).then((res: any) => {
            if (res.success) {
              //
              ins.$message.success("删除成功！");
              pharmacyModule.pharmacyDrawer = false;
              // //详情页面数据接口调用
              MedicalHistoryDetailsFun();
            }
          });
        })
        .catch(() => {
          console.log("取消");
        });
    },

    //抽屉open事件
    pharmacyClick(pharmacyEditForm: any) {
      pharmacyModule.pharmacyForm = JSON.parse(
        JSON.stringify(pharmacyEditForm)
      );

      pharmacyModule.pharmacyDrawer = true;
      setTimeout(() => {
        (ins.$refs.pharmacyRuleForm as any).clearValidate();
      }, 20);
    },
    //保存
    savePharmacy() {
      const formRef = ins.$refs["pharmacyRuleForm"] as any;
      let arr: any = [];
      formRef.validate((valid: boolean) => {
        if (valid) {
          arr = JSON.parse(JSON.stringify(pharmacyModule.pharmacyForm.data));
          for (let index = 0; index < arr.length; index++) {
            const Val = arr[index];
            Val.user_id = Number(ins.$route.query.user_id);
            if (Val.hismed_indate != undefined && Val.hismed_indate != "") {
              Val.hismed_indate = new Date(Val.hismed_indate).getTime() + "";
            }
            if (Val.hismed_offdate != undefined && Val.hismed_offdate != "") {
              Val.hismed_offdate = new Date(Val.hismed_offdate).getTime() + "";
            }
          }
          const param = arr;
          ApiGeneralMedicationHistorySave(param).then((res) => {
            if (res.success) {
              //
              ins.$message.success("修改成功！");
              pharmacyModule.pharmacyDrawer = false;
              // //详情页面数据接口调用
              MedicalHistoryDetailsFun();
            }
          });
        } else {
          console.log("未通过");
        }
      });
    },

    addMedicine() {
      ins.addLoading = true;
      const formRef = ins.$refs["pharmacyRuleForm"] as any;
      let arr: any = [];
      formRef.validate((valid: boolean) => {
        if (valid) {
          arr = JSON.parse(JSON.stringify(pharmacyModule.pharmacyForm.data));
          for (let index = 0; index < arr.length; index++) {
            const Val = arr[index];
            Val.user_id = Number(ins.$route.query.user_id);
          }
          const param = arr;
          ApiAddMedicine(param[0]).then((res) => {
            if (res.success) {
              //
              ins.$message.success("新增成功！");
              pharmacyModule.pharmacyDrawer = false;
              // //详情页面数据接口调用
              MedicalHistoryDetailsFun();
            }
          });
        } else {
          console.log("未通过");
        }
      });
      ins.addLoading = false;
    },
    pharmacyCanelBtn() {
      // ins.confirmDialogVisible = true;
      pharmacyModule.pharmacyDrawer = false;
      setTimeout(() => {
        (ins.$refs.pharmacyRuleForm as any).clearValidate();
      }, 20);
    },
    pharmacyRadio(property: string, content: string, index: number) {
      if (pharmacyModule.pharmacyForm.data[index][property] == content) {
        pharmacyModule.pharmacyForm.data[index][property] = "";
      } else {
        pharmacyModule.pharmacyForm.data[index][property] = content;
      }
      pharmacyModule.pharmacyForm = { ...pharmacyModule.pharmacyForm };
    },
  });
  return {
    pharmacyModule,
  };
}
export { pharmacyFun };
