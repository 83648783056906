















































































































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import {
  ApiGetVideoList,
  ApiUpVideoList,
  ApiDelVideoList,
  //上传文件到oss
  ApiInstallImg,
  ApiAddNote,
  ApiObjectRecordsDelete,
} from "@/apis";
import { parseTime } from "@/utils";
import { Base64 } from "js-base64";
import fileDownload from "js-file-download";
import axios from "axios";
import { DirectoryEnum } from "@/constants";
import { uploadFile, getSignUrlBatch } from "@/utils/alioss";
import * as utils from "@/utils";

@Component({
  filters: {
    parseTimeFilter(time: any, str: string) {
      if (time) {
        return parseTime(time, str);
      } else {
        return "-";
      }
    },
  },
})
export default class VideoData extends Vue {
  dialogVisible = false;
  dialogVisible2 = false;
  loading = false;
  loadings = false;
  // 是否显示进度条
  videoFlag = false;
  // 进度条进度
  videoUploadPercent = 0;
  //上传的附件信息 - 反馈单模块
  uploadBoxShow: any = true;
  enclosures: any = [];
  ws_image: any = [];
  doctor_image: any = [];
  doctor_screening: any = [];
  patient_image: any = [];
  ws_images: any = [];
  doctor_images: any = [];
  doctor_screenings: any = [];
  patient_images: any = [];
  handleExceed() {
    return 1;
  }
  uploadHttpRequestPicturex() {
    return 1;
  }
  // 上传前校验
  beforeAvatarUpload(file: any) {
    const isJpg = file.type == "image/jpg";
    const isPng = file.type == "image/png";
    const isJpeg = file.type == "image/jpeg";
    const isMp4 = file.type == "video/mp4";
    const isMov = file.type == "video/quicktime";
    console.log(file.type, "file.type");
    if (isJpg || isMp4 || isJpeg || isPng || isMov) {
      return new Promise((resolve) => {
        const isCan = file.size / 1024 / 1024 < 100;
        const isVideo =
          file.type == "video/mp4" || file.type == "video/quicktime";
        const isJPG = file.type === "image/jpeg";
        const isPNG = file.type === "image/png";
        const isPng = file.type == "image/png";
        const fileSize = file.size / 1024; //换成kb
        let bool = false;
        if (isCan) {
          if (isJPG || isPNG || isVideo || isPng) {
            bool = true;
          }
          if (bool) {
            if (isVideo) {
              return resolve(file);
            } else {
              //如果格式符合要求，但是size过大，对图片进行处理
              const image = new Image();
              let resultBlob: any = "";
              image.src = URL.createObjectURL(file);
              image.onload = () => {
                resultBlob = this.compressUpload(image, fileSize, 200); //Blob
                resolve(resultBlob);
              };
            }
          }
        } else {
          this.$message.warning("超出文件大小！");
          return Promise.reject;
        }
      });
    } else {
      this.$message.warning("格式不支持");
      return false;
    }
  }
  // 压缩图片
  compressUpload(image: any, fileSize: number, ratio: number): any {
    const canvas = document.createElement("canvas"); //创建画布元素
    const ctx = canvas.getContext("2d");
    const width = image.width;
    const height = image.height;
    canvas.width = width;
    canvas.height = height;
    let compressRatio: any;
    if (fileSize < 200) {
      compressRatio = 1;
    } else {
      compressRatio = ratio / fileSize;
    }

    if (ctx === null) {
      this.$message.error("画布创建失败");
    } else {
      ctx.fillStyle = "#fff";
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(image, 0, 0);
      const compressData = canvas.toDataURL("image/jpeg", compressRatio); //等比压缩
      const blobImg = this.dataURItoBlob(compressData); //base64转Blob

      if (blobImg.size > 200 * 1024 && compressRatio < 1) {
        return this.compressUpload(image, fileSize, ratio - 30);
      }
      return blobImg;
    }
  }
  // base64 转 blob
  dataURItoBlob(data: any) {
    let byteString;
    if (data.split(",")[0].indexOf("base64") >= 0) {
      byteString = atob(data.split(",")[1]); //转二进制
    } else {
      byteString = unescape(data.split(",")[1]);
    }
    const mimeString = data.split(",")[0].split(":")[1].split(";")[0];
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i += 1) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], { type: mimeString });
  }

  async created() {
    const res = await this.getVideoList();
    if (res) {
      this.ws_image = this.ws_images.slice(0, 5);
    }
  }

  // 获取详情
  async getVideoList() {
    this.loading = true;
    const database_id = localStorage.getItem("database_id");
    const res = await ApiGetVideoList({ database_id });
    if (res.success) {
      this.patient_images = res.data.patient_image;
      const doctorRaw = res.data.doctor_image;
      this.doctor_screenings = res.data.doctor_screening;
      this.ws_images = res.data.ws_image;
      this.patient_image = this.patient_images.slice(0, 6);
      const temp = doctorRaw;
      const processedImages = [];
      for (const image of temp) {
        if (image.resource_path.indexOf("pins-document") > -1) {
          try {
            const item = await this.imageUrlAuth(image.resource_path);
            const result = {
              resource_id: image.resource_id,
              createdate: image.createdate,
              resource_path: image.resource_path || null,
              resource_path_auth: item || null,
              doctor_note: image.doctor_note || null,
            };
            processedImages.push(result);
          } catch (error) {
            console.error("图片加载失败", error);
          }
        } else {
          const result = {
            resource_id: image.resource_id,
            createdate: image.createdate,
            resource_path: image.resource_path || null,
            resource_path_auth: image.resource_path || null,
            doctor_note: image.doctor_note || null,
          };
          processedImages.push(result);
        }
      }
      this.doctor_image = processedImages.slice(0, 6);
      this.doctor_images = processedImages;
      this.doctor_screening = this.doctor_screenings.slice(0, 6);
      this.ws_image = this.ws_images;
      setTimeout(() => {
        this.loading = false;
      }, 500);
      return true;
    }
  }
  // 上传成功的回调
  uploadSuccess() {
    return 1;
  }

  // 进度条钩子
  uploadVideoProcess(file: any, fileList: any) {
    if (file.status === "success") {
      this.videoFlag = true; //进度条显示
      const interval = setInterval(() => {
        if (this.videoUploadPercent >= 100) {
          clearInterval(interval);
          return;
        }
        this.videoUploadPercent += 1; //进度条进度
      }, 20);
    }
  }
  // 上传附件--图片-----问题分析模块
  async uploadHttpRequestPicture(params: any) {
    const file = params.file;
    const fileName = file.name;
    this.uploadHttpRequest(params);
  }

  // 上传 给上传图片和文件的函数使用
  //upload_dir: `picture/${
  async uploadAttachment(file: File) {
    const fileName = new Date().getTime().toString();
    const fileExt = file.name.split(".").pop();

    const res = await uploadFile(
      DirectoryEnum.PICTRUE,
      file,
      fileName + "." + fileExt,
      `/${
        JSON.parse(localStorage.getItem("doctorLoginInfo") as any).id
      }/${localStorage.getItem("database_id")}`
    );
    return res;
  }

  // 接收具体路径path的返回值；
  async uploadHttpRequest(params: any) {
    const file = params.file;
    const path = (await this.uploadAttachment(file)) as any;
    if (path) {
      // ... 处理具体的业务逻辑; 调用api等...
      if (!this.loadings) {
        const data = await ApiUpVideoList({
          database_id: localStorage.getItem("database_id"),
          resource_path: path,
        });
        if (data.success) {
          this.videoFlag = false;
          this.videoUploadPercent = 0;
          this.getVideoList();
        }
      }
    }
  }

  //获取文件后缀名
  getExtension(name: string) {
    return name.substring(name.lastIndexOf("."));
  }

  // 删除视频或者图片
  delPci(item: any) {
    console.log("item=", item);
    this.$confirm("确认要删除么?", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
      .then(async () => {
        if (item.resource_path) return;
        const result = await ApiObjectRecordsDelete({
          object_key_list: [item.resource_path],
          user_id: utils.get("userId"),
        });
        const id = item.id;
        const res = await ApiDelVideoList({ id });
        if (res.success) {
          this.getVideoList();
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        }
      })
      .catch((err) => {
        return err;
      });
  }

  // 折叠盒子
  closeBox(num: any) {
    if (num == 4) {
      this.patient_image = this.patient_images.slice(0, 6);
    } else if (num == 2) {
      this.doctor_image = this.doctor_image.slice(0, 6);
    } else if (num == 3) {
      this.doctor_screening = this.doctor_screenings.slice(0, 6);
    } else {
      this.ws_image = this.ws_images.slice(0, 5);
      this.uploadBoxShow = false;
    }
  }
  // 展开盒子
  openBox(num: any) {
    if (num == 4) {
      this.patient_image = this.patient_images;
    } else if (num == 2) {
      this.doctor_image = this.doctor_images;
    } else if (num == 3) {
      this.doctor_screening = this.doctor_screenings;
    } else {
      this.ws_image = this.ws_images;
      this.uploadBoxShow = true;
    }
  }
  // 判断是视频么
  isViodeOrPic(url: any) {
    if (!url) return "未知";

    const parts = url.toLowerCase().split("?");
    const cleanUrl = parts[0];
    const extension = cleanUrl.split(".").pop();

    if (["mp4", "mov"].includes(extension)) {
      return "视频";
    } else if (["jpg", "png", "jpeg"].includes(extension)) {
      return "照片";
    } else {
      return "未知";
    }
  }
  VideoUrl = "";
  SrcUrl = "";
  //在线预览视频
  videoClick(val: any) {
    console.log(val, "cal");

    this.dialogVisible = true;
    this.downloadText = "下载视频";
    this.downName = val.doctor_note;
    // this.url = `https://pins-gateway.pinsdev.com/kkfileview/onlinePreview?url=${encodeURIComponent(
    //   Base64.encode(url)
    // )}`;
    this.VideoUrl = val.resource_path || val.file_src;
    // window.open(
    //   `https://pins-gateway.pinsdev.com/kkfileview/onlinePreview?url=${encodeURIComponent(
    //     Base64.encode(url)
    //   )}`
    // );
  }
  videoClickAuth(val: any) {
    this.dialogVisible2 = true;
    this.downloadText = "下载视频";
    this.downName = val.doctor_note;
    this.VideoUrl = val.resource_path_auth || val.file_src;
  }
  downloadText: any = "";
  //在线图片
  imgClick(val: any) {
    this.dialogVisible = true;
    this.SrcUrl = val.resource_path || val.file_src;
    console.log(val, "cal");
    this.downloadText = "下载图片";
    this.downName = val.doctor_note;
    // window.open(
    //   `https://pins-gateway.pinsdev.com/kkfileview/onlinePreview?url=${encodeURIComponent(
    //     Base64.encode(url)
    //   )}`
    // );
  }
  imagesClickAuth(val: any) {
    this.dialogVisible = true;
    this.SrcUrl = val.resource_path_auth || val.file_src;
    this.downloadText = "下载图片";
    this.downName = val.doctor_note;
  }
  downName: any = null;
  year: any = null;
  month: any = null;
  strDate: any = null;
  // 下载图片
  async download() {
    this.$message.warning("当前正在下载中，请稍后");
    let params: any = {};
    const date = new Date();
    this.year = date.getFullYear(); //年
    this.month = date.getMonth() + 1; //月
    this.strDate = date.getDate(); //日
    if (this.month >= 1 && this.month <= 9) {
      this.month = "0" + this.month;
    }
    if (this.strDate >= 0 && this.strDate <= 9) {
      this.strDate = "0" + this.strDate;
    }
    if (this.SrcUrl) {
      //
      params = {
        url: this.SrcUrl,
        name: this.downName
          ? this.downName
          : "图片" + this.year + "-" + this.month + "-" + this.strDate + ".jpg",
      };
    } else if (this.VideoUrl) {
      params = {
        url: this.VideoUrl,
        name: this.downName
          ? this.downName
          : "视频" + this.year + "-" + this.month + "-" + this.strDate + ".mp4",
      };
    }
    const res = await ApiInstallImg(params);
    console.log(res);
    //获取文件流
    const a = document.createElement("a");
    const objUrl = URL.createObjectURL(res);
    a.href = objUrl;
    a.download = params.name;
    document.body.appendChild(a);
    a.click();
    a.remove();
  }

  async download2() {
    this.$message.warning("当前正在下载中，请稍后");
    const date = new Date();
    this.year = date.getFullYear(); //年
    this.month = date.getMonth() + 1; //月
    this.strDate = date.getDate(); //日
    if (this.month >= 1 && this.month <= 9) {
      this.month = "0" + this.month;
    }
    if (this.strDate >= 0 && this.strDate <= 9) {
      this.strDate = "0" + this.strDate;
    }
    const name = this.downName
      ? this.downName
      : "视频" + this.year + "-" + this.month + "-" + this.strDate + ".mp4";

    axios({
      url: this.VideoUrl,
      method: "GET",
      responseType: "blob", // 设置响应类型为 blob
    })
      .then((response) => {
        fileDownload(response.data, name);
      })
      .catch((error) => {
        console.error("Error downloading the file:", error);
      });
  }
  // 关闭预览弹窗
  cancelPreview() {
    this.dialogVisible = false;
    this.dialogVisible2 = false;
    this.VideoUrl = "";
    this.SrcUrl = "";
  }
  xingqu = "";
  remarkForm: any = {
    doctor_note: "",
    id: null,
    type: null,
  };
  dialogRemark = false;
  // 备注
  Remark() {
    //
  }
  noteDialog = "";
  remarkRules = {
    doctor_note: [{ required: true, message: "请输入备注", trigger: "blur" }],
  };
  cancelRemark() {
    this.dialogRemark = false;
    (this.$refs.remarkForm as any).clearValidate();
  }
  type: any = "";
  addRemak() {
    this.dialogRemark = true;
    this.remarkForm.doctor_note = "";
  }
  edmitRemak(val: any, type: any) {
    this.ws_image_length = this.ws_image.length;
    this.patient_image_length = this.patient_image.length;
    this.doctor_image_length = this.doctor_image.length;
    this.doctor_screening_length = this.doctor_screening.length;
    console.log(
      this.ws_image_length,
      this.patient_image_length,
      this.doctor_image_length,
      this.doctor_screening_length
    );

    this.dialogRemark = true;
    if (typeof val.doctor_note == "string") {
      this.remarkForm.doctor_note = val.doctor_note;
      this.noteDialog = "编辑备注";
    } else {
      this.remarkForm.doctor_note = "";
      this.noteDialog = "添加备注";
    }
    if (type == "101") {
      this.remarkForm.type = 101;
      this.remarkForm.id = val.id;
      this.type = "101";
    } else if (type == "102") {
      this.remarkForm.type = 102;
      this.type = "102";
      this.remarkForm.id = val.resource_id;
    } else if (type == "103") {
      this.remarkForm.type = 103;
      this.type = "103";
      this.remarkForm.id = val.id;
    } else if (type == "104") {
      this.remarkForm.type = 104;
      this.type = "104";
      this.remarkForm.id = val.id;
    }
  }

  async getList() {
    const database_id = localStorage.getItem("database_id");
    const res = await ApiGetVideoList({ database_id });
    if (res.success) {
      this.patient_images = res.data.patient_image;
      const doctorRaw = res.data.doctor_image;
      this.doctor_screenings = res.data.doctor_screening;
      this.ws_images = res.data.ws_image;
      this.patient_image = this.patient_images.slice(0, 6);
      // const temp = doctorRaw.slice(0, this.doctor_image_length);
      const temp = doctorRaw;
      const processedImages = [];
      for (const image of temp) {
        if (image.resource_path.indexOf("pins-document") > -1) {
          try {
            const item = await this.imageUrlAuth(image.resource_path);
            const result = {
              resource_id: image.resource_id,
              createdate: image.createdate,
              resource_path: image.resource_path || null,
              resource_path_auth: item || null,
              doctor_note: image.doctor_note || null,
            };
            processedImages.push(result);
          } catch (error) {
            console.error("图片加载失败", error);
          }
        } else {
          const result = {
            resource_id: image.resource_id,
            createdate: image.createdate,
            resource_path: image.resource_path || null,
            resource_path_auth: image.resource_path || null,
            doctor_note: image.doctor_note || null,
          };
          processedImages.push(result);
        }
      }
      this.doctor_image = processedImages.slice(0, 6);
      this.doctor_images = processedImages;
      this.doctor_screening = this.doctor_screenings.slice(
        0,
        this.doctor_screening_length
      );
      this.ws_image = this.ws_images;
      setTimeout(() => {
        this.loading = false;
      }, 500);
      return true;
    }
  }
  ws_image_length: any = null;
  patient_image_length: any = null;
  doctor_image_length: any = null;
  doctor_screening_length: any = null;
  async imageUrlAuth(res: any) {
    const result = await getSignUrlBatch([res], 3600);
    if (result.success) {
      if (result.data && result.data.length > 0) {
        return result.data[0];
      } else {
        this.$message.error(result.message);
        return null;
      }
    } else {
      this.$message.error("图片加载失败");
      return null; // 返回一个默认值，如 null
    }
  }
  remarkSubmit() {
    (this.$refs.remarkForm as any).validate(async (val: any) => {
      if (val) {
        const res = await ApiAddNote(this.remarkForm);
        if (res.success) {
          if (this.type == "101") {
            if (this.ws_image_length > 5) {
              this.getList();
            } else if (this.ws_image_length <= 5) {
              this.getList();
            }
          } else if (this.type == "102") {
            this.getList();
            //
          } else if (this.type == "103") {
            this.getList();
            //
          } else if (this.type == "104") {
            this.getList();
            //
          }

          this.dialogRemark = false;
          this.$message.success(`${this.noteDialog}成功`);
        }
      }
    });
  }
}
