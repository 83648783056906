//运动并发症
import {
  computed,
  reactive,
  watch,
  getCurrentInstance,
} from "@vue/composition-api";
import { ApiMovementComplicationsSave } from "@/apis/index";
interface Module {
  exerciseForm: object;
  test: () => void;
}
function exerciseModuleFun(ins: any, MedicalHistoryDetailsFun: any) {
  const exerciseModule: any = reactive({
    exerciseForm: {
      //运动并发症
      hiscomp_yidong_date: "", // 异动症--出现时间
      hiscomp_yidong03: "", // 异动症--出现症状时服药方案
      hiscomp_yidong04: "", // 异动症--出现症状时等效美多芭剂量
      hiscomp_yidong05: "", // 异动症-与服药的关系
      hiscomp_jimo_date: "", // 剂末现象--出现时间
      hiscomp_jimo03: "", // 剂末现象--出现症状时服药方案
      hiscomp_jimo04: "", // 剂末现象--出现症状时等效美多芭剂量
      hiscomp_tongx_date: "", // 痛性痉挛--出现时间-年份
      hiscomp_tongx03: "", // 痛性痉挛--出现症状时服药方案
      hiscomp_tongx04: "", // 痛性痉挛--出现症状时等效美多芭剂量
      hiscomp_chenj_date: "", // 晨僵--出现时间
      hiscomp_chenj03: "", // 晨僵--出现症状时服药方案
      hiscomp_chenj04: "", // 晨僵--出现症状时等效美多芭剂量
      hiscomp_kaig_date: "", // 开关现象--出现时间
      hiscomp_kaig03: "", // 开关现象--出现症状时服药方案
      hiscomp_kaig04: "", // 开关现象--出现症状时等效美多芭剂量
      hiscomp_kaig05: "", // 开关现象--有不可预测的关
      hiscomp_kaig06: "", // 开关现象--与服药的关系
    } as any,

    exerciseDrawer: false,

    //抽屉open事件
    exerciseClick(exerciseEditForm: any) {
      //处理时间格式，拼接
      const transactionDate =
        exerciseEditForm.hiscomp_yidong01 +
        "-" +
        exerciseEditForm.hiscomp_yidong02;
      const WearingOffDate =
        exerciseEditForm.hiscomp_jimo01 + "-" + exerciseEditForm.hiscomp_jimo02;
      const crampDate =
        exerciseEditForm.hiscomp_tongx01 +
        "-" +
        exerciseEditForm.hiscomp_tongx02;
      const morningStiffnessDate =
        exerciseEditForm.hiscomp_chenj01 +
        "-" +
        exerciseEditForm.hiscomp_chenj02;
      const switchDate =
        exerciseEditForm.hiscomp_kaig01 + "-" + exerciseEditForm.hiscomp_kaig02;
      //处理好的时间格式时间戳
      exerciseEditForm.hiscomp_yidong_date = new Date(
        transactionDate
      ).getTime(); // 年月日转化成时间戳 异动
      console.log(exerciseEditForm.hiscomp_yidong_date);
      exerciseEditForm.hiscomp_jimo_date = new Date(WearingOffDate).getTime(); // 年月日转化成时间戳   剂末现象
      exerciseEditForm.hiscomp_tongx_date = new Date(crampDate).getTime(); // 年月日转化成时间戳   痛性痉挛
      exerciseEditForm.hiscomp_chenj_date = new Date(
        morningStiffnessDate
      ).getTime(); // 年月日转化成时间戳   晨僵
      exerciseEditForm.hiscomp_kaig_date = new Date(switchDate).getTime(); // 年月日转化成时间戳   开关现象

      exerciseModule.exerciseForm = JSON.parse(
        JSON.stringify(exerciseEditForm)
      );
      exerciseModule.exerciseDrawer = true;
    },
    exerciseConfirmBtn() {
      const param = {
        user_id: Number(ins.$route.query.user_id),
        ...exerciseModule.exerciseForm,
      };
      ApiMovementComplicationsSave(param).then((res) => {
        console.log(res);
        if (res.success) {
          //
          ins.$message.success("修改成功！");
          exerciseModule.exerciseDrawer = false;
          // //详情页面数据接口调用
          MedicalHistoryDetailsFun();
        }
      });
    },
    exerciseCanelBtn() {
      ins.confirmDialogVisible = true;
    },
    //格式化月份方法
    formatMonth(date: number | string) {
      if (date == undefined) return;
      date = date.toString();
      return ("0" + date).slice(-2);
    },
    exerciseRadio(property: string, content: string) {
      if (exerciseModule.exerciseForm[property] == content) {
        exerciseModule.exerciseForm[property] = "";
      } else {
        exerciseModule.exerciseForm[property] = content;
      }
      exerciseModule.exerciseForm = { ...exerciseModule.exerciseForm };
    },
  });
  return {
    exerciseModule,
  };
}
export { exerciseModuleFun };
