//  主要症状
import { reactive } from "@vue/composition-api";

import {
  ApiPastMedicalHistorySave,
  editStartMedical,
  editMianMedical,
  editOtherMedical,
  editSequenceProgress,
  addMedicalHistoryTwo,
  addMedicalHistoryThree,
} from "@/apis/index";
interface Module {
  mainSymptomsForm: object;
}
function mainSymptomsFun(ins: any, mainSymptomsDetailFun: any) {
  console.log(ins, "insss");
  const mainSymptomsModule: any = reactive({
    chiefComplaintId: null,
    // 表单数据
    mainSymptomsForm: {
      user_id: "", // 患者病历id
      trmtprst_indate: null, // 起病情况--起病时间
      trmtprst_inage: null, // 起病情况--起病年龄
      trmtprst_fstsymp: "", // 起病情况--首发症状
      trmtprst_fstposition: "", // 起病情况--首发部位
      trmtprst_issymmetry: "", // 起病情况--是否对称起病
      trmtprst_symmetrydate: "", // 起病情况--对称起病时间
      trmtprst_note: "", // 起病情况--备注
      lu_limb_date: "", // 进展顺序--左上肢时间
      ld_limb_date: "", // 进展顺序--左下肢时间
      ru_limb_date: "", // 进展顺序--右上肢时间
      rd_limb_date: "", // 进展顺序--右下肢时间
      tremor: "", // 主要症状--震颤
      tremor_type: "", // 主要症状--震颤--类型
      tremor_item: "", // 主要症状--震颤--出现时间
      tremor_items: "", // 主要症状--震颤--进展双侧时间
      tonic: "", // 主要症状--强直
      tonic_type: "", // 主要症状--强直--类型
      tonic_item: "", // 主要症状--强直--出现时间
      slow_action: "", // 主要症状--运动迟缓
      slow_action_type: "", // 主要症状--运动迟缓--类型
      slow_action_item: "", // 主要症状--运动迟缓--出现时间
      slow_action_items: "", // 主要症状--运动迟缓--进展双侧时间
      abnormal_gait: "", // 主要症状--步态异常
      abnormal_gait_type: "", // 主要症状--步态异常--类型
      abnormal_gait_item: "", // 主要症状--步态异常--出现时间
      panic_gait: "", // 其它运动症状--慌张步态
      panic_gait_item: "", // 其它运动症状--慌张步态--出现时间
      lowercase: "", // 其他运动症状--小写症
      lowercase_item: "", // 其他运动症状--小写症--出现时间
      difficulty_turning: "", // 其他运动症状--翻身困难
      difficulty_turning_item: "", // 其他运动症状--翻身困难--出现时间
      fall: "", // 其他运动症状--跌倒/姿势不稳
      fall_item: "", // 其他运动症状--跌倒/姿势不稳-出现时间
      language_disability: "", // 其他运动症状--语言障碍
      language_disability_item: "", // 其他运动症状--语言障碍--出现时间
      hard_to_swallow: "", // 其他运动症状--吞咽困难
      hard_to_swallow_item: "", // 其他运动症状--吞咽困难--出现时间
    },

    // 现病史表单
    startMedicalFrom: {
      // trmtprst_id: null,
      // user_id: null,
      // trmtprst_indate: null,
      // trmtprst_inage: "",
      // trmtprst_fstsymp: "",
      // trmtprst_fstposition: "",
      // trmtprst_issymmetry: "",
      // trmtprst_symmetrydate: null,
      // trmtprst_note: "",
    },

    // 主要症状
    MainMedical: {},

    partList: ["左上肢", "左下肢", "右上肢", "右下肢", "头面部"],

    diseaseStateList: ["有", "无", "未知"],

    //震颤类型列表
    tremorTypeList: ["典型的帕金森病的静止性震颤", "突出的运动性震颤", "其它"],

    // 语言障碍
    unableSpeakList: ["言语含糊", "言语重复", "言语表达困难/失语", "其它"],

    // 眼球运动障碍
    eyeBarrierList: ["核上性凝视麻痹", "突出的运动性震颤", "眼睑失用", "其它"],

    // 额叶认知/行为障碍
    unableBehaviorList: ["淡漠/反应迟钝", "执行功能障碍", "行为异常", "其它"],

    unableSleepList: [
      "失眠",
      "早醒",
      "睡眠片段化",
      "多梦",
      "RBD",
      "周期性肢动",
      "睡眠发作",
      "不安腿综合征",
      "日间睡眠过多",
    ],

    acrocinesisList: [
      "舞蹈样动作",
      "肌阵挛",
      "肌张力障碍",
      "手足徐动",
      "投掷样动作",
      "不宁腿",
      "其它",
    ],

    // 其它步态异常
    unableOhterList: [
      "典型的帕金森病的姿势异常和步态障碍",
      "宽基底步态或共济失调",
      "足内翻",
      "其它",
    ],

    //强直类型列表
    tonicTypeList: [
      "肢体的铅管样强直",
      "肢体的折刀样强直",
      "肢体的齿轮样强直",
      "中轴强直",
      "其它",
    ],

    //步态异常类型列表
    abnormalGaitTypeList: [
      "典型的帕金森病的体位障碍和步态异常",
      "宽基底步态或共济失调",
      "无额外帮助可能摔倒",
      "足内翻",
      "起步困难",
      "其它",
    ],
    //运动迟缓类型列表
    bradykinesiaTypeList: [
      "典型的帕金森病的运动迟缓",
      "单纯的运动不能（无强直或震颤)",
      "其它",
    ],

    //主诉抽屉开关
    previousHistoryDrawer: false,

    //起病情况抽屉开关
    startMedicalDrawer: false,

    // 进展顺序开关
    SequenceProgressDrawer: false,

    // 主要症状开关
    mainMedicalDrawer: false,

    // 其他运动症状开关
    otherMedicalDrawer: false,

    zhiwuDrawer: false,

    qitaDrawer: false,

    feidianxingDrawer: false,

    // 打开起病情况抽屉
    openStertMedicalClick(data: any, id: any) {
      mainSymptomsModule.startMedicalDrawer = true;
      mainSymptomsModule.startMedicalFrom = JSON.parse(JSON.stringify(data));
      mainSymptomsModule.startMedicalFrom.user_id = id;
    },

    // 起病情况编辑保存
    onSaveStartMedical() {
      const params = mainSymptomsModule.startMedicalFrom;
      editStartMedical(params).then((res) => {
        if (res.success) {
          ins.$message.success("编辑成功！");
          ins.startMedicalDrawer = false;
          mainSymptomsDetailFun();
        }
      });
    },

    onSaveStartMedicalThree() {
      const params = mainSymptomsModule.startMedicalFrom;
      addMedicalHistoryThree(params).then((res: any) => {
        if (res.success) {
          ins.$message.success("编辑成功！");
          ins.startMedicalDrawer = false;
          mainSymptomsDetailFun();
        }
      });
    },

    onSaveStartMedicalTwo() {
      const params = mainSymptomsModule.startMedicalFrom;
      addMedicalHistoryTwo(params).then((res: any) => {
        if (res.success) {
          ins.$message.success("编辑成功！");
          ins.startMedicalDrawer = false;
          mainSymptomsDetailFun();
        }
      });
    },

    // 打开进展顺序抽屉
    openSequenceProgressClick(data: any, id: any) {
      mainSymptomsModule.SequenceProgressDrawer = true;
      mainSymptomsModule.startMedicalFrom = JSON.parse(JSON.stringify(data));
      mainSymptomsModule.startMedicalFrom.user_id = id;
    },

    // 保存进展顺序编辑
    onSaveSequenceProgress() {
      const param = mainSymptomsModule.startMedicalFrom;
      editSequenceProgress(param).then((res) => {
        if (res.success) {
          ins.$message.success("编辑成功！");
          ins.SequenceProgressDrawer = false;
          mainSymptomsDetailFun();
        }
      });
    },

    // psp进展顺序
    onSaveMedicalHistoryThree() {
      const param = mainSymptomsModule.startMedicalFrom;
      addMedicalHistoryThree(param).then((res: any) => {
        if (res.success) {
          ins.$message.success("编辑成功！");
          ins.SequenceProgressDrawer = false;
          mainSymptomsDetailFun();
        }
      });
    },

    onSaveMedicalHistoryTwo() {
      const param = mainSymptomsModule.startMedicalFrom;
      addMedicalHistoryTwo(param).then((res: any) => {
        if (res.success) {
          ins.$message.success("编辑成功！");
          ins.SequenceProgressDrawer = false;
          mainSymptomsDetailFun();
        }
      });
    },

    // 打开主要症状抽屉
    openMainMedicalclick(data: any, id: any) {
      ins.mainMedicalDrawer = true;
      mainSymptomsModule.startMedicalFrom = JSON.parse(JSON.stringify(data));
      mainSymptomsModule.startMedicalFrom.user_id = id;
    },

    // 主症状编辑保存
    onSaveMainMedical() {
      const params = mainSymptomsModule.startMedicalFrom;
      editMianMedical(params).then((res) => {
        if (res.success) {
          ins.$message.success("编辑成功！");
          ins.mainMedicalDrawer = false;
          mainSymptomsDetailFun();
        }
      });
    },

    onSaveMainMedicalTree() {
      const params = mainSymptomsModule.startMedicalFrom;
      addMedicalHistoryThree(params).then((res: any) => {
        if (res.success) {
          ins.$message.success("编辑成功！");
          ins.mainMedicalDrawer = false;
          mainSymptomsDetailFun();
        }
      });
    },

    onSaveMainMedicalTwo() {
      const params = mainSymptomsModule.startMedicalFrom;
      addMedicalHistoryTwo(params).then((res: any) => {
        if (res.success) {
          ins.$message.success("编辑成功！");
          ins.mainMedicalDrawer = false;
          mainSymptomsDetailFun();
        }
      });
    },

    // 打开其他运动症状抽屉
    openOtherMedical(data: any, id: any) {
      ins.otherMedicalDrawer = true;
      mainSymptomsModule.startMedicalFrom = JSON.parse(JSON.stringify(data));
      mainSymptomsModule.startMedicalFrom.user_id = id;
    },

    // 打开植物神经症状抽屉
    openZhiwu(data: any, id: any) {
      ins.zhiwuDrawer = true;
      mainSymptomsModule.startMedicalFrom = JSON.parse(JSON.stringify(data));
      mainSymptomsModule.startMedicalFrom.user_id = id;
    },

    openQita(data: any, id: any) {
      ins.qitaDrawer = true;
      mainSymptomsModule.startMedicalFrom = JSON.parse(JSON.stringify(data));
      mainSymptomsModule.startMedicalFrom.user_id = id;
    },

    openFeidianxing(data: any, id: any) {
      ins.feidianxingDrawer = true;
      mainSymptomsModule.startMedicalFrom = JSON.parse(JSON.stringify(data));
      mainSymptomsModule.startMedicalFrom.user_id = id;
    },

    // 保存其他运动症状编辑
    onSaveOtherMedical() {
      const param = mainSymptomsModule.startMedicalFrom;

      editOtherMedical(param).then((res) => {
        if (res.success) {
          ins.$message.success("编辑成功！");
          ins.otherMedicalDrawer = false;
          mainSymptomsDetailFun();
        }
      });
    },

    onSaveOtherMedicalThree() {
      const param = mainSymptomsModule.startMedicalFrom;

      addMedicalHistoryThree(param).then((res: any) => {
        if (res.success) {
          ins.$message.success("编辑成功！");
          ins.otherMedicalDrawer = false;
          mainSymptomsDetailFun();
        }
      });
    },

    onSaveOtherMedicalTwo() {
      const param = mainSymptomsModule.startMedicalFrom;

      addMedicalHistoryTwo(param).then((res: any) => {
        if (res.success) {
          ins.$message.success("编辑成功！");
          ins.otherMedicalDrawer = false;
          mainSymptomsDetailFun();
        }
      });
    },

    // 保存植物神经症状编辑
    onSaveZhiwu() {
      const param = mainSymptomsModule.startMedicalFrom;

      addMedicalHistoryThree(param).then((res: any) => {
        if (res.success) {
          ins.$message.success("编辑成功！");
          ins.zhiwuDrawer = false;
          mainSymptomsDetailFun();
        }
      });
    },

    onSaveZhiwuTwo() {
      const param = mainSymptomsModule.startMedicalFrom;

      addMedicalHistoryTwo(param).then((res: any) => {
        if (res.success) {
          ins.$message.success("编辑成功！");
          ins.zhiwuDrawer = false;
          mainSymptomsDetailFun();
        }
      });
    },

    // 保存其他症状编辑
    onSaveQita() {
      const param = mainSymptomsModule.startMedicalFrom;

      addMedicalHistoryThree(param).then((res: any) => {
        if (res.success) {
          ins.$message.success("编辑成功！");
          ins.qitaDrawer = false;
          mainSymptomsDetailFun();
        }
      });
    },

    onSaveQitaTwo() {
      const param = mainSymptomsModule.startMedicalFrom;

      addMedicalHistoryTwo(param).then((res: any) => {
        if (res.success) {
          ins.$message.success("编辑成功！");
          ins.qitaDrawer = false;
          mainSymptomsDetailFun();
        }
      });
    },

    onSaveFeidainxing() {
      const param = mainSymptomsModule.startMedicalFrom;

      addMedicalHistoryThree(param).then((res: any) => {
        if (res.success) {
          ins.$message.success("编辑成功！");
          ins.feidianxingDrawer = false;
          mainSymptomsDetailFun();
        }
      });
    },

    onSaveFeidainxingTwo() {
      const param = mainSymptomsModule.startMedicalFrom;

      addMedicalHistoryTwo(param).then((res: any) => {
        if (res.success) {
          ins.$message.success("编辑成功！");
          ins.feidianxingDrawer = false;
          mainSymptomsDetailFun();
        }
      });
    },

    otherMotorSymptomsRadio(property: string, content: string) {
      const propertyName = property + "_item";
      ins.startMedicalFrom[propertyName] = "";
      if (ins.startMedicalFrom[property] == content) {
        ins.startMedicalFrom[property] = "";
      } else {
        ins.startMedicalFrom[property] = content;
      }
      ins.startMedicalFrom = { ...ins.startMedicalFrom };
    },

    pspOtherSymptomChange(property: string, content: string) {
      if (property == "unable_ohter") {
        ins.startMedicalFrom["unable_other_time"] = "";
      }
      const propertyName = property + "_time";
      const propertyType = property + "_type";
      const typeTime = property + "_type_time";
      const twoTypeTime = property + "_type_two_time";
      const twoTypeTime2 = property + "_two_time";
      ins.startMedicalFrom[propertyName] = "";
      ins.startMedicalFrom[propertyType] = "";
      ins.startMedicalFrom[typeTime] = "";
      ins.startMedicalFrom[twoTypeTime] = "";
      ins.startMedicalFrom[twoTypeTime2] = "";
      if (ins.startMedicalFrom[property] == content) {
        ins.startMedicalFrom[property] = "";
      } else {
        ins.startMedicalFrom[property] = content;
      }
      ins.startMedicalFrom = { ...ins.startMedicalFrom };
    },

    // 关闭起病情况抽屉
    startMedicalCanelBtn() {
      ins.confirmDialogVisible = true;
    },

    // 关闭进展顺序抽屉
    SequenceProgressCanelBtn() {
      ins.confirmDialogVisible = true;
    },

    // 关闭主要症状抽屉
    mainMedicalCanelBtn() {
      ins.confirmDialogVisible = true;
    },

    // 关闭其他运动症状抽屉
    otherMedicalCanelBtn() {
      ins.confirmDialogVisible = true;
    },

    // 关闭植物神经症状抽屉
    zhiwuCanelBtn() {
      ins.confirmDialogVisible = true;
    },

    // 关闭其他症状抽屉
    qitalCanelBtn() {
      ins.confirmDialogVisible = true;
    },

    // 关闭非典型症状抽屉
    feidianxingCanelBtn() {
      ins.confirmDialogVisible = true;
    },

    // 诊断下拉框的changge
    selectChange(index: number) {
      mainSymptomsModule.symptomDiagnosisForm[index].chatidiag_second = "";
    },
    // 是否对称症状
    onsetRadioClick(property: string, content: string) {
      if (ins.startMedicalFrom[property] === content) {
        ins.startMedicalFrom[property] = "";
      } else {
        ins.startMedicalFrom[property] = content;
      }
      ins.startMedicalFrom = { ...ins.startMedicalFrom };
    },

    onTonicChange() {
      if (ins.startMedicalFrom.tonic !== "有") {
        ins.startMedicalFrom.tonic_type = "";
        ins.startMedicalFrom.tonic_item = "";
      }
    },

    onSlowActionChange() {
      if (ins.startMedicalFrom.slow_action !== "有") {
        ins.startMedicalFrom.slow_action_type = "";
        ins.startMedicalFrom.slow_action_item = "";
        ins.startMedicalFrom.slow_action_items = "";
      }
    },
    onTremorChange() {
      if (ins.startMedicalFrom.tremor !== "有") {
        ins.startMedicalFrom.tremor_type = "";
        ins.startMedicalFrom.tremor_item = "";
        ins.startMedicalFrom.tremor_items = "";
      }
    },

    onAbnormalGaitChange() {
      if (ins.startMedicalFrom.abnormal_gait !== "有") {
        ins.startMedicalFrom.abnormal_gait_type = "";
        ins.startMedicalFrom.abnormal_gait_item = "";
      }
    },

    onHardToSwallowChange() {
      if (ins.startMedicalFrom.hard_to_swallow !== "是") {
        ins.startMedicalFrom.hard_to_swallow_item = "";
      }
    },

    onShakingChange() {
      if (ins.startMedicalFrom.shaking !== "有") {
        ins.startMedicalFrom.shaking_type = "";
        ins.startMedicalFrom.shaking_type_time = "";
        ins.startMedicalFrom.shaking_type_two_time = "";
      }
    },

    onAnkyloseChange() {
      if (ins.startMedicalFrom.ankylose !== "有") {
        ins.startMedicalFrom.ankylose_type = "";
        ins.startMedicalFrom.ankylose_time = "";
      }
    },

    onExerciseSlowChange() {
      if (ins.startMedicalFrom.exercise_slow !== "有") {
        ins.startMedicalFrom.exercise_slow_type = "";
        ins.startMedicalFrom.exercise_slow_time = "";
        ins.startMedicalFrom.exercise_slow_two_time = "";
      }
    },

    onUnableWalkChange() {
      if (ins.startMedicalFrom.unable_walk !== "有") {
        ins.startMedicalFrom.unable_walk_type = "";
        ins.startMedicalFrom.unable_walk_time = "";
      }
    },

    //保存症状诊断
    onSaveSymptomDiagnos() {
      const formRef = ins.$refs["previousHistoryRuleForm"] as any;
      formRef.validate((valid: boolean) => {
        if (valid) {
          const param = mainSymptomsModule.symptomDiagnosisForm.data;
          param.map((item: any) => {
            item.user_id = Number(ins.$route.query.user_id);
          });
          ApiPastMedicalHistorySave(param).then((res) => {
            if (res.success) {
              ins.$message.success("修改成功！");
              mainSymptomsModule.symptomDiagnosisDrawer = false;
              // //详情页面数据接口调用
              mainSymptomsDetailFun();
            }
          });
        }
      });
    },

    formatDate(timeStamp: number | string | null) {
      if (!timeStamp) {
        return "-";
      }
      const time = new Date(timeStamp);
      //年-月-日
      return `${time.getFullYear()}-${this.format(
        time.getMonth() + 1
      )}-${this.format(time.getDate())}`;
    },
    myFormatMonth(timeStamp: number | string | null) {
      if (timeStamp == null) {
        return "-";
      }
      const time = new Date(timeStamp);
      //年-月-日
      return `${time.getFullYear()}-${this.format(time.getMonth() + 1)}`;
    },

    format(date: number | string) {
      date = date.toString();
      return ("0" + date).slice(-2);
    },
    flag: true,
    // 添加既往疾病史
    //扩展
    // addPreviousHistory() {
    //   const formRef = ins.$refs["previousHistoryRuleForm"] as any;
    //   formRef.validate((valid: boolean) => {
    //     if (valid) {
    //       const arr = {
    //         hisdisease_id: "",
    //         user_id: Number(ins.$route.query.user_id),
    //         hisdisease_name: "", //疾病名称
    //         hisdisease_date: "", //发病时间
    //         hisdisease_outcome: "", //转归
    //         hisdisease_pharmacy: "", //用药
    //         hisdisease_note: "", //备注
    //       };
    //       ins.previousHistoryForm.data.push(arr);
    //     }
    //   });
    // },
    // 表单减少一行
    // reducePreviousHistory(index: number) {
    //   const h = ins.$createElement;
    //   ins
    //     .$msgbox({
    //       title: "提示",
    //       message: h("p", undefined, [
    //         h("img", {
    //           attrs: {
    //             src: require("../../../../../assets/img/icon-confirm-tips.png"),
    //           },
    //         }),
    //         h("span", { class: "message_text" }, " 确认删除吗？ "),
    //       ]),
    //       showCancelButton: true,
    //       confirmButtonText: "确 认",
    //       cancelButtonText: "取 消",
    //       cancelButtonClass: "message_cancel",
    //       confirmButtonClass: "message_confirm",
    //       closeOnClickModal: false,
    //     })
    //     .then(() => {
    //       ins.previousHistoryForm.data.splice(index, 1);
    //     })
    //     .catch(() => {
    //       console.log("取消");
    //     });
    // },

    // 底部单选的click事件
    symptomDiagnosisRadio(property: string, content: string, index: number) {
      if (mainSymptomsModule.symptomDiagnosisForm[index][property] == content) {
        mainSymptomsModule.symptomDiagnosisForm[index][property] = "";
      } else {
        mainSymptomsModule.symptomDiagnosisForm[index][property] = content;
      }
      mainSymptomsModule.symptomDiagnosisForm = [
        ...mainSymptomsModule.symptomDiagnosisForm,
      ];
    },

    //弹窗打开，数据回显操作
    // openStertMedicalClick(previousHistoryEditForm: any) {
    //   // mainSymptomsModule.symptomDiagnosisForm = JSON.parse(
    //   //   JSON.stringify(previousHistoryEditForm.data)
    //   // );
    //   // mainSymptomsModule.symptomDiagnosDrawer = true;
    // },

    symptomDiagnosisCanelBtn() {
      ins.confirmDialogVisible = true;
    },
  });
  return {
    mainSymptomsModule,
  };
}
export { mainSymptomsFun };
