




































































































































import { Component, Vue } from "vue-property-decorator";
import {
  ApiConsentList,
  //上传文件到oss
  ApiConsentAdd,
  ApiConsentDelete,
} from "@/apis";
import { parseTime } from "@/utils";
import { Base64 } from "js-base64";
import { Form } from "element-ui";
import { DirectoryEnum } from "@/constants";
import { uploadFile } from "@/utils/alioss";
@Component({
  filters: {
    parseTimeFilter(time: any, str: string) {
      if (time) {
        return parseTime(time, str);
      } else {
        return "-";
      }
    },
  },
})
export default class InformedConsent extends Vue {
  infoDialog = false;
  loading = false;
  loadings = false;
  userId = "";
  addDialogForm: any = {
    consent_issign: null,
    consent_file: null,
    consent_note: "",
  };
  totalObjData = [] as any;
  noteDialog = "";
  infoRules = {
    consent_issign: [
      { required: true, message: "请选择是否签署", trigger: "change" },
    ],
    consent_file: [
      { required: true, message: "请选择文件", trigger: "change" },
    ],
  };

  // 上传前校验
  beforeAvatarUpload(file: any) {
    const extension = this.getExtension(file.name);
    if (
      extension == ".jpg" ||
      extension == ".jpeg" ||
      extension == ".png" ||
      extension == ".pdf"
    ) {
      return true;
    } else {
      this.$message.warning("只支持jpg，jpeg，png，pdf格式文件！");
      return false;
    }
  }

  async created() {
    this.userId = this.$route.query.user_id as string;
    this.getList();
  }

  // 上传成功的回调
  uploadSuccess() {
    return 1;
  }

  // 上传附件
  async uploadHttpRequest(params: any) {
    const file = params.file;
    const fileName = file.name;
    this.addDialogForm.consent_file = await this.uploadAttachment(
      file
    );
    console.log(this.addDialogForm.consent_file);
  }

  // 上传
  // upload_dir: "web_patient_information/informed_consent",
  async uploadAttachment(file: File) {
    const fileName = new Date().getTime().toString();
    const fileExt = file.name.split(".").pop();

    const res = await uploadFile(
      DirectoryEnum.WEB_PATIENT_INFORMATION,
      file,
      fileName + "." + fileExt,
      "/informed_consent"
    );
    return res;
  }
  //获取文件后缀名
  getExtension(name: string) {
    return name.substring(name.lastIndexOf("."));
  }

  cancelInfo() {
    this.infoDialog = false;
  }

  dash(data: any) {
    if (data == null || data == "") {
      data = "-";
    }
    return data;
  }
  editButton(row: any) {
    this.addDialogForm = {
      consent_issign: row.consent_issign,
      consent_file: row.consent_file,
      consent_note: row.consent_note,
      consent_id: row.consent_id,
    };
    this.infoDialog = true;
  }
  deleteButton(row: any) {
    this.$confirm("是否确认要删除?", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    }).then(async () => {
      const res = await ApiConsentDelete({}, row.consent_id);
      if (res.success) {
        this.$message.success("删除成功！");
        this.getList();
      } else {
        this.$message.error(res.message);
      }
    });
  }

  addContentFun() {
    Object.keys(this.addDialogForm).map((item: any) => {
      this.addDialogForm[item] = "";
    });
    this.infoDialog = true;
    setTimeout(() => {
      (this.$refs.addDialogForm as Form).clearValidate();
    }, 20);
  }

  async getList() {
    const res = await ApiConsentList(this.userId);
    if (res.success) {
      this.totalObjData = res.data;
      setTimeout(() => {
        this.loading = false;
      }, 500);
      return true;
    }
  }

  addContent() {
    (this.$refs.addDialogForm as any).validate(async (val: any) => {
      if (val) {
        const params = {
          ...this.addDialogForm,
          user_id: this.userId,
        };
        const res = await ApiConsentAdd(params);
        if (res.success) {
          this.infoDialog = false;
          this.$message.success(`操作成功！`);
          this.getList();
        }
      }
    });
  }
}
