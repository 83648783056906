

























































































































































































































































import { Component, Vue, Emit } from "vue-property-decorator";

import { basicInformationModuleFun } from "@/views/EleMediRecord/Scale/ts/add/basicInformation";
import { chiefComplaintModuleFun } from "@/views/EleMediRecord/Scale/ts/add/chiefComplaint";
import { DirectoryEnum } from "@/constants";
import { uploadFile } from "@/utils/alioss";
//就诊记录-保存
import { addMedicalRecordModuleFun } from "@/views/EleMediRecord/Scale/ts/medicalRecords/saveMedicalRecord";
import {
  computed,
  defineComponent,
  getCurrentInstance,
  onMounted,
  onUnmounted,
  reactive,
  ref,
  toRef,
  toRefs,
  watch,
} from "@vue/composition-api";

export default defineComponent({
  setup() {
    //公用数据
    //公用数据
    const { proxy: _this } = getCurrentInstance() as any;

    //基础信息模块
    const { basicInformationModule } = basicInformationModuleFun(_this);
    //主诉模块
    const { chiefComplaintModule } = chiefComplaintModuleFun(
      _this,
      basicInformationModule
    );

    //新增病历
    const { addMedicalRecordModule } = addMedicalRecordModuleFun(_this);

    function init() {
      //对list主体内容元素监听滚动事件
      // initScroll();
    }
    onMounted(init);
    return {
      ...toRefs(basicInformationModule),
      ...toRefs(chiefComplaintModule),
      ...toRefs(addMedicalRecordModule),
    };
  },
});
