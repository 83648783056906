//其它治疗史
import { reactive } from "@vue/composition-api";
import { ApiEidemiologicalHistorySave } from "@/apis/index";

interface Module {
  eidemiologicalHistoryForm: object;
  test: () => void;
}

function eidemiologicalHistoryModuleFun(
  ins: any,
  MedicalHistoryDetailsFun: any
) {
  const eidemiologicalHistoryModule: any = reactive({
    eidemiologicalHistoryForm: {
      data: {
        user_id: "",
        disease_history: "", // 备注
      },
    } as any,
    eidemiologicalHistoryDrawer: false,

    //抽屉open事件
    eidemiologicalHistoryClick(eidemiologicalHistoryEditForm: any) {
      eidemiologicalHistoryModule.eidemiologicalHistoryDrawer = true;
      setTimeout(() => {
        (ins.$refs.eidemiologicalHistoryRuleForm as any).clearValidate();
      }, 20);

      eidemiologicalHistoryModule.eidemiologicalHistoryForm = JSON.parse(
        JSON.stringify(eidemiologicalHistoryEditForm)
      );
    },
    //保存
    eidemiologicalHistoryConfirmBtn() {
      const formRef = ins.$refs["eidemiologicalHistoryRuleForm"] as any;

      formRef.validate((valid: boolean) => {
        if (valid) {
          const param =
            eidemiologicalHistoryModule.eidemiologicalHistoryForm.data;
          param.user_id = Number(ins.$route.query.user_id);
          ApiEidemiologicalHistorySave(param).then((res: any) => {
            if (res.success) {
              ins.$message.success("修改成功！");
              eidemiologicalHistoryModule.eidemiologicalHistoryDrawer = false;
              //详情页面数据接口调用
              MedicalHistoryDetailsFun();
            }
          });
        } else {
          console.log("未通过");
        }
      });
    },
    //取消
    eidemiologicalHistoryCanelBtn() {
      ins.confirmDialogVisible = true;
      setTimeout(() => {
        (ins.$refs.eidemiologicalHistoryRuleForm as any).clearValidate();
      }, 20);
    },
  });
  return {
    eidemiologicalHistoryModule,
  };
}

export { eidemiologicalHistoryModuleFun };
