






























































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import {
  ApiGetExportControlHistoryFirst,
  ApiGetPatientControlHistoryList,
  ApiGetMyPatientControlHistoryList,
  ApiGetAddMyPatientControlHistoryList,
  ApiGetDelMyPatientControlHistoryList,
  ApiGetExportControlHistory,
} from "@/apis/index";
import { parseTime } from "@/utils";
import { Form } from "element-ui";
@Component({
  name: "programControlHistory",
  filters: {
    parseTimeFilter(time: any, str: string) {
      if (time) {
        return parseTime(time, str);
      } else {
        return "-";
      }
    },
  },
})
export default class ProgramControlHistory extends Vue {
  get HeaderCellStyle(): object {
    return {
      background: "#F5F7FA",
      color: "#303133",
      padding: "0 14px",
      height: "48px",
      fontSzie: "14px",
      fontWeight: "500",
    };
  }
  isExporting = false;
  //参数历史
  condition: any = {
    database_id: localStorage.getItem("database_id"),
    date_start: "",
    date_end: "",
    cur_page: 1,
    page_size: 10,
  };
  time = 0;
  tableData: Array<any> = [];
  pagination = {
    //当前是第几页
    page: 1,
    //分页器当前页显示几条数据
    size: 10,
    //表格的数据总量
    total: 0,
  };
  pagination1 = {
    //当前是第几页
    page: 1,
    //分页器当前页显示几条数据
    size: 10,
    //表格的数据总量
    total: 0,
  };
  // 自增程控记录新增
  addForm = {
    database_id: localStorage.getItem("database_id"),
    tele_date: "",
    // summry: `刺激：开启\n参数：G1\n左脑，STN，[P1:C+2-,1.30V,60us,120Hz ] \n右脑，STN，[P1:C+6-,1.40V,60us,120Hz ] \n软启动：4秒\n循环模式：禁用\n定时刺激：禁用`,
    summry: `左脑，P1，[C+2-,1.30V,60us,120Hz ]\n右脑，P1，[C+6-,1.40V,60us,120Hz ]`,
    model: "",
    remark: "",
    source: "手动增加",
    current_group_name: "",
    device_brand: "品驰",
  };
  modelList = [
    "G101",
    "G102R",
    "G102",
    "G111",
    "G101A",
    "G101T",
    "G102T",
    "G102RS",
    "G131",
    "G132",
    "G112",
    "G101ANEW",
    "G102NEW",
    "G102RNEW",
    "G114R",
    "G103",
    "G104",
    "G104R",
    "G105",
    "G106",
    "G106R",
    "G102RZ",
    "T902",
    "G122",
    "G122R",
    "G134R",
    "G106RS",
    "G113R",
    "G106RST",
    "G107R",
    "G107",
  ];
  autoincrementData = [] as any;
  activeName = "first";
  addShowDialog = false;
  tabIndex = 0;

  // 自增程控记录校验
  addFormRules: any = {
    tele_date: [{ required: true, message: "请选择程控时间", trigger: "blur" }],
    summry: [{ required: true, message: "请输入参数概要", trigger: "blur" }],
  };

  // 取消添加
  cancelAddPatientList() {
    this.addShowDialog = false;
    (this.$refs.contextForm as Form).resetFields();
    this.addForm = {
      database_id: localStorage.getItem("database_id"),
      tele_date: "",
      summry: `左脑，P1，[C+2-,1.30V,60us,120Hz ]\n右脑，P1，[C+6-,1.40V,60us,120Hz ]`,
      model: "",
      remark: "",
      source: "手动增加",
      current_group_name: "",
      device_brand: "品驰",
    };
  }

  // 自增程控记录添加
  addPatientList() {
    (this.$refs.contextForm as Form).validate(async (val: any) => {
      if (val) {
        if (
          this.addForm.summry.lastIndexOf("\n") ==
          this.addForm.summry.length - 1
        ) {
          this.addForm.summry = this.addForm.summry.substring(
            0,
            this.addForm.summry.length - 1
          );
        }
        if (this.addForm.device_brand == "其他" && !this.addForm.model) {
          this.addForm.model = "其他";
        }
        const res = await ApiGetAddMyPatientControlHistoryList(this.addForm);
        if (res.success) {
          this.addForm = {
            database_id: localStorage.getItem("database_id"),
            tele_date: "",
            summry: `左脑，P1，[C+2-,1.30V,60us,120Hz ]\n右脑，P1，[C+6-,1.40V,60us,120Hz ]`,
            model: "",
            remark: "",
            source: "手动增加",
            current_group_name: "",
            device_brand: "品驰",
          };
          this.addShowDialog = false;
          this.$message.success("新增成功");
        }
        this.getDataList();
      }
    });
  }

  // 自增程控记录删除
  del(id: any) {
    this.$confirm("你确定要删除这条自增程控记录么？", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
      .then(async () => {
        const res = await ApiGetDelMyPatientControlHistoryList({ id });
        if (res.success) {
          this.getDataList();
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        }
      })
      .catch(() => {
        return 1;
      });
  }
  clickTab(tab: { index: string }, event: object) {
    const index = tab.index;
    switch (index) {
      case "0": {
        this.tabIndex = 0;
        break;
      }
      case "1": {
        this.tabIndex = 1;
        break;
      }
    }
    this.condition = {
      database_id: localStorage.getItem("database_id"),
      date_start: "",
      date_end: "",
      cur_page: 1,
      page_size: 10,
    };
    this.pagination.size = 10;
    this.pagination.page = 1;
    if (this.tabIndex == 0) {
      this.updateTableData();
    } else {
      this.getDataList();
    }
  }
  //hook
  mounted() {
    this.init();
  }
  //methods
  init() {
    this.updateTableData();
    this.getDataList();
  }
  formatDate(timeStamp: number | string | null) {
    if (timeStamp == null) {
      return "-";
    }
    const time = new Date(timeStamp);
    //年-月-日
    return `${time.getFullYear()}-${this.format(
      time.getMonth() + 1
    )}-${this.format(time.getDate())} ${this.format(
      time.getHours()
    )}:${this.format(time.getMinutes())}:${this.format(time.getSeconds())}`;
  }
  format(date: number | string) {
    date = date.toString();
    return ("0" + date).slice(-2);
  }
  dash(data: string | number | undefined | null) {
    let result: string | number = "-";
    if (data || data === 0) {
      result = data;
    }
    return result;
  }
  //当表格为空时，表格默认显示内容
  emptyContent = "暂无数据";
  //获取列表数据
  //程控历史查询列表
  updateTableData() {
    this.emptyContent = "暂无数据";
    let endTime = this.condition.date_end;
    if (endTime) {
      const timeLimit = 24 * 60 * 60 * 1000;
      endTime = endTime + timeLimit - 1;
      this.condition.date_end = endTime;
    }
    const param = {
      ...this.condition,
    };
    param.cur_page = this.pagination.page;
    param.page_size = this.pagination.size;
    ApiGetPatientControlHistoryList(param).then((res) => {
      if (res.success) {
        this.tableData = res.data;
        this.pagination.total = res.page.total_size;
        this.tableData.forEach((item) => {
          item.summaryFormat = item.summry ? item.summry.split("\n") : "-";
        });
      } else {
        this.emptyContent = "抱歉，暂时没有找到相关数据，请您联系品驰工作人员";
      }
    });
  }
  //自增程控历史查询列表
  getDataList() {
    this.emptyContent = "暂无数据";
    const param = {
      ...this.condition,
    };
    param.cur_page = this.pagination1.page;
    param.page_size = this.pagination1.size;
    ApiGetMyPatientControlHistoryList(param).then((res) => {
      if (res.success) {
        this.autoincrementData = res.data;
        this.pagination1.total = res.page.total_size;
        this.autoincrementData.forEach((item: any) => {
          item.summaryFormat = item.summry ? item.summry.split("\n") : "-";
        });
      } else {
        this.emptyContent = "抱歉，暂时没有找到相关数据，请您联系品驰工作人员";
      }
    });
  }
  //点击查询按钮触发的事件
  clickSearch() {
    this.pagination.page = 1;
    this.pagination.size = 10;
    if (this.tabIndex == 0) {
      this.updateTableData();
    } else if (this.tabIndex == 1) {
      this.getDataList();
    }
  }
  //点击重置按钮触发的事件
  resetCondition() {
    this.pagination.size = 10;
    this.$set(this.pagination, "size", 10);
    this.$set(this.condition, "size", 10);
    this.$set(this.pagination, "page", 1);
    //清空查询条件
    //重置分页器
    this.condition = {
      database_id: localStorage.getItem("database_id"),
      date_start: "",
      date_end: "",
      cur_page: 1,
      page_size: 10,
    };
    //重置表格数据
    if (this.tabIndex == 0) {
      this.updateTableData();
    } else if (this.tabIndex == 1) {
      this.getDataList();
    }
  }

  getDate() {
    const time = new Date();
    return `${time.getFullYear()}${this.format(
      time.getMonth() + 1
    )}${this.format(time.getDate())}`;
  }
  //点击导出按钮触发的事件
  extract() {
    this.isExporting = true;
    const endTime = this.condition.date_end;
    if (endTime) {
      const timeLimit = 24 * 60 * 60 * 1000;
      this.condition.date_end = endTime + timeLimit - 1;
    }
    const param = {
      ...this.condition,
      cur_page: this.pagination.page,
      page_size: this.pagination.size,
    };
    ApiGetExportControlHistoryFirst(param).then((res: any) => {
      if (typeof res.success == "undefined") {
        ApiGetExportControlHistory(param).then((res) => {
          this.isExporting = false;
          const url = URL.createObjectURL(res);
          const a = document.createElement("a");
          a.download = this.getDate() + "参数历史.xlsx";
          document.body.appendChild(a);
          a.href = url;
          a.click();
          a.remove();
        });
      } else {
        this.isExporting = false;
        this.$message.warning(res.data.message);
      }
    });
    // 程控历史导出
  }
  //分页器改变页显示数触发函数
  handleSizeChange(size: any) {
    this.pagination.size = size;
    this.pagination.page = 1;
    this.updateTableData();
  }
  //分页器改变当前显示页触发函数
  handleCurrentChange(page: any) {
    this.pagination.page = page;
    this.updateTableData();
  }
  //分页器改变页显示数触发函数
  handleSizeChange1(size: any) {
    this.pagination1.size = size;
    this.pagination1.page = 1;
    this.getDataList();
  }
  //分页器改变当前显示页触发函数
  handleCurrentChange1(page: any) {
    this.pagination1.page = page;
    this.getDataList();
  }
  //确认对话框是否显示
  showConfirmDialog = false;
  //关闭确认对话框函数
  closeConfirmDialog() {
    this.showConfirmDialog = false;
  }
  //确认对话框确定按钮点击触发
  completeConfirmConfirm() {
    this.extract();
    this.closeConfirmDialog();
  }
}
