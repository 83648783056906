//既往环境接触史
import {
  computed,
  reactive,
  watch,
  getCurrentInstance,
} from "@vue/composition-api";
import {
  ApiEnvironmentalExposureSave,
  ApiEnvironmentalExposureSavePsp,
} from "@/apis/index";
interface Module {
  environmentData: object;
  environmentDetails: object;
}

function environmentModuleFun(ins: any, MedicalHistoryDetailsFun: any) {
  const environmentModule: any = reactive({
    environmentForm: {
      hisenviro_occupation: "", // 从事时间最长职业
      hisenviro_occuyears: "", // 从事时间最长职业时长--年
      hisenviro_occu01years: "", // 从事时间最长职业时长--月
      hisenviro_occu03: "", // 是否从事化学溶剂工作
      hisenviro_occu01: "", //是否从事需要灭害剂（包括：除草剂、杀虫剂、灭菌剂、熏蒸剂等）的工作
      hisenviro_occu05: "", // 是否从事其它化学制剂或者气雾剂
      hisenviro_occu04: "", // 是否从事接触重金属的工作
      hisenviro_occu02: "", // 是否曾经在家里接触了灭害剂
      hisenviro_occu06: "", // 是否从事电焊工作
    } as any,
    occupationList: ["工人", "教师", "农民", "干部", "学生", "职员", "其它"],
    environmentDrawer: false,

    cleanCodes(value: string) {
      if (value != "中国") {
        environmentModule.environmentForm.codes = [];
      }
    },

    //抽屉open事件
    environmentClick(environmentEditForm: any) {
      environmentModule.environmentForm = JSON.parse(
        JSON.stringify(environmentEditForm)
      );
      // environmentModule.environmentForm.hisenviro_id = environmentEditForm.hisenviro_id
      environmentModule.environmentDrawer = true;
    },
    saveEnvironmentHistory() {
      const param = {
        user_id: Number(ins.$route.query.user_id),
        ...environmentModule.environmentForm,
      };
      ApiEnvironmentalExposureSave(param).then((res) => {
        if (res.success) {
          //
          ins.$message.success("修改成功！");
          environmentModule.environmentDrawer = false;
          // //详情页面数据接口调用
          MedicalHistoryDetailsFun();
        } else {
          ins.$message.error(res.message);
        }
      });
    },

    saveEnvironmentHistoryPsp() {
      const param = {
        user_id: Number(ins.$route.query.user_id),
        ...environmentModule.environmentForm,
      };
      ApiEnvironmentalExposureSavePsp(param).then((res) => {
        if (res.success) {
          //
          ins.$message.success("修改成功！");
          environmentModule.environmentDrawer = false;
          // //详情页面数据接口调用
          MedicalHistoryDetailsFun();
        } else {
          ins.$message.error(res.message);
        }
      });
    },
    environmentCanelBtn() {
      ins.confirmDialogVisible = true;
    },
    environmentRadio(property: string, content: string) {
      if (property == "hisenviro_ismove") {
        environmentModule.environmentForm["hisenviro_movenum"] = "";
      }
      if (environmentModule.environmentForm[property] == content) {
        environmentModule.environmentForm[property] = "";
      } else {
        environmentModule.environmentForm[property] = content;
      }
      environmentModule.environmentForm = {
        ...environmentModule.environmentForm,
      };
    },
  });
  return {
    environmentModule,
  };
}
export { environmentModuleFun };
