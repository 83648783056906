import {
  computed,
  defineComponent,
  getCurrentInstance,
  onMounted,
  reactive,
  toRefs,
  watch,
} from "@vue/composition-api";

interface Module {
  test: () => void;
}

function scrollModuleFun(ins: any) {
  const scrollModule: any = reactive({
    //当前选中节点
    currentTreeNode: "#Diagnostic",
    //最大滚动高度
    maximumScrollHeight: 0,
    //菜单列表
    data: [
      {
        id: "#Diagnostic",
        label: "症状诊断",
      },
      {
        id: "#Medical",
        label: "现病史",
        children: [
          {
            id: "#Medical",
            label: "起病情况",
          },
          {
            id: "#Progress",
            label: "进展顺序",
          },
          {
            id: "#Cardinal",
            label: "主要症状",
          },
          {
            id: "#Rest",
            label: "其它运动症状",
          },
        ],
      },
      {
        id: "#Previous",
        label: "既往史",
        children: [
          {
            id: "#Previous",
            label: "既往疾病史",
          },
          {
            id: "#environment",
            label: "既往环境接触史",
          },
          {
            id: "#livingHabit",
            label: "既往生活习惯",
          },
        ],
      },
      {
        id: "#irritability",
        label: "过敏史",
      },
      {
        id: "#family",
        label: "家族史",
      },
      {
        id: "#pharmacy",
        label: "用药史",
        children: [
          {
            id: "#pharmacy",
            label: "一般用药史",
          },
          {
            id: "#exercise",
            label: "运动并发症",
          },
        ],
      },
      {
        id: "#otherTreatment",
        label: "其它治疗史",
      },
      {
        id: "#EpidemiologicalHistory",
        label: "流行病学史",
      },
    ],
    //菜单对应的元素列表
    moduleList: [
      // 症状诊断
      {
        id: "#Diagnostic",
        top: Infinity,
        height: Infinity,
      },
      // 起病情况
      {
        id: "#Medical",
        top: Infinity,
        height: Infinity,
      },
      // 进展顺利
      {
        id: "#Progress",
        top: Infinity,
        height: Infinity,
      },
      // 主要症状
      {
        id: "#Cardinal",
        top: Infinity,
        height: Infinity,
      },
      // 其它运动症状
      {
        id: "#Rest",
        top: Infinity,
        height: Infinity,
      },
      //其他治疗史
      {
        id: "#otherTreatment",
        top: Infinity,
        height: Infinity,
      },
      //流行病学史
      {
        id: "#EpidemiologicalHistory",
        top: Infinity,
        height: Infinity,
      },
      //运动并发症
      {
        id: "#exercise",
        top: Infinity,
        height: Infinity,
      },
      //一般用药史
      {
        id: "#pharmacy",
        top: Infinity,
        height: Infinity,
      },
      //家族史
      {
        id: "#family",
        top: Infinity,
        height: Infinity,
      },
      //过敏史
      {
        id: "#irritability",
        top: Infinity,
        height: Infinity,
      },
      //既往生活习惯
      {
        id: "#livingHabit",
        top: Infinity,
        height: Infinity,
      },
      //既往环境接触史
      {
        id: "#environment",
        top: Infinity,
        height: Infinity,
      },
      //既往疾病史
      {
        id: "#Previous",
        top: Infinity,
        height: Infinity,
      },
    ],
    handleScroll() {
      scrollModule.preShake(scrollModule.showTop, 500)();
    },
    updateScroll() {
      const contentDom: any = document.getElementById("content");
      //初始化最大滚动高度
      const lastModuleEl: any = document.getElementById(
        "EpidemiologicalHistory"
      );
      scrollModule.maximumScrollHeight =
        lastModuleEl.offsetTop +
        lastModuleEl.offsetHeight -
        contentDom.clientHeight -
        40 +
        170;
      // console.log('最大滚动高度：',scrollModule.maximumScrollHeight);
      //初始化元素列表中元素高度
      const moduleList = scrollModule.moduleList;
      moduleList.forEach((item: any, index: number) => {
        scrollModule.moduleList[index].top =
          document.querySelector(item.id).offsetTop - 80;
      });
    },
    //直接改变主体内容滚动高度，会触发监听的滚动事件
    //使用元素实例的scrollIntoView方法依然会触发监听的滚动事件
    showTop() {
      scrollModule.updateScroll();
      //获取当前滚动高度
      const contentDom: any = document.getElementById("content");
      const currentTop = contentDom.scrollTop;
      // console.log("当前滚动高度值：" + currentTop);

      const moduleList = scrollModule.moduleList;
      //150为判断滚动到顶部（既往病史）的边界值
      //如果滚动高度小于150，则设定菜单为既往病史
      if (currentTop < 224) {
        (ins.$refs.treeRef as any).setCurrentKey(
          moduleList[moduleList.length - 1].id
        );
        return;
      }
      //用于判断滚动到底部（其他治疗史）的边界值
      //如果滚动高度大于此边界，则设定菜单为其他治疗史
      const criticalValue = scrollModule.maximumScrollHeight - 150;
      if (currentTop > criticalValue || currentTop == criticalValue) {
        if (scrollModule.clickMenuItem) {
          (ins.$refs.treeRef as any).setCurrentKey(scrollModule.clickMenuItem);
        } else {
          (ins.$refs.treeRef as any).setCurrentKey(moduleList[0].id);
        }
        //对菜单元素失焦处理
        scrollModule.outFocus();
        return;
      }
      //对除了既往疾病史和其他治疗史的模块，进行高度判断
      for (let i = 0; i < moduleList.length; i++) {
        const moduleHeight = moduleList[i].top;
        if (currentTop > moduleHeight || currentTop == moduleHeight) {
          (ins.$refs.treeRef as any).setCurrentKey(moduleList[i].id);
          //对菜单元素失焦处理
          scrollModule.outFocus();
          return;
        }
      }
    },
    outFocus() {
      const input = document.createElement("input");
      document.body.prepend(input);
      input.setAttribute("style", "position:absolute;top:0px;left:0px;");
      input.focus();
      input.remove();
    },
    //防抖用的计时器变量
    timer: null,
    preShake(fn: any, timeout: any) {
      return function () {
        scrollModule.timer = setTimeout(fn, timeout);
        if (scrollModule.timer) {
          clearTimeout(scrollModule.timer);
        }
      };
    },
    clickMenuItem: null,
    //点击菜单事件
    clickNode(data: any, node: any, self: any) {
      scrollModule.updateScroll();
      const contentDom: any = document.getElementById("content");

      const currentDom = document.querySelector(data.id);
      const currentModuleElHeight = scrollModule.moduleList.find(
        (listItem: any) => {
          return listItem.id == data.id;
        }
      ).top;
      scrollModule.clickMenuItem = data.id;
      //直接改变主体内容滚动高度，会触发监听的滚动事件
      //使用元素实例的scrollIntoView方法依然会触发监听的滚动事件
      // console.log("当前点击菜单对应元素判断高度值:" + currentDom.offsetTop);
      if (currentDom.offsetTop > scrollModule.maximumScrollHeight) {
        contentDom.scrollTop = scrollModule.maximumScrollHeight;
      } else {
        contentDom.scrollTop = currentModuleElHeight + 1;
      }
      setTimeout(() => {
        scrollModule.clickMenuItem = null;
      }, 600);
    },
  });
  return {
    scrollModule,
  };
}

export { scrollModuleFun };
