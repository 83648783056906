














































import { Component, Vue } from "vue-property-decorator";
import AddMedicalRecord1 from "./MedicalHistory.vue";
import AddMedicalRecord2 from "./MedicalHistory2.vue";
import AddMedicalRecord3 from "./MedicalHistory3.vue";
import { getTemcode, addTemcode } from "@/apis";
@Component({
  components: {
    AddMedicalRecord1,
    AddMedicalRecord2,
    AddMedicalRecord3,
  },
})
export default class MedicalHistoryCard extends Vue {
  // 页面显隐属性
  caseRecordType = "card" as any;

  get userId(): any {
    return this.$route.query.user_id;
  }

  dialogAdd = false;

  env = process.env.NODE_ENV;

  isLiu = false;

  cardHandle() {
    // this.isLiu = true;
    // console.log(window.location.host, "window.location.host");
    if (window.location.host == "localhost:8080") {
      this.isLiu = false;
      return;
    }
    if (this.env == "development") {
      if (window.location.host == "192.168.1.78:83") {
        return (this.isLiu = false);
      } else if (window.location.host == "192.168.1.78:124") {
        return (this.isLiu = true);
      }
    } else if (this.env == "test") {
      if (window.location.host == "192.168.1.78:84") {
        return (this.isLiu = false);
      } else if (window.location.host == "192.168.1.78:125") {
        return (this.isLiu = true);
      }
    } else if (this.env == "production") {
      if (window.location.host == "doctorserv.pinsmedical.com") {
        return (this.isLiu = false);
      } else if (window.location.host == "cheeloo-psp.pinsmedical.com") {
        return (this.isLiu = true);
      }
    }
  }

  backCaseRecord() {
    this.caseRecordType = "card";
    this.getMedicalHistoryCardList();
  }

  medicalHistoryCardInfo = {} as any;
  switchType(val: any) {
    switch (val) {
      case 1:
        return "addMedicalRecord1";
      case 2:
        return "addMedicalRecord2";
      case 3:
        return "addMedicalRecord3";
    }
  }
  async getMedicalHistoryCardList() {
    try {
      const result = await getTemcode(this.userId);
      if (result.success) {
        this.medicalHistoryCardInfo = result.data;
        if (result.data && this.medicalHistoryCardInfo.tem_code) {
          this.caseRecordType = this.switchType(
            this.medicalHistoryCardInfo.tem_code
          );
          localStorage.setItem(
            "addMedicalRecordIndex",
            this.medicalHistoryCardInfo.tem_code
          );
        } else {
          this.caseRecordType = "card";
        }
        console.log(this.caseRecordType, "this.caseRecordType");
      }
    } catch (error) {
      console.log(error);
    }
  }
  destroyed() {
    localStorage.removeItem("addMedicalRecordIndex");
  }
  async cardFunHandle(id: any) {
    const params = {
      user_id: this.userId,
      tem_code: id,
    };
    const res = await addTemcode(params);
    if (res.success) {
      this.caseRecordType = "addMedicalRecord" + id;
      localStorage.setItem("addMedicalRecordIndex", id);
      console.log(this.caseRecordType, "this.caseRecordType");
    } else {
      this.$message.error(res.message);
    }
  }

  created() {
    this.getMedicalHistoryCardList();
    this.cardHandle();
  }
}
