// 就诊记录：新增就诊记录取消和新增逻辑
import { computed, onMounted, reactive, watch } from "@vue/composition-api";
import { editChiefComplaint } from "@/apis";
import { DirectoryEnum } from "@/constants";
import { uploadFile } from "@/utils/alioss";
import { Base64 } from "js-base64";
function addMedicalRecordModuleFun(ins: any) {
  const addMedicalRecordModule: any = reactive({
    confirmDialogVisible: false,
    loading: false,
    backCaseRecord() {
      ins.confirmDialogVisible = true;
    },
    enclosures: [],
    closeConfirmDialog() {
      ins.confirmDialogVisible = false;
    },

    completeConfirmConfirm() {
      ins.$emit("backCaseRecord");
    },

    selectChange(index: number) {
      ins.symptomDiagnosisForm.data[index].chatidiag_second = "";
    },

    //上传文件限制个数
    handleExceed(files: any, fileList: any) {
      ins.$message.warning(`当前限制选择 10 个文件`);
    },

    //根据插槽的file对象，拿到文件名，获取后缀来判断使用img还是video，因为插槽的file对象没有type
    showVideo(file: any) {
      if (file) {
        const res = file.split(".");
        const video = res.some((item: any) => {
          return item == "mp4";
        });
        const videos = res.some((item: any) => {
          return item == "mov";
        });
        if (video) {
          return "视频";
        } else if (videos) {
          return "视频";
        } else {
          return "照片";
        }
      }
    },

    //在线预览视频
    videoClick(url: any) {
      window.open(
        `https://pins-gateway.pinsdev.com/kkfileview/onlinePreview?url=${encodeURIComponent(
          Base64.encode(url)
        )}`
      );
    },
    //在线图片
    imgClick(url: any) {
      window.open(
        `https://pins-gateway.pinsdev.com/kkfileview/onlinePreview?url=${encodeURIComponent(
          Base64.encode(url)
        )}`
      );
    },

    ondeleteSymptomDiagnosisFormItem(index: number) {
      const h = ins.$createElement;
      ins
        .$msgbox({
          title: "提示",
          message: h("p", undefined, [
            h("img", {
              attrs: {
                src: require("../../../../../assets/img/icon-confirm-tips.png"),
              },
            }),
            h("span", { class: "message_text" }, " 确认删除吗？ "),
          ]),
          showCancelButton: true,
          confirmButtonText: "确 认",
          cancelButtonText: "取 消",
          cancelButtonClass: "message_cancel",
          confirmButtonClass: "message_confirm",
          closeOnClickModal: false,
        })
        .then(() => {
          ins.symptomDiagnosisForm.data.splice(index, 1);
        })
        .catch(() => {
          console.log("取消");
        });
    },

    // 上传图片前模块
    beforeAvatarUpload(file: any) {
      const isJpg = file.type == "image/jpg";
      const isJpeg = file.type == "image/jpeg";
      const isMp4 = file.type == "video/mp4";
      const isCan = file.size / 1024 / 1024 < 100;
      if (isJpg || isMp4 || isJpeg) {
        if (isCan) {
          return new Promise((resolve, reject) => {
            const isCan = file.size / 1024 / 1024 < 100;
            const isVideo = file.type == "video/mp4";
            const isJPG = file.type === "image/jpeg";
            const isPNG = file.type === "image/png";
            const fileSize = file.size / 1024; //换成kb
            let bool = false;
            if (isCan) {
              if (isJPG || isPNG || isVideo) {
                //判断是否符合格式要求
                bool = true;
              } else {
                return Promise.reject;
              }
              if (bool) {
                if (isVideo) {
                  return resolve(file);
                } else {
                  //如果格式符合要求，但是size过大，对图片进行处理
                  const image = new Image();
                  let resultBlob: any = "";
                  image.src = URL.createObjectURL(file);
                  image.onload = () => {
                    resultBlob = addMedicalRecordModule.compressUpload(
                      image,
                      fileSize,
                      200
                    ); //Blob
                    resolve(resultBlob);
                  };
                }
              } else {
                return Promise.reject;
              }
            } else {
              ins.$message.warning("超出文件大小！");
              return false;
            }
          });
        } else {
          ins.$message.warning("超出文件大小");
          return false;
        }
      } else {
        ins.$message.warning("只支持jpg和mp4格式文件！");
        return false;
      }
    },

    // 压缩图片
    compressUpload(image: any, fileSize: number, ratio: number): any {
      const canvas = document.createElement("canvas"); //创建画布元素
      const ctx = canvas.getContext("2d");
      const width = image.width;
      const height = image.height;
      canvas.width = width;
      canvas.height = height;
      let compressRatio: any;
      if (fileSize < 200) {
        compressRatio = 1;
      } else {
        compressRatio = ratio / fileSize;
      }

      if (ctx === null) {
        ins.$message.error("画布创建失败");
      } else {
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(image, 0, 0, width, height);
        const compressData = canvas.toDataURL("image/jpeg", compressRatio); //等比压缩
        const blobImg = addMedicalRecordModule.dataURItoBlob(compressData); //base64转Blob
        if (blobImg.size > 200 * 1024 && compressRatio < 1) {
          return addMedicalRecordModule.compressUpload(
            image,
            fileSize,
            ratio - 30
          );
        }
        return blobImg;
      }
    },

    dataURItoBlob(data: any) {
      let byteString;
      if (data.split(",")[0].indexOf("base64") >= 0) {
        byteString = atob(data.split(",")[1]); //转二进制
      } else {
        byteString = unescape(data.split(",")[1]);
      }
      const mimeString = data.split(",")[0].split(":")[1].split(";")[0];
      const ia = new Uint8Array(byteString.length);
      for (let i = 0; i < byteString.length; i += 1) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ia], { type: mimeString });
    },

    // 移除图片
    imgHandleRemove(file: any, fileList: any) {
      addMedicalRecordModule.enclosures =
        addMedicalRecordModule.enclosures.filter((item: any, index: any) => {
          return item.url != file;
        });
      const uploadImg = ins.$refs["upload-img"] as any;
      uploadImg.handleRemove(file);
    },

    // 上传附件--图片-----问题分析模块
    async uploadHttpRequestPicture(params: any) {
      const file = params.file;
      const fileName = file.name;
      addMedicalRecordModule.uploadAttachment(fileName, file);
    },
    // 上传 给上传图片和文件的函数使用
    async uploadAttachment(file: File) {
      //        upload_dir: `picture/${
      const fileName = new Date().getTime().toString();
      const fileExt = file.name.split(".").pop();

      const res = await uploadFile(
        DirectoryEnum.PICTRUE,
        file,
        fileName + "." + fileExt,
        `/${
          JSON.parse(localStorage.getItem("doctorLoginInfo") as any).id
        }/${localStorage.getItem("database_id")}`
      );
      return res;
    },

    // 接收具体路径path的返回值；
    async uploadHttpRequest(params: any) {
      const file = params.file;
      this.uploadHttpRequest(params);
    },

    //获取文件后缀名
    getExtension(name: string) {
      return name.substring(name.lastIndexOf("."));
    },

    saveMedicalRecord() {
      const param = { ...ins.chiefComplaintForm };
      param.user_id = ins.$route.query.user_id;
      addMedicalRecordModule.enclosures = addMedicalRecordModule.enclosures.map(
        (item: any) => {
          return { url: item.url };
        }
      );
      param.resource_path = JSON.stringify(addMedicalRecordModule.enclosures);
      const mainFormRef = ins.$refs.chiefComplaintRuleForm as any;
      mainFormRef.validate((mainValid: boolean) => {
        if (mainValid) {
          editChiefComplaint(param).then((res: any) => {
            if (res.success) {
              ins.$message.success("添加成功！");
              ins.completeConfirmConfirm();
            } else {
              ins.$message.error(res.message);
            }
          });
        }
      });
    },
  });
  return {
    addMedicalRecordModule,
  };

  //
}
export { addMedicalRecordModuleFun };
