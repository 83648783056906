









































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import { parseTime } from "@/utils";
import {
  ApiGetOperationRecordList,
  ApiGetAddOperationRecord,
  ApiGetDelOperationRecord,
  ApiStimAdd,
  ApiVideoAdd,
  ApiImageAdd,
  ApiAuthorizationAdd,
  ApiAuthorizationDel,
  ApiAuthorizationList,
  ApiVideoDel,
  ApiVideoList,
  ApiStimDel,
  ApiStimList,
  ApiImageDel,
  ApiImageList,
  ApiMicroelecAdd,
  ApiDiscussionDel,
  ApiDiscussionAdd,
  ApiMicroelecDel,
  ApiMicroelecList,
  ApiDiscussionList,
  ApiOperationTotalList,
} from "@/apis";
import { Form } from "element-ui";
import { DirectoryEnum } from "@/constants";
import { uploadFile } from "@/utils/alioss";
@Component({
  filters: {
    parseTimeFilter(time: any, str: string) {
      if (time) {
        return parseTime(time, str);
      } else {
        return "-";
      }
    },
  },
})
export default class ControlHistory extends Vue {
  upIcon = require("../../../../assets/img/icon-up.png");
  downIcon = require("../../../../assets/img/icon-down.png");

  menuItems = [
    {
      label: "手术记录",
      id: "operationRecord",
      active: true,
      file: "operation_record",
    },
    {
      label: "术前讨论记录",
      id: "operationBeforeRecord",
      active: false,
      file: "discussion",
    },
    {
      label: "手术委托书",
      id: "operationBook",
      active: false,
      file: "auth",
    },
    {
      label: "微电极记录",
      id: "miniRecord",
      active: false,
      file: "mini",
    },
    {
      label: "临时刺激记录",
      id: "tempRecord",
      active: false,
      file: "temp",
    },
    {
      label: "影像记录",
      id: "videoRecord",
      active: false,
      file: "image",
    },
    {
      label: "手术视频记录",
      id: "operationVideoRecord",
      active: false,
      file: "video",
    },
  ];
  currentMenu = "";
  addShowDialog = false;
  loading = false;
  dataNoneShow = false;
  userId = "";

  addFormTotalData = {
    discussion: {
      discussdate: "",
      compere: "",
      affiliatedperson: "",
      discusssubject: "",
      discussrecord: "",
      resourcepath: "",
      note: "",
    },
    auth: {
      patientname: "",
      relation: "",
      sex: "",
      age: "",
      idcard: "",
      signresult: "",
      resourcepath: "",
      note: "",
    },
    temp: {
      testdoctor: "",
      patientsymptom: "",
      fristimprove: "",
      testresult: "",
      testdevice: "",
      recorduser: "",
      resourcepath: "",
      note: "",
      param: "",
    },
    mini: {
      testdoctor: "",
      rightorleft: "",
      implantedtarget: "",
      implanteddepth: "",
      targettype: "",
      x: "",
      y: "",
      z: "",
      testresult: "",
      testreport: "",
      resourcepath: "",
      testdevice: "",
      recorduser: "",
      note: "",
      file_path: "",
    },
    image: {
      img_type: "",
      img_dateime: "",
      img_part: "",
      img_result: "",
      img_resourcepath: "",
      img_note: "",
    },
    video: {
      videodate: "",
      resourcedescription: "",
      resourcepath: "",
      note: "",
    },
  } as any;

  // 新增手术记录
  addForm = {
    database_id: localStorage.getItem("database_id"),
    operation_date: "",
    operation_type: "",
    equipment_brand: "",
    implantation_info: "",
    equipment_type: "",
    remark: "",
  };
  dataList = [] as any;
  addNumber = 1;
  // 设备信息
  equipmentInfo = {
    electrode1: "",
    target1: "",
    electrodetyoe1: "",
    electrode2: "",
    target2: "",
    electrodetyoe2: "",
    electrode3: "",
    target3: "",
    electrodetyoe3: "",
    electrode4: "",
    target4: "",
    electrodetyoe4: "",
    electrode5: "",
    target5: "",
    electrodetyoe5: "",
    electrode6: "",
    target6: "",
    electrodetyoe6: "",
  };
  writeNumber = 0;
  // 手术类型
  TypeOfOperation = [
    "脑深部电刺激术（DBS）",
    "脊髓电刺激术（SCS）",
    "迷走神经电刺激术（VNS）",
    "骶神经电刺激术（SNM）",
    "其他",
  ];
  deepOfOperation = [
    "0",
    "+0.5",
    "+1.0",
    "+1.5",
    "+2.0",
    "+2.5",
    "-0.5",
    "-1.0",
    "-1.5",
    "-2.0",
    "-2.5",
  ];
  // 新增手术记录校验
  addFormRules = {
    operation_date: [
      { required: true, message: "请选择手术时间", trigger: "change" },
    ],
    operation_type: [
      { required: true, message: "请选择手术类型", trigger: "change" },
    ],
  };

  addFormTotalRules = {
    disscussRules: {
      discussdate: [
        { required: true, message: "请选择讨论时间", trigger: "change" },
      ],
      compere: [{ required: true, message: "请填写主持人", trigger: "blur" }],
      affiliatedperson: [
        { required: true, message: "请填写参与人", trigger: "blur" },
      ],
      discusssubject: [
        { required: true, message: "请填写讨论主题", trigger: "blur" },
      ],
      discussrecord: [
        { required: true, message: "请填写讨论记录", trigger: "blur" },
      ],
    },
    authRules: {},
    miniRules: {
      testdoctor: [
        { required: true, message: "请填写测试医生", trigger: "blur" },
      ],
      rightorleft: [
        { required: true, message: "请选择测别", trigger: "change" },
      ],
      implantedtarget: [
        { required: true, message: "请选择植入靶点", trigger: "change" },
      ],
      targettype: [
        { required: true, message: "请选择靶点类型", trigger: "change" },
      ],
      implanteddepth: [
        {
          required: true,
          message: "请填写植入深度&信号强度",
          trigger: "change",
        },
      ],
    },
    tempRules: {},
    imageRules: {
      img_type: [
        { required: true, message: "请选择影像类型", trigger: "change" },
      ],
      img_dateime: [
        { required: true, message: "请选择影像时间", trigger: "change" },
      ],
      img_part: [{ required: true, message: "请选择部位", trigger: "change" }],
      img_result: [
        { required: true, message: "请填写检查结果描述", trigger: "blur" },
      ],
      img_resourcepath: [
        { required: true, message: "请选择资源路径", trigger: "change" },
      ],
    },
    videoRules: {
      videodate: [
        { required: true, message: "请选择拍摄时间", trigger: "change" },
      ],
      resourcedescription: [
        { required: true, message: "请填写视频描述", trigger: "blur" },
      ],
      resourcepath: [
        { required: true, message: "请选择资源路径", trigger: "change" },
      ],
    },
  };
  // 删除
  delOperation(id: any) {
    this.$confirm("你确定要删除这条手术记录么？", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
      .then(async () => {
        const res = await ApiGetDelOperationRecord({ id });
        if (res.success) {
          // const res = await ApiGetOperationRecordList({
          //   database_id: localStorage.getItem("database_id"),
          // });
          // if (res.success) {
          //   this.$message.warning("删除成功！");
          //   this.dataList = res.data.map((item: any) => {
          //     if (item.implantation_info != "") {
          //       return {
          //         ...item,
          //         implantation_info: JSON.parse(item.implantation_info),
          //       };
          //     } else {
          //       return item;
          //     }
          //   });
          // }
          this.getDatailLsit();
          this.$message.success("删除成功！");
        }
      })
      .catch(() => {
        return 1;
      });
  }

  async getDatailLsit() {
    this.loading = true;
    const res = await ApiGetOperationRecordList({
      database_id: localStorage.getItem("database_id"),
    });
    if (res.success) {
      if (res.data.length == 0) {
        this.dataNoneShow = true;
      } else {
        this.dataNoneShow = false;
      }
      this.dataList.implantationInfos = {} as any;
      this.dataList = res.data.map((item: any, id: number) => {
        if (item.implantation_info != "") {
          this.dataList.implantationInfos[id] = JSON.parse(
            item.implantation_info
          );
          console.log(this.dataList.implantationInfos, "====");
          return {
            ...item,
            implantation_info: JSON.parse(item.implantation_info),
          };
        } else {
          return item;
        }
      });
      this.loading = false;

      if (this.dataList.length) {
        this.table.table1 = true;
      }
    }
  }
  totalObjData = [] as any;

  getTotalData() {
    ApiOperationTotalList({}, this.userId).then((res) => {
      this.totalObjData = res.data;
      if (res.data.discussion_list.length) {
        this.table.table2 = true;
      }
      if (res.data.authorization_list.length) {
        this.table.table3 = true;
      }
      if (res.data.microele_list.length) {
        this.table.table4 = true;
      }
      if (res.data.stim_list.length) {
        this.table.table5 = true;
      }
      if (res.data.image_list.length) {
        this.table.table6 = true;
      }
      if (res.data.video_list.length) {
        this.table.table7 = true;
      }
    });
  }

  // 获取数据
  async created() {
    this.userId = this.$route.query.user_id as string;
    console.log(this.userId);
    await this.getDatailLsit();
    this.getTotalData();
  }
  // 取消添加
  cancelOperationRecord() {
    (this.$refs.addForm as any).clearValidate();
    this.addForm = {
      database_id: localStorage.getItem("database_id"),
      operation_date: "",
      operation_type: "",
      equipment_brand: "",
      implantation_info: "",
      equipment_type: "",
      remark: "",
    };
    this.equipmentInfo = {
      electrode1: "",
      target1: "",
      electrodetyoe1: "",
      electrode2: "",
      target2: "",
      electrodetyoe2: "",
      electrode3: "",
      target3: "",
      electrodetyoe3: "",
      electrode4: "",
      target4: "",
      electrodetyoe4: "",
      electrode5: "",
      target5: "",
      electrodetyoe5: "",
      electrode6: "",
      target6: "",
      electrodetyoe6: "",
    };
    this.addShowDialog = false;
  }
  cancelTotalForm() {
    // (this.$refs.tempForm as any).clearValidate();
    // (this.$refs.miniForm as any).clearValidate();
    // (this.$refs.imageForm as any).clearValidate();
    // (this.$refs.videoForm as any).clearValidate();
    // (this.$refs.authForm as any).clearValidate();
    this.addShowDialog2 = false;
    this.addShowDialog3 = false;
    this.addShowDialog4 = false;
    this.addShowDialog5 = false;
    this.addShowDialog6 = false;
    this.addShowDialog7 = false;

    this.handleKeysBlank(this.addFormTotalData.discussion);
    this.handleKeysBlank(this.addFormTotalData.temp);
    this.handleKeysBlank(this.addFormTotalData.auth);
    this.handleKeysBlank(this.addFormTotalData.video);
    this.handleKeysBlank(this.addFormTotalData.image);
    this.handleKeysBlank(this.addFormTotalData.mini);
  }

  // 添加手术记录
  addOperationRecord() {
    (this.$refs.addForm as Form).validate(async (val: any) => {
      if (val) {
        const array = [
          {
            id: 1,
            electrode: this.equipmentInfo.electrode1,
            target: this.equipmentInfo.target1,
            electrodetyoe: this.equipmentInfo.electrodetyoe1,
          },
          {
            id: 2,
            electrode: this.equipmentInfo.electrode2,
            target: this.equipmentInfo.target2,
            electrodetyoe: this.equipmentInfo.electrodetyoe2,
          },
          {
            id: 3,
            electrode: this.equipmentInfo.electrode3,
            target: this.equipmentInfo.target3,
            electrodetyoe: this.equipmentInfo.electrodetyoe3,
          },
          {
            id: 4,
            electrode: this.equipmentInfo.electrode4,
            target: this.equipmentInfo.target4,
            electrodetyoe: this.equipmentInfo.electrodetyoe4,
          },
          {
            id: 5,
            electrode: this.equipmentInfo.electrode5,
            target: this.equipmentInfo.target5,
            electrodetyoe: this.equipmentInfo.electrodetyoe5,
          },
          {
            id: 6,
            electrode: this.equipmentInfo.electrode6,
            target: this.equipmentInfo.target6,
            electrodetyoe: this.equipmentInfo.electrodetyoe6,
          },
        ];
        const list = array.filter((item) => {
          return (
            item.electrode != "" ||
            item.target != "" ||
            item.electrodetyoe != ""
          );
        });
        this.addForm = {
          ...this.addForm,
          database_id: localStorage.getItem("database_id"),
        };
        this.addForm.implantation_info = JSON.stringify(list);
        const res = await ApiGetAddOperationRecord(this.addForm);
        if (res.success) {
          this.addForm = {
            database_id: localStorage.getItem("database_id"),
            operation_date: "",
            operation_type: "",
            equipment_brand: "",
            implantation_info: "",
            equipment_type: "",
            remark: "",
          };
          this.addShowDialog = false;
          this.getDatailLsit();
          this.equipmentInfo = {
            electrode1: "",
            target1: "",
            electrodetyoe1: "",
            electrode2: "",
            target2: "",
            electrodetyoe2: "",
            electrode3: "",
            target3: "",
            electrodetyoe3: "",
            electrode4: "",
            target4: "",
            electrodetyoe4: "",
            electrode5: "",
            target5: "",
            electrodetyoe5: "",
            electrode6: "",
            target6: "",
            electrodetyoe6: "",
          };
          this.$message.success("操作成功！");
        } else {
          this.$message.error(res.message);
        }
      }
    });
  }

  // 添加添加类别
  NumberAdd() {
    if (this.addNumber > 4) return this.$message.warning("最多添加6个电极侧别");
    this.addNumber = this.addNumber + 1;
  }

  // 清除点击类别数据
  NumberDel(index: any) {
    switch (index) {
      case 2: {
        (this.equipmentInfo.electrode2 = ""),
          (this.equipmentInfo.target2 = ""),
          (this.equipmentInfo.electrodetyoe2 = "");
        break;
      }
      case 3: {
        this.$set(this.equipmentInfo, "electrode3", "");
        this.$set(this.equipmentInfo, "target3", "");
        this.$set(this.equipmentInfo, "electrodetyoe3", "");
        break;
      }
      case 4: {
        (this.equipmentInfo.electrode4 = ""),
          (this.equipmentInfo.target4 = ""),
          (this.equipmentInfo.electrodetyoe4 = "");
        break;
      }
      case 5: {
        (this.equipmentInfo.electrode5 = ""),
          (this.equipmentInfo.target5 = ""),
          (this.equipmentInfo.electrodetyoe5 = "");
        break;
      }
      case 6: {
        (this.equipmentInfo.electrode6 = ""),
          (this.equipmentInfo.target6 = ""),
          (this.equipmentInfo.electrodetyoe6 = "");
        break;
      }
    }
    if (this.addNumber < 1) return;
    this.addNumber -= 1;
  }
  writeChang(val: any) {
    // console.log(val);
  }
  formatDate(timeStamp: number | string | null) {
    if (timeStamp == null) {
      return "-";
    }
    const time = new Date(timeStamp);
    //年-月-日
    return `${time.getFullYear()}-${this.format(
      time.getMonth() + 1
    )}-${this.format(time.getDate())}`;
  }
  format(date: number | string) {
    date = date.toString();
    return ("0" + date).slice(-2);
  }
  editButton(val: any, row: any) {
    this.currentMenu = val;
    if (val == "oper") {
      this.addForm = row;
      this.addForm.operation_date = this.formatDate(row.operation_date);
      const temp = row.implantation_info;
      this.addNumber = temp.length - 1;
      temp.forEach((res: any, idx: number) => {
        if (idx == 0) {
          this.equipmentInfo.electrode1 = res.electrode;
          this.equipmentInfo.target1 = res.target;
          this.equipmentInfo.electrodetyoe1 = res.electrodetyoe;
        }
        if (idx == 1) {
          this.equipmentInfo.electrode2 = res.electrode;
          this.equipmentInfo.target2 = res.target;
          this.equipmentInfo.electrodetyoe2 = res.electrodetyoe;
        }
        if (idx == 2) {
          this.equipmentInfo.electrode3 = res.electrode;
          this.equipmentInfo.target3 = res.target;
          this.equipmentInfo.electrodetyoe3 = res.electrodetyoe;
        }
        if (idx == 3) {
          this.equipmentInfo.electrode4 = res.electrode;
          this.equipmentInfo.target4 = res.target;
          this.equipmentInfo.electrodetyoe4 = res.electrodetyoe;
        }
        if (idx == 4) {
          this.equipmentInfo.electrode5 = res.electrode;
          this.equipmentInfo.target5 = res.target;
          this.equipmentInfo.electrodetyoe5 = res.electrodetyoe;
        }
      });
      this.addShowDialog = true;
    } else if (val == "discussion") {
      this.addFormTotalData.discussion = {
        ...this.addFormTotalData.discussion,
        ...row,
      };
      this.addShowDialog2 = true;
    } else if (val == "auth") {
      this.addFormTotalData.auth = {
        ...this.addFormTotalData.auth,
        ...row,
      };
      this.addShowDialog3 = true;
    } else if (val == "mini") {
      this.addFormTotalData.mini = {
        ...this.addFormTotalData.mini,
        ...row,
      };
      this.addShowDialog4 = true;
    } else if (val == "temp") {
      this.addFormTotalData.temp = {
        ...this.addFormTotalData.temp,
        ...row,
      };
      this.addShowDialog5 = true;
    } else if (val == "image") {
      this.addFormTotalData.image = {
        ...this.addFormTotalData.image,
        ...row,
      };
      this.addShowDialog6 = true;
    } else if (val == "video") {
      this.addFormTotalData.video = {
        ...this.addFormTotalData.video,
        ...row,
      };
      this.addShowDialog7 = true;
    }
    this.getTotalData();
  }
  deleteButton(val: any, row: any) {
    if (val == "discussion") {
      this.deleteDiscussion(row.discussion_id);
    } else if (val == "auth") {
      this.deleteAuth(row.instruction_id);
    } else if (val == "mini") {
      this.deleteMini(row.microelec_id);
    } else if (val == "temp") {
      this.deleteTemp(row.record_id);
    } else if (val == "image") {
      this.deleteImage(row.image_id);
    } else if (val == "video") {
      this.deleteVideo(row.video_id);
    }
  }
  async deleteDiscussion(id: number) {
    this.$confirm("是否确认要删除?", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    }).then(async () => {
      const res = await ApiDiscussionDel({}, id);
      if (res.success) {
        this.$message.success("删除成功！");
        this.getTotalData();
      } else {
        this.$message.error(res.message);
      }
    });
  }
  async deleteAuth(id: number) {
    this.$confirm("是否确认要删除?", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    }).then(async () => {
      const res = await ApiAuthorizationDel({}, id);
      if (res.success) {
        this.$message.success("删除成功！");
        this.getTotalData();
      } else {
        this.$message.error(res.message);
      }
    });
  }
  async deleteMini(id: number) {
    this.$confirm("是否确认要删除?", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    }).then(async () => {
      const res = await ApiMicroelecDel({}, id);
      if (res.success) {
        this.$message.success("删除成功！");
        this.getTotalData();
      } else {
        this.$message.error(res.message);
      }
    });
  }
  async deleteTemp(id: number) {
    this.$confirm("是否确认要删除?", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    }).then(async () => {
      const res = await ApiStimDel({}, id);
      if (res.success) {
        this.$message.success("删除成功！");
        this.getTotalData();
      } else {
        this.$message.error(res.message);
      }
    });
  }
  async deleteImage(id: number) {
    this.$confirm("是否确认要删除?", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    }).then(async () => {
      const res = await ApiImageDel({}, id);
      if (res.success) {
        this.$message.success("删除成功！");
        this.getTotalData();
      } else {
        this.$message.error(res.message);
      }
    });
  }
  async deleteVideo(id: number) {
    this.$confirm("是否确认要删除?", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    }).then(async () => {
      const res = await ApiVideoDel({}, id);
      if (res.success) {
        this.$message.success("删除成功！");
        this.getTotalData();
      } else {
        this.$message.error(res.message);
      }
    });
  }
  dash(data: any) {
    if (data == null || data == "") {
      data = "-";
    }
    return data;
  }
  itemClick(idx: any, item: any) {
    this.menuItems.forEach((res, id) => {
      if (idx == id) {
        res.active = true;
      } else {
        res.active = false;
      }
    });
    (document.getElementById(item) as any).scrollIntoView(true);
  }
  // 上传成功的回调
  uploadSuccess() {
    return 1;
  }
  beforeAvatarUpload3(file: any) {
    const extension = this.getExtension(file.name);
    const isCan = file.size / 1024 / 1024 < 100;

    if ((extension == ".mp4" || extension == ".MP4") && isCan) {
      return true;
    } else {
      this.$message.warning("只能上传mp4格式文件，且小于100M。");
      return false;
    }
  }
  beforeAvatarUpload2(file: any) {
    const extension = this.getExtension(file.name);
    if (extension == ".jpg" || extension == ".jpeg" || extension == ".png") {
      return true;
    } else {
      this.$message.warning("只能上传jpg，jpeg，png格式文件。");
      return false;
    }
  }
  // 上传前校验
  beforeAvatarUpload(file: any) {
    const extension = this.getExtension(file.name);
    if (
      extension == ".jpg" ||
      extension == ".jpeg" ||
      extension == ".png" ||
      extension == ".pdf"
    ) {
      return true;
    } else {
      this.$message.warning("只能上传jpg，jpeg，png，pdf格式文件。");
      return false;
    }
  }
  // 上传附件
  async uploadHttpRequest(params: any) {
    const file = params.file;
    const fileName = file.name;
    console.log(this.currentMenu);
    if (this.currentMenu == "discussion") {
      this.addFormTotalData.discussion.resourcepath =
        await this.uploadAttachment(file);
    } else if (this.currentMenu == "auth") {
      this.addFormTotalData.auth.resourcepath = await this.uploadAttachment(file);
    } else if (this.currentMenu == "mini") {
      this.addFormTotalData.mini.file_path = await this.uploadAttachment(file);
    } else if (this.currentMenu == "temp") {
      this.addFormTotalData.temp.resourcepath = await this.uploadAttachment(file);
    } else if (this.currentMenu == "image") {
      this.addFormTotalData.image.img_resourcepath =
        await this.uploadAttachment(file);
    } else if (this.currentMenu == "video") {
      this.addFormTotalData.video.resourcepath = await this.uploadAttachment(file);
    }
  }

  // 上传
  async uploadAttachment(file: File) {
    //      upload_dir: "web_patient_information/" + this.currentMenu,
    const fileName = new Date().getTime().toString();
    const fileExt = file.name.split(".").pop();

    const res = await uploadFile(
      DirectoryEnum.WEB_PATIENT_INFORMATION,
      file,
      fileName + "." + fileExt,
      `/${this.currentMenu}`
    );
    return res;
  }

  //获取文件后缀名
  getExtension(name: string) {
    return name.substring(name.lastIndexOf("."));
  }

  againAddDepth() {
    if (!this.addFormTotalData.mini.deep) {
      this.$message.warning("请选择植入深度");
      return;
    }
    if (!this.addFormTotalData.mini.strong) {
      this.$message.warning("请填写信号强度");
      return;
    }
    if (!this.addFormTotalData.mini.implanteddepth) {
      this.addFormTotalData.mini.implanteddepth += `(${this.addFormTotalData.mini.deep},${this.addFormTotalData.mini.strong})`;
    } else {
      this.addFormTotalData.mini.implanteddepth += `,(${this.addFormTotalData.mini.deep},${this.addFormTotalData.mini.strong})`;
    }
    this.addFormTotalData.mini.strong = "";
    this.addFormTotalData.mini.deep = "";
  }

  addShowDialog2 = false;
  addShowDialog3 = false;
  addShowDialog4 = false;
  addShowDialog5 = false;
  addShowDialog6 = false;
  addShowDialog7 = false;

  addRecord(val: string) {
    this.currentMenu = val;
    console.log(this.currentMenu, "addRecord");
    if (val == "discussion") {
      this.addShowDialog2 = true;
      Object.keys(this.addFormTotalData.discussion).map((item: any) => {
        this.addFormTotalData.discussion[item] = "";
      });
      setTimeout(() => {
        (this.$refs.disscussForm as Form).clearValidate();
      }, 200);
    } else if (val == "auth") {
      this.addShowDialog3 = true;
      Object.keys(this.addFormTotalData.auth).map((item: any) => {
        this.addFormTotalData.auth[item] = "";
      });
      setTimeout(() => {
        (this.$refs.authForm as Form).clearValidate();
      }, 200);
    } else if (val == "mini") {
      this.addShowDialog4 = true;
      Object.keys(this.addFormTotalData.mini).map((item: any) => {
        this.addFormTotalData.mini[item] = "";
      });
      setTimeout(() => {
        (this.$refs.miniForm as Form).clearValidate();
      }, 200);
    } else if (val == "temp") {
      this.addShowDialog5 = true;
      Object.keys(this.addFormTotalData.temp).map((item: any) => {
        this.addFormTotalData.temp[item] = "";
      });
      setTimeout(() => {
        (this.$refs.tempForm as Form).clearValidate();
      }, 200);
    } else if (val == "image") {
      this.addShowDialog6 = true;
      Object.keys(this.addFormTotalData.image).map((item: any) => {
        this.addFormTotalData.image[item] = "";
      });
      setTimeout(() => {
        (this.$refs.imageForm as Form).clearValidate();
      }, 200);
    } else if (val == "video") {
      this.addShowDialog7 = true;
      Object.keys(this.addFormTotalData.video).map((item: any) => {
        this.addFormTotalData.video[item] = "";
      });
      setTimeout(() => {
        (this.$refs.videoForm as Form).clearValidate();
      }, 200);
    }
  }

  addOperationTotalHandle(val: string) {
    if (val == "oper") {
      this.addOperationRecord();
    } else if (val == "discuss") {
      this.discussValid();
    } else if (val == "video") {
      this.videoValid();
    } else if (val == "mini") {
      this.miniValid();
    } else if (val == "temp") {
      this.tempValid();
    } else if (val == "image") {
      this.imageValid();
    } else if (val == "auth") {
      this.authValid();
    }
  }

  discussValid() {
    (this.$refs.disscussForm as Form).validate(async (val: any) => {
      if (val) {
        this.discussSubmit();
      }
    });
  }
  async discussSubmit() {
    const params = {
      ...this.addFormTotalData.discussion,
      discussdate: this.addFormTotalData.discussion.discussdate,
      compere: this.addFormTotalData.discussion.compere,
      affiliatedperson: this.addFormTotalData.discussion.affiliatedperson,
      discusssubject: this.addFormTotalData.discussion.discusssubject,
      discussrecord: this.addFormTotalData.discussion.discussrecord,
      resourcepath: this.addFormTotalData.discussion.resourcepath,
      note: this.addFormTotalData.discussion.note,
      user_id: this.userId,
    };
    const res = await ApiDiscussionAdd(params);
    if (res.success) {
      this.$message.success("操作成功！");
      this.addShowDialog2 = false;
      this.getTotalData();
    } else {
      this.$message.error(res.message);
    }
  }
  tempValid() {
    (this.$refs.tempForm as Form).validate(async (val: any) => {
      if (val) {
        this.tempSubmit();
      }
    });
  }
  async tempSubmit() {
    const params = {
      ...this.addFormTotalData.temp,
      user_id: this.userId,
    };
    const res = await ApiStimAdd(params);
    if (res.success) {
      this.$message.success("操作成功！");
      this.addShowDialog5 = false;
      this.getTotalData();
    } else {
      this.$message.error(res.message);
    }
  }
  miniValid() {
    (this.$refs.miniForm as Form).validate(async (val: any) => {
      if (val) {
        this.miniSubmit();
      }
    });
  }
  async miniSubmit() {
    const params = {
      ...this.addFormTotalData.mini,
      user_id: this.userId,
    };
    const res = await ApiMicroelecAdd(params);
    if (res.success) {
      this.$message.success("操作成功！");
      this.addShowDialog4 = false;
      this.getTotalData();
    } else {
      this.$message.error(res.message);
    }
  }
  imageValid() {
    (this.$refs.imageForm as Form).validate(async (val: any) => {
      if (val) {
        this.imageSubmit();
      }
    });
  }
  async imageSubmit() {
    const params = {
      ...this.addFormTotalData.image,
      user_id: this.userId,
    };
    const res = await ApiImageAdd(params);
    if (res.success) {
      this.$message.success("操作成功！");
      this.addShowDialog6 = false;
      this.getTotalData();
    } else {
      this.$message.error(res.message);
    }
  }
  videoValid() {
    (this.$refs.videoForm as Form).validate(async (val: any) => {
      if (val) {
        this.videoSubmit();
      }
    });
  }
  async videoSubmit() {
    const params = {
      ...this.addFormTotalData.video,
      user_id: this.userId,
    };
    const res = await ApiVideoAdd(params);
    if (res.success) {
      this.$message.success("操作成功！");
      this.addShowDialog7 = false;
      this.getTotalData();
    } else {
      this.$message.error(res.message);
    }
  }
  authValid() {
    (this.$refs.authForm as Form).validate(async (val: any) => {
      if (val) {
        this.authSubmit();
      }
    });
  }
  async authSubmit() {
    const params = {
      ...this.addFormTotalData.auth,
      user_id: this.userId,
    };
    const res = await ApiAuthorizationAdd(params);
    if (res.success) {
      this.$message.success("操作成功！");
      this.addShowDialog3 = false;
      this.getTotalData();
    } else {
      this.$message.error(res.message);
    }
  }

  inputIdCardBlur(val: any) {
    // const reg =
    // /[^\w\/]/ig
    // if(!reg.test(val)) {
    //   this.$message.error("只能输入英文和数字。");
    //   this.addFormTotalData.auth.idcard = "";
    // }
  }
  handleKeysBlank(val: any) {
    Object.keys(val).map((item: any) => {
      val[item] = "";
    });
  }
  disabledHandle(val: any) {
    Object.keys(val).map((item: any) => {
      if (val[item]) {
        return false;
      }
    });
    return true;
  }

  table = {
    table1: true,
    table2: false,
    table3: false,
    table4: false,
    table5: false,
    table6: false,
    table7: false,
  } as any;

  clipStatusTable(val: any) {
    this.table[val] = !this.table[val];
  }
}
