// 过敏史
import {
  computed,
  reactive,
  watch,
  getCurrentInstance,
} from "@vue/composition-api";
import { ApiAllergyHistorySave } from "@/apis/index";
interface Module {
  irritabilityForm: object;
  test: () => void;
}
function irritabilityModuleFun(ins: any, MedicalHistoryDetailsFun: any) {
  const irritabilityModule: any = reactive({
    irritabilityForm: {
      allergies: "", // 过敏史
    } as any,
    irritabilityDetailsData: {
      //过敏史
      allergies: "", // 过敏史
    },
    irritabilityDrawer: false,

    //抽屉open事件
    irritabilityClick(irritabilityEditForm: any) {
      irritabilityModule.irritabilityForm = JSON.parse(
        JSON.stringify(irritabilityEditForm)
      );

      irritabilityModule.irritabilityDrawer = true;
    },
    irritabilityConfirmBtn() {
      const formRef = ins.$refs["irritabilityRuleForm"] as any;
      formRef.validate((valid: boolean) => {
        if (valid) {
          const param = {
            user_id: Number(ins.$route.query.user_id),
            ...ins.irritabilityForm,
          };
          ApiAllergyHistorySave(param).then((res) => {
            if (res.success) {
              //
              ins.$message.success("修改成功！");
              irritabilityModule.irritabilityDrawer = false;
              // //详情页面数据接口调用
              MedicalHistoryDetailsFun();
            }
          });
        } else {
          console.log("未通过");
        }
      });
    },
    irritabilityCanelBtn() {
      ins.confirmDialogVisible = true;
    },
  });
  return {
    irritabilityModule,
  };
}
export { irritabilityModuleFun };
