

















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { breadcrumb } from "@/utils/breadcrumb";
import { Base64 } from "js-base64";
import { DirectoryEnum } from "@/constants";
import { uploadFile } from "@/utils/alioss";
import {
  delMedicalRecordDetial,
  getMedicalRecordDetail,
  clinicAllData,
  cureExerAdd,
  cureAcupAdd,
  cureOtherAdd,
  cureMedicineAdd,
  clinicImageAdd,
  clinicMedicineList,
  clinicMedicineDel,
  clinicMedicineAdd,
  clinicChatAdd,
  clinicChaticereAdd,
  clinicChatielecAdd,
  clinicChatigeneAdd,
  clinicImageDel,
  clinicImageList,
  clinicChatinoAdd,
  clinicCureMedicineDel,
  clinicCureMedicineList,
  clinicCureOtherDel,
  clinicCureOtherList,
  clinicCureAcupDel,
  clinicCureAcupList,
  clinicCureExerDel,
  clinicCureExerList,
  editChiefComplaint,
} from "@/apis/index";
import { Form } from "element-ui";
import { RSAKey } from "jsencrypt/lib/lib/jsbn/rsa";
@Component({
  name: "InfoMedicalRecord",
})
export default class InfoMedicalRecord extends Vue {
  @Prop() medicalRecordHisId!: number;
  @Prop() archived!: boolean;
  get isCollapse() {
    return this.$store.getters.sidebar.opened;
  }
  upIcon = require("../../../../../assets/img/icon-up.png");
  downIcon = require("../../../../../assets/img/icon-down.png");

  //data
  condition: any = {
    user_name: "",
    date_start: "",
    date_end: "",
  };
  tableData: Array<any> = [];
  starLoading = false;
  loading = false;
  previousHistoryDrawer = false;
  confirmDialogVisible = false;
  tabIndex = 0;

  chiefComplaintRules1 = {
    trmtchief_type: [
      { required: true, message: "请选择就诊类型1", trigger: "blur" },
    ],
    trmtchief_type2: [
      { required: true, message: "请选择就诊类型2", trigger: "blur" },
    ],
    trmtchief_date: [
      { required: true, message: "请选择就诊时间", trigger: "blur" },
    ],
  };
  chiefComplaintRules = {};
  userTrmtchiefFrom = {
    trmtchief_type: "",
    trmtchief_type2: "",
    trmtchief_date: "",
    trmtchief_doctor: "",
    isClip: false,
  } as any;
  userTrmtchiefFrom2 = {
    trmtchief_zhusu: "",
    trmtchief_detail: "",
    trmtchief_yizhu: "",
    isClip: false,
  } as any;
  userTrmtchiefFrom3 = {
    trmtchief_note: "",
    isClip: false,
  } as any;
  basicInfoForm2 = {
    chatitg_blood01: "",
    chatitg_blood02: "",
    chatitg_blood02_1: "",
    chatitg_blood02_2: "",
    chatitg_blood00: "",
    chatitg_blood00_1: "",
    chatitg_blood00_2: "",
    chatitg_blood000: "",
    chatitg_blood03: "",
    chatitg_blood03_1: "",
    chatitg_blood03_2: "",
    chatitg_blood033: "",
    chatitg_blood04: "",
    chatitg_blood04_1: "",
    chatitg_blood04_2: "",
    chatitg_blood044: "",
    chatitg_blood05: "",
    chatitg_blood05_1: "",
    chatitg_blood05_2: "",
    chatitg_blood055: "",
    chatitg_blood07: "",
    chatitg_blood07_1: "",
    chatitg_blood07_2: "",
    chatitg_blood077: "",
    chatitg_blood09: "",
    chatitg_blood09_1: "",
    chatitg_blood09_2: "",
    chatitg_blood099: "",
    isClip: false,
  } as any;
  basicInfoForm3 = {
    chatitg_nerve01: "",
    chatitg_nerve02_desc: "",
    chatitg_nerve03_desc: "",
    chatitg_nerve04_desc: "",
    chatitg_nerve05_desc: "",
    chatitg_nerve06_desc: "",
    chatitg_nerve07_desc: "",
    chatitg_nerve08_desc: "",
    chatitg_nerve09_desc: "",
    chatitg_nerve10_desc: "",
    chatitg_nerve11_desc: "",
    chatitg_nerve12_desc: "",
    chatitg_nerve13_desc: "",
    chatitg_nerve14_desc: "",
    chatitg_nerve15_desc: "",
    chatitg_nerve16_desc: "",
    chatitg_nerve17_desc: "",
    chatitg_nerve18_desc: "",
    chatitg_nerve20_desc: "",
    chatitg_nerve21_desc: "",
    chatitg_nerve22_desc: "",
    chatitg_nerve23_desc: "",
    chatitg_nerve02: "",
    chatitg_nerve03: "",
    chatitg_nerve04: "",
    chatitg_nerve05: "",
    chatitg_nerve06: "",
    chatitg_nerve07: "",
    chatitg_nerve08: "",
    chatitg_nerve09: "",
    chatitg_nerve10: "",
    chatitg_nerve11: "",
    chatitg_nerve12: "",
    chatitg_nerve13: "",
    chatitg_nerve14: "",
    chatitg_nerve15: "",
    chatitg_nerve16: "",
    chatitg_nerve17: "",
    chatitg_nerve18: "",
    chatitg_nerve19: "",
    chatitg_nerve20: "",
    chatitg_nerve21: "",
    chatitg_nerve22: "",
    chatitg_nerve23: "",
    chatitg_note: "",
    isClip: false,
  } as any;
  basicInfoForm = {
    chatitg_date: "",
    chatitg_doctor: "",
    chatitg_hospital: "",
    chatitg_result: "",
    isClip: false,
  } as any;
  xindiantuForm = {
    chatielec_date: "",
    chatielec_hospital: "",
    chatielec_doctor: "",
    chatielec_conclusion: "",
    isClip: false,
  } as any;
  geneForm = {
    chatigene_date: "",
    chatigene_hospital: "",
    chatigene_doctor: "",
    chatigene_result: "",
    isClip: false,
  } as any;
  mediForm = {} as any;
  exerForm = {} as any;
  acuqForm = {} as any;
  otherForm = {} as any;

  basicInfoRules = {};
  checkType = [
    "MRI",
    "PET",
    "SPET",
    "黑质超声",
    "泌尿系超声&残余尿",
    "其他",
  ] as any;
  treeData = [
    {
      id: 0,
      label: "就诊信息",
      children: [],
    },
    {
      id: 1,
      label: this.textShow(this.userTrmtchiefFrom).split("：")[0],
      children: [],
    },
    {
      id: 2,
      label: "备注",
      children: [],
    },
    {
      id: 3,
      label: "当时用药情况",
      children: [],
    },
    {
      id: 4,
      label: "查体-体格检查",
      children: [
        {
          id: 41,
          label: "基础检查",
        },
        {
          id: 42,
          label: "卧立位血压检查",
        },
        {
          id: 43,
          label: "神经系统检查",
        },
      ],
    },
    {
      id: 5,
      label: "辅助检查",
      children: [
        {
          id: 51,
          label: "实验室检查",
        },
        {
          id: 52,
          label: "影像检查",
        },
        {
          id: 53,
          label: "脑脊液检查",
        },
        {
          id: 54,
          label: "心电图检查",
        },
        {
          id: 55,
          label: "基因检查",
        },
      ],
    },
    {
      id: 6,
      label: "治疗措施",
      children: [],
    },
  ];

  totalMedicalData = {
    chatitg: {
      chatitg_date: "",
      isClip: false,
    },
    chatino: {
      chatinor_alt01: "",
      chatinor_alt02: "",
      chatinor_ast01: "",
      chatinor_ast02: "",
      chatinor_bloodsugar: "",
      chatinor_blooduricacid01: "",
      chatinor_blooduricacid02: "",
      chatinor_copper: "",
      chatinor_copper01: "",
      chatinor_copper02: "",
      chatinor_creatinine01: "",
      chatinor_creatinine02: "",
      chatinor_date: "",
      chatinor_fasting01: "",
      chatinor_fasting02: "",
      chatinor_hld01: "",
      chatinor_hld02: "",
      chatinor_hospital: "",
      chatinor_iron: "",
      chatinor_iron01: "",
      chatinor_iron02: "",
      chatinor_ldl01: "",
      chatinor_ldl02: "",
      chatinor_lipid: "",
      chatinor_liver: "",
      chatinor_note: "",
      chatinor_other: "",
      chatinor_red01: "",
      chatinor_red02: "",
      chatinor_renal: "",
      chatinor_resource: "",
      chatinor_result: "",
      chatinor_t301: "",
      chatinor_t302: "",
      chatinor_t401: "",
      chatinor_t402: "",
      chatinor_tc01: "",
      chatinor_tc02: "",
      chatinor_th01: "",
      chatinor_th02: "",
      chatinor_thyroid: "",
      chatinor_tsh01: "",
      chatinor_tsh02: "",
      chatinor_urea01: "",
      chatinor_urea02: "",
      chatinor_uricacid: "",
      chatinor_white01: "",
      chatinor_white02: "",
      isClip: false,
    },
    chatielec: {
      chatielec_date: "",
      chatielec_hospital: "",
      chatielec_doctor: "",
      chatielec_conclusion: "",
      isClip: false,
    },
    chatigene: {
      chatigene_date: "",
      chatigene_hospital: "",
      chatigene_doctor: "",
      chatigene_result: "",
      isClip: false,
    },
  } as any;

  defaultProps = {
    children: "children",
    label: "label",
  };

  // 一般用药史;
  pharmacyForm = {} as any;
  imageForm = {
    chatiimg_conclusion: "",
    chatiimg_date: "",
    chatiimg_doctor: "",
    chatiimg_hospital: "",
    chatiimg_note: "",
    chatiimg_resource: "",
    chatiimg_result: "",
    chatiimg_type: "",
  } as any;
  naojiye = {
    chaticere_result: "",
    chaticere_doctor: "",
    chaticere_hospital: "",
    chaticere_pressure: null,
    chaticere_pressure01: "",
    chaticere_pressure02: "",
    chaticere_blood: null,
    chaticere_cell01: "",
    chaticere_cell02: "",
    chaticere_white01: "",
    chaticere_white02: "",
    chaticere_biochemical: null,
    chaticere_glucose01: "",
    chaticere_glucose02: "",
    chaticere_chloride01: "",
    chaticere_chloride02: "",
    chaticere_protein01: "",
    chaticere_protein02: "",
    chaticere_other: "",
    chaticere_note: "",
  } as any;
  //药名
  drugName = [
    "多巴丝肼",
    "普拉克索",
    "吡贝地尔",
    "雷沙吉兰",
    "司来吉兰",
    "沙芬酰胺",
    "金刚烷胺",
    "盐酸苯海索",
    "溴隐亭",
    "卡左双多巴",
    "恩他卡朋",
    "托卡朋",
    "奥匹卡朋",
    "罗替高汀",
    "罗匹尼罗",
    "斯达力沃/达灵复",
    "麦角乙脲",
    "培高利特",
    "氟桂利嗪",
    "桂利嗪",
    "利血平",
    "复方降压0号",
    "黛力新",
    "氟哌啶醇",
    "维思通",
    "奋乃静",
    "舒必利",
    "安律凡",
    "胺碘酮",
    "氟西汀",
    "帕罗西汀",
    "文拉法辛",
    "舍曲林",
    "奥氮平",
    "喹硫平",
    "其它",
  ];
  drugName2 = [
    "美多巴",
    "息宁",
    "安坦",
    "金刚烷胺",
    "泰舒达",
    "司来吉兰",
    "普拉克索(森福罗)",
    "司吉宁",
    "珂丹",
    "辅酶Q10",
    "银杏叶",
    "左旋多巴",
    "雷沙吉兰",
    "罗匹尼罗",
    "罗替高汀贴片",
    "其它",
  ];

  adverseReaction = [
    "无",
    "恶心",
    "呕吐",
    "头晕",
    "幻觉",
    "智力下降",
    "运动并发症",
    "其它",
  ];
  stopDrug = ["无", "不良反应", "药效不佳", "费用", "药物撤市", "其它"];

  //服药说明
  specification = [
    "餐前半小时",
    "餐前一小时",
    "餐后半小时",
    "餐后俩小时",
    "其它",
  ];

  shiyanshi = {
    chatinor_alt01: "",
    chatinor_alt02: "",
    chatinor_ast01: "",
    chatinor_ast02: "",
    chatinor_bloodsugar: "",
    chatinor_blooduricacid01: "",
    chatinor_blooduricacid02: "",
    chatinor_copper: "",
    chatinor_copper01: "",
    chatinor_copper02: "",
    chatinor_creatinine01: "",
    chatinor_creatinine02: "",
    chatinor_date: "",
    chatinor_fasting01: "",
    chatinor_fasting02: "",
    chatinor_hld01: "",
    chatinor_hld02: "",
    chatinor_hospital: "",
    chatinor_iron: "",
    chatinor_iron01: "",
    chatinor_iron02: "",
    chatinor_ldl01: "",
    chatinor_ldl02: "",
    chatinor_lipid: "",
    chatinor_liver: "",
    chatinor_note: "",
    chatinor_other: "",
    chatinor_red01: "",
    chatinor_red02: "",
    chatinor_renal: "",
    chatinor_resource: "",
    chatinor_result: "",
    chatinor_t301: "",
    chatinor_t302: "",
    chatinor_t401: "",
    chatinor_t402: "",
    chatinor_tc01: "",
    chatinor_tc02: "",
    chatinor_th01: "",
    chatinor_th02: "",
    chatinor_thyroid: "",
    chatinor_tsh01: "",
    chatinor_tsh02: "",
    chatinor_urea01: "",
    chatinor_urea02: "",
    chatinor_uricacid: "",
    chatinor_white01: "",
    chatinor_white02: "",
    isClip: false,
  } as any;

  treeHandleNodeClick(data: any) {
    if (data.id == 4 || data.id == 5) return;
    (document.getElementById(data.id) as any).scrollIntoView(true);
  }
  backCaseRecord() {
    this.$emit("backCaseRecord");
  }

  openPreviousDrawer() {
    //
  }
  init() {
    this.getMedicalRecordDetailList();
    this.getDataBaseAllData();
    if ((document as any).querySelector(".footer_buttons")) {
      if (!this.isCollapse) {
        (document as any)
          .querySelector(".footer_buttons")
          .classList.remove("collapsed");
      } else {
        (document as any)
          .querySelector(".footer_buttons")
          .classList.add("collapsed");
      }
    }
  }
  //hook
  mounted() {
    this.init();
  }
  //获取病历详情
  async getMedicalRecordDetailList() {
    const res = await getMedicalRecordDetail(this.medicalRecordHisId);
    if (res.success) {
      this.userTrmtchiefFrom = {
        trmtchief_type: res.data.user_trmtchief.trmtchief_type,
        trmtchief_type2: res.data.user_trmtchief.trmtchief_type2,
        trmtchief_date: res.data.user_trmtchief.trmtchief_date,
        trmtchief_doctor: res.data.user_trmtchief.trmtchief_doctor,
        isClip: false,
      } as any;
      this.userTrmtchiefFrom2 = {
        trmtchief_zhusu: res.data.user_trmtchief.trmtchief_zhusu,
        trmtchief_detail: res.data.user_trmtchief.trmtchief_detail,
        trmtchief_yizhu: res.data.user_trmtchief.trmtchief_yizhu,
        isClip: true,
      } as any;
      this.userTrmtchiefFrom3 = {
        trmtchief_note: res.data.user_trmtchief.trmtchief_note,
        resource_path: res.data.user_trmtchief.resource_path,
        isClip: false,
      } as any;

      if (res.data.user_trmtchief.resource_path) {
        this.PicAndVideoList = JSON.parse(
          res.data.user_trmtchief.resource_path
        );
        this.enclosures = [
          ...JSON.parse(res.data.user_trmtchief.resource_path),
        ];
        this.videolist = [...this.enclosures];
      }
      this.treeData[1].label = this.textShow(this.userTrmtchiefFrom).split(
        "："
      )[0];

      this.isHaveVal(this.userTrmtchiefFrom);
      this.isHaveVal(this.userTrmtchiefFrom2);
      this.isHaveVal(this.userTrmtchiefFrom3);
    }
  }
  isHaveVal(val: any) {
    Object.keys(val).map((detail: any) => {
      if (detail == "resource_path" && val[detail] == "[]") {
        return;
      } else {
        if (
          val[detail] &&
          detail != "isClip" &&
          detail != "createdate" &&
          detail != "createuserid" &&
          detail != "trmtchief_id" &&
          detail != "updatedate" &&
          detail != "updateuserid" &&
          detail != "user_id"
        ) {
          val.isClip = true;
          return;
        }
      }
    });
  }
  isHaveVal2(val: any) {
    if (
      Object.keys(val).some((detail) => {
        return (
          val[detail] &&
          ![
            "isClip",
            "createdate",
            "createuserid",
            "chatinor_id",
            "trmtchief_id",
            "updatedate",
            "updateuserid",
            "user_id",
            "chaticere_id",
            "chatinor_id",
            "chatigene_id",
            "chatielec_id",
          ].includes(detail)
        );
      })
    ) {
      this.shiyanshi.isClip = true;
    }
    // Object.keys(val).map((detail: any) => {
    //   if (val[detail] && detail != "isClip"  && detail != "createdate" && detail != "createuserid" && detail != "chatinor_id"&& detail != "trmtchief_id" && detail != "updatedate" && detail != "updateuserid" && detail != "user_id" && detail != "chaticere_id" && detail != "chatinor_id"&&detail != "chatigene_id"&&detail != "chatielec_id") {
    //     this.shiyanshi.isClip = true;
    //     return;
    //   }
    // })
  }
  isHaveVal3(val: any) {
    if (
      Object.keys(val).some((detail) => {
        return (
          val[detail] &&
          val[detail] != "-" &&
          ![
            "isClip",
            "createdate",
            "createuserid",
            "chatitg_id",
            "trmtchief_id",
            "updatedate",
            "updateuserid",
            "user_id",
          ].includes(detail)
        );
      })
    ) {
      this.basicInfoForm.isClip = true;
    }
  }
  async getMedicineList() {
    const res = await clinicMedicineList(this.medicalRecordHisId, {});
    if (res.success) {
      this.totalMedicalData.medicine_list = res.data;
      if (res.data.length) {
        this.table1 = true;
      }
    }
  }
  async getClinicImageList() {
    const res = await clinicImageList(this.medicalRecordHisId, {});
    if (res.success) {
      this.totalMedicalData.image_list = res.data;
      if (res.data.length) {
        this.shiyanshi.isClip = true;
      }
    }
  }
  table2 = false;
  async getClinicCureOtherList() {
    const res = await clinicCureOtherList(this.medicalRecordHisId, {});
    if (res.success) {
      this.totalMedicalData.cure_other_list = res.data;
      if (res.data.length) {
        this.table2 = true;
      }
    }
  }

  async getClinicCureExerList() {
    const res = await clinicCureExerList(this.medicalRecordHisId, {});
    if (res.success) {
      this.totalMedicalData.cure_exer_list = res.data;
      if (res.data.length) {
        this.table2 = true;
      }
    }
  }
  async getClinicCureAcupList() {
    const res = await clinicCureAcupList(this.medicalRecordHisId, {});
    if (res.success) {
      this.totalMedicalData.cure_acup_list = res.data;
      if (res.data.length) {
        this.table2 = true;
      }
    }
  }
  async getClinicCureMedicineList() {
    const res = await clinicCureMedicineList(this.medicalRecordHisId, {});
    if (res.success) {
      this.totalMedicalData.cure_medicine_list = res.data;
      if (res.data.length) {
        this.table2 = true;
      }
    }
  }
  async getDataBaseAllData() {
    const res = await clinicAllData(this.medicalRecordHisId);
    if (res.success) {
      if (!res.data.chaticere) {
        res.data.chaticere = this.naojiye;
      }
      if (!res.data.chatino) {
        res.data.chatino = {
          ...this.shiyanshi,
        };
      }
      if (!res.data.chatigene) {
        res.data.chatigene = this.geneForm;
      }
      if (!res.data.chatielec) {
        res.data.chatielec = this.xindiantuForm;
      }
      if (!res.data.chatitg) {
        res.data.chatitg = {
          ...this.basicInfoForm,
          ...this.basicInfoForm2,
          ...this.basicInfoForm3,
        };
      }
      this.totalMedicalData = res.data;
      this.isHaveVal3(res.data.chatitg);
      this.isHaveVal2(res.data.chaticere);
      this.isHaveVal2(res.data.chatino);
      this.isHaveVal2(res.data.chatigene);
      this.isHaveVal2(res.data.chatielec);

      if (this.totalMedicalData.medicine_list.length) {
        this.table1 = true;
      }
      if (this.totalMedicalData.image_list.length) {
        this.totalMedicalData.chatino.isClip = true;
      }
      if (
        this.totalMedicalData.cure_other_list.length ||
        this.totalMedicalData.cure_exer_list.length ||
        this.totalMedicalData.cure_acup_list.length ||
        this.totalMedicalData.cure_medicine_list.length
      ) {
        this.table2 = true;
      }
    } else {
      this.$message.error(res.message);
    }
  }
  textShow(item: any) {
    if (item.trmtchief_type2 == "首诊" || item.trmtchief_type2 == "其他") {
      return "主诉：";
    } else {
      return "病情变化：";
    }
  }
  textShow2(item: any) {
    if (item.trmtchief_type2 == "首诊" || item.trmtchief_type2 == "其他") {
      return "医嘱：";
    } else {
      return "目前主要解决的问题：";
    }
  }

  formatDate(timeStamp: number | string | null) {
    if (!timeStamp) {
      return "-";
    }
    const time = new Date(Number(timeStamp));
    //年-月-日
    return `${time.getFullYear()}-${this.format(
      time.getMonth() + 1
    )}-${this.format(time.getDate())}`;
  }
  format(date: number | string) {
    date = date.toString();
    return ("0" + date).slice(-2);
  }
  dash(data: any) {
    let result = "-";
    if (data || data === 0) {
      result = data;
    }
    return result;
  }
  dashNo(data: any) {
    let result = "-";
    if (data || data === 0) {
      result = data;
    } else {
      return "-";
    }
    if (data.indexOf("-") > -1) {
      return result.split("-")[0];
    }
  }
  currentMenu = "";
  editButton(val: any, row: any) {
    this.currentMenu = val;
    if (val == "medicine") {
      this.pharmacyForm = {
        ...this.pharmacyForm,
        ...row,
      };
      this.dialogShow1 = true;
    } else if (val == "other") {
      this.otherForm = {
        ...this.otherForm,
        ...row,
      };
      this.dialogShow11 = true;
    } else if (val == "exer") {
      this.exerForm = {
        ...this.exerForm,
        ...row,
      };
      this.dialogShow9 = true;
    } else if (val == "acup") {
      this.acuqForm = {
        ...this.acuqForm,
        ...row,
      };
      this.dialogShow10 = true;
    } else if (val == "image") {
      this.imageForm = {
        ...this.imageForm,
        ...row,
      };
      this.dialogShow4 = true;
    } else if (val == "medi") {
      this.mediForm = {
        ...this.mediForm,
        ...row,
      };
      this.dialogShow8 = true;
    }
    this.getDataBaseAllData();
  }
  deleteButton(val: any, row: any) {
    if (val == "medicine") {
      this.deleteMedicine(row.id);
    } else if (val == "other") {
      this.deleteOther(row.id);
    } else if (val == "medi") {
      this.deleteMedi(row.measuremed_id);
    } else if (val == "exer") {
      this.deleteExer(row.measurexer_id);
    } else if (val == "image") {
      this.deleteImage(row.chatiimg_id);
    } else if (val == "acup") {
      this.deleteAcuq(row.measureacup_id);
    }
  }
  async deleteMedicine(id: number) {
    this.$confirm("是否确认要删除?", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    }).then(async () => {
      const res = await clinicMedicineDel(id, {});
      if (res.success) {
        this.$message.success("删除成功！");
        this.getMedicineList();
      } else {
        this.$message.error(res.message);
      }
    });
  }
  async deleteImage(id: number) {
    this.$confirm("是否确认要删除?", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    }).then(async () => {
      const res = await clinicImageDel(id, {});
      if (res.success) {
        this.$message.success("删除成功！");
        this.getClinicImageList();
      } else {
        this.$message.error(res.message);
      }
    });
  }
  async deleteOther(id: number) {
    this.$confirm("是否确认要删除?", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    }).then(async () => {
      const res = await clinicCureOtherDel(id, {});
      if (res.success) {
        this.$message.success("删除成功！");
        this.getClinicCureOtherList();
      } else {
        this.$message.error(res.message);
      }
    });
  }
  async deleteExer(id: number) {
    this.$confirm("是否确认要删除?", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    }).then(async () => {
      const res = await clinicCureExerDel(id, {});
      if (res.success) {
        this.$message.success("删除成功！");
        this.getClinicCureExerList();
      } else {
        this.$message.error(res.message);
      }
    });
  }
  async deleteAcuq(id: number) {
    this.$confirm("是否确认要删除?", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    }).then(async () => {
      const res = await clinicCureAcupDel(id, {});
      if (res.success) {
        this.$message.success("删除成功！");
        this.getClinicCureAcupList();
      } else {
        this.$message.error(res.message);
      }
    });
  }
  async deleteMedi(id: number) {
    this.$confirm("是否确认要删除?", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    }).then(async () => {
      const res = await clinicCureMedicineDel(id, {});
      if (res.success) {
        this.$message.success("删除成功！");
        this.getClinicCureMedicineList();
      } else {
        this.$message.error(res.message);
      }
    });
  }

  getDate() {
    const time = new Date();
    return `${time.getFullYear()}${this.format(
      time.getMonth() + 1
    )}${this.format(time.getDate())}`;
  }
  dialogShow1 = false;
  dialogShow1_1 = false;
  dialogShow1_2 = false;
  dialogShow1_3 = false;
  dialogShow2 = false;
  dialogShow21 = false;
  dialogShow22 = false;
  dialogShow3 = false;
  dialogShow4 = false;
  dialogShow5 = false;
  dialogShow6 = false;
  dialogShow7 = false;
  dialogShow8 = false;
  dialogShow9 = false;
  dialogShow10 = false;
  dialogShow11 = false;
  handleSplit(val: any, id: number) {
    if (val && val.indexOf("-") > -1) {
      return val.split("-")[id];
    } else if (val && val.indexOf("-") == -1 && id == 0) {
      return val;
    } else {
      return "";
    }
  }
  addFormBtnClick(val: string) {
    this.currentMenu = val;
    if (val == "medicine") {
      this.dialogShow1 = true;
      setTimeout(() => {
        (this.$refs.pharmacyRuleForm as Form).clearValidate();
      }, 200);
    } else if (val == "med1") {
      this.dialogShow1_1 = true;
    } else if (val == "med2") {
      this.dialogShow1_2 = true;
    } else if (val == "med3") {
      this.dialogShow1_3 = true;
    } else if (val == "basic") {
      this.dialogShow2 = true;
      this.basicInfoForm = {
        chatitg_date: this.totalMedicalData.chatitg.chatitg_date,
        chatitg_doctor: this.totalMedicalData.chatitg.chatitg_doctor,
        chatitg_hospital: this.totalMedicalData.chatitg.chatitg_hospital,
        chatitg_result: this.totalMedicalData.chatitg.chatitg_result,
        isClip: this.basicInfoForm.isClip,
      };
    } else if (val == "basic2") {
      this.dialogShow21 = true;
      this.basicInfoForm2 = {
        chatitg_result: this.totalMedicalData.chatitg.chatitg_result,
        chatitg_blood01: this.totalMedicalData.chatitg.chatitg_blood01,
        chatitg_blood000: this.totalMedicalData.chatitg.chatitg_blood000,
        chatitg_blood022: this.totalMedicalData.chatitg.chatitg_blood022,
        chatitg_blood033: this.totalMedicalData.chatitg.chatitg_blood033,
        chatitg_blood044: this.totalMedicalData.chatitg.chatitg_blood044,
        chatitg_blood055: this.totalMedicalData.chatitg.chatitg_blood055,
        chatitg_blood077: this.totalMedicalData.chatitg.chatitg_blood077,
        chatitg_blood099: this.totalMedicalData.chatitg.chatitg_blood099,
        chatitg_blood02_1: this.handleSplit(
          this.totalMedicalData.chatitg.chatitg_blood02,
          0
        ),
        chatitg_blood02_2: this.handleSplit(
          this.totalMedicalData.chatitg.chatitg_blood02,
          1
        ),
        chatitg_blood03_1: this.handleSplit(
          this.totalMedicalData.chatitg.chatitg_blood03,
          0
        ),
        chatitg_blood03_2: this.handleSplit(
          this.totalMedicalData.chatitg.chatitg_blood03,
          1
        ),
        chatitg_blood04_1: this.handleSplit(
          this.totalMedicalData.chatitg.chatitg_blood04,
          0
        ),
        chatitg_blood04_2: this.handleSplit(
          this.totalMedicalData.chatitg.chatitg_blood04,
          1
        ),
        chatitg_blood00_1: this.handleSplit(
          this.totalMedicalData.chatitg.chatitg_blood00,
          0
        ),
        chatitg_blood00_2: this.handleSplit(
          this.totalMedicalData.chatitg.chatitg_blood00,
          1
        ),
        chatitg_blood05_1: this.handleSplit(
          this.totalMedicalData.chatitg.chatitg_blood05,
          0
        ),
        chatitg_blood05_2: this.handleSplit(
          this.totalMedicalData.chatitg.chatitg_blood05,
          1
        ),
        chatitg_blood07_1: this.handleSplit(
          this.totalMedicalData.chatitg.chatitg_blood07,
          0
        ),
        chatitg_blood07_2: this.handleSplit(
          this.totalMedicalData.chatitg.chatitg_blood07,
          1
        ),
        chatitg_blood09_1: this.handleSplit(
          this.totalMedicalData.chatitg.chatitg_blood09,
          0
        ),
        chatitg_blood09_2: this.handleSplit(
          this.totalMedicalData.chatitg.chatitg_blood09,
          1
        ),
      };
    } else if (val == "basic3") {
      const temps = JSON.parse(JSON.stringify(this.totalMedicalData.chatitg));
      const temps3 = JSON.parse(JSON.stringify(this.basicInfoForm3));

      this.dialogShow22 = true;
      for (let i = 2; i < 10; i++) {
        temps3["chatitg_nerve0" + i] = this.handleSplit(
          temps["chatitg_nerve0" + i],
          0
        );
        temps3["chatitg_nerve0" + i + "_desc"] = this.handleSplit(
          temps["chatitg_nerve0" + i],
          1
        );
      }
      for (let i = 10; i <= 23; i++) {
        temps3["chatitg_nerve" + i] = this.handleSplit(
          temps["chatitg_nerve" + i],
          0
        );

        temps3["chatitg_nerve" + i + "_desc"] = this.handleSplit(
          temps["chatitg_nerve" + i],
          1
        );
      }
      temps3["chatitg_nerve01"] = temps.chatitg_nerve01;
      temps3["chatitg_nerve19"] = temps.chatitg_nerve19;
      temps3["chatitg_note"] = temps.chatitg_note;

      this.basicInfoForm3 = {
        ...temps3,
      };
      console.log(this.basicInfoForm3);
    } else if (val == "shiyanshi") {
      this.dialogShow3 = true;
      this.totalMedicalData.chatino.isClip = this.shiyanshi.isClip;
      this.shiyanshi = JSON.parse(
        JSON.stringify(this.totalMedicalData.chatino)
      );
    } else if (val == "image") {
      this.dialogShow4 = true;
    } else if (val == "naojiye") {
      this.dialogShow5 = true;
      this.naojiye = JSON.parse(
        JSON.stringify(this.totalMedicalData.chaticere)
      );
    } else if (val == "xindiantu") {
      this.dialogShow6 = true;
      this.xindiantuForm = JSON.parse(
        JSON.stringify(this.totalMedicalData.chatielec)
      );
    } else if (val == "gene") {
      this.dialogShow7 = true;
      this.geneForm = JSON.parse(
        JSON.stringify(this.totalMedicalData.chatigene)
      );
    } else if (val == "medi") {
      this.dialogShow8 = true;
    } else if (val == "exer") {
      this.dialogShow9 = true;
    } else if (val == "acuq") {
      this.dialogShow10 = true;
    } else if (val == "other") {
      this.dialogShow11 = true;
    }
  }
  addFormFunTotal(val: string) {
    if (val == "medicine") {
      this.medicineValid();
    } else if (val == "basic") {
      this.basicInfoSubmit(1);
    } else if (val == "basic2") {
      this.totalMedicalData.chatitg.chatitg_blood00 =
        this.basicInfoForm2.chatitg_blood00_1 +
        "-" +
        this.basicInfoForm.chatitg_blood00_2;
      this.totalMedicalData.chatitg.chatitg_blood02 =
        this.basicInfoForm2.chatitg_blood02_1 +
        "-" +
        this.basicInfoForm.chatitg_blood02_2;
      this.totalMedicalData.chatitg.chatitg_blood03 =
        this.basicInfoForm2.chatitg_blood03_1 +
        "-" +
        this.basicInfoForm.chatitg_blood03_2;
      this.totalMedicalData.chatitg.chatitg_blood04 =
        this.basicInfoForm2.chatitg_blood04_1 +
        "-" +
        this.basicInfoForm.chatitg_blood04_2;

      this.totalMedicalData.chatitg.chatitg_blood05 =
        this.basicInfoForm2.chatitg_blood05_1 +
        "-" +
        this.basicInfoForm.chatitg_blood05_2;
      this.totalMedicalData.chatitg.chatitg_blood07 =
        this.basicInfoForm2.chatitg_blood07_1 +
        "-" +
        this.basicInfoForm.chatitg_blood07_2;
      this.totalMedicalData.chatitg.chatitg_blood09 =
        this.basicInfoForm2.chatitg_blood09_1 +
        "-" +
        this.basicInfoForm.chatitg_blood09_2;
      this.basicInfoSubmit(2);
    } else if (val == "basic3") {
      for (let i = 2; i < 10; i++) {
        this.totalMedicalData.chatitg["chatitg_nerve0" + i] =
          this.basicInfoForm3["chatitg_nerve0" + i] +
          "-" +
          this.basicInfoForm3["chatitg_nerve0" + i + "_desc"];
      }
      for (let i = 10; i < 18; i++) {
        this.totalMedicalData.chatitg["chatitg_nerve" + i] =
          this.basicInfoForm3["chatitg_nerve" + i] +
          "-" +
          this.basicInfoForm3["chatitg_nerve" + i + "_desc"];
      }
      for (let i = 20; i < 23; i++) {
        this.totalMedicalData.chatitg["chatitg_nerve" + i] =
          this.basicInfoForm3["chatitg_nerve" + i] +
          "-" +
          this.basicInfoForm3["chatitg_nerve" + i + "_desc"];
      }
      this.basicInfoSubmit(3);
    } else if (val == "shiyanshi") {
      this.shiyanshiSubmit();
    } else if (val == "naojiye") {
      this.naojiyeSubmit();
    } else if (val == "xindiantu") {
      this.xindiantuSubmit();
    } else if (val == "gene") {
      this.geneSubmit();
    } else if (val == "acup") {
      this.acupSubmit();
    } else if (val == "exer") {
      this.exerSubmit();
    } else if (val == "other") {
      this.otherSubmit();
    } else if (val == "medi") {
      this.mediSubmit();
    } else if (val == "image") {
      this.imageSubmit();
    } else if (val == "med1") {
      this.basicMedValid(1);
    } else if (val == "med2") {
      this.basicMedValid(2);
    } else if (val == "med3") {
      this.basicMedValid(3);
    }
  }
  medicineValid() {
    (this.$refs.pharmacyRuleForm as Form).validate(async (val: any) => {
      if (val) {
        this.medicineSubmit();
      }
    });
  }
  basicMedValid(id: number) {
    if (id == 1) {
      (this.$refs.userTrmtchiefFrom as Form).validate(async (val: any) => {
        if (val) {
          this.basicMedSubmit(id);
        }
      });
    } else {
      this.basicMedSubmit(id);
    }
  }
  async medicineSubmit() {
    const params = {
      ...this.pharmacyForm,
      trmtchief_id: this.medicalRecordHisId,
    };
    const res = await clinicMedicineAdd(params);
    if (res.success) {
      this.$message.success("操作成功！");
      this.dialogShow1 = false;
      this.getMedicineList();
    } else {
      this.$message.error(res.message);
    }
  }
  async shiyanshiSubmit() {
    const params = {
      ...this.shiyanshi,
      trmtchief_id: this.medicalRecordHisId,
      user_id: this.userId,
    };
    const res = await clinicChatinoAdd(params);
    if (res.success) {
      this.$message.success("操作成功！");
      this.dialogShow3 = false;

      this.totalMedicalData.chatino = res.data;
      this.isHaveVal2(this.totalMedicalData.chatino);

      Object.keys(this.shiyanshi).map((item: any) => {
        if (item != "isClip") {
          this.shiyanshi[item] = null;
        }
      });
    } else {
      this.$message.error(res.message);
    }
  }
  async xindiantuSubmit() {
    const params = {
      ...this.xindiantuForm,
      trmtchief_id: this.medicalRecordHisId,
      user_id: this.userId,
    };
    const res = await clinicChatielecAdd(params);
    if (res.success) {
      this.$message.success("操作成功！");
      this.dialogShow6 = false;
      this.isHaveVal2(this.totalMedicalData.chatino);
      this.totalMedicalData.chatielec = res.data;
      Object.keys(this.xindiantuForm).map((item: any) => {
        this.xindiantuForm[item] = "";
      });
    } else {
      this.$message.error(res.message);
    }
  }
  async geneSubmit() {
    const params = {
      ...this.geneForm,
      trmtchief_id: this.medicalRecordHisId,
      user_id: this.userId,
    };
    const res = await clinicChatigeneAdd(params);
    if (res.success) {
      this.$message.success("操作成功！");
      this.dialogShow7 = false;
      this.isHaveVal2(this.totalMedicalData.chatino);
      this.totalMedicalData.chatigene = res.data;
      Object.keys(this.geneForm).map((item: any) => {
        this.geneForm[item] = "";
      });
    } else {
      this.$message.error(res.message);
    }
  }
  async acupSubmit() {
    const params = {
      ...this.acuqForm,
      trmtchief_id: this.medicalRecordHisId,
      user_id: this.userId,
    };
    const res = await cureAcupAdd(params);
    if (res.success) {
      this.$message.success("操作成功！");
      this.dialogShow10 = false;
      this.getClinicCureAcupList();
      Object.keys(this.acuqForm).map((item: any) => {
        this.acuqForm[item] = "";
      });
    } else {
      this.$message.error(res.message);
    }
  }
  async exerSubmit() {
    const params = {
      ...this.exerForm,
      trmtchief_id: this.medicalRecordHisId,
      user_id: this.userId,
    };
    const res = await cureExerAdd(params);
    if (res.success) {
      this.$message.success("操作成功！");
      this.dialogShow9 = false;
      this.totalMedicalData.cure_exer_list = res.data;
      this.getClinicCureExerList();
      Object.keys(this.exerForm).map((item: any) => {
        this.exerForm[item] = "";
      });
    } else {
      this.$message.error(res.message);
    }
  }
  async otherSubmit() {
    const params = {
      ...this.otherForm,
      trmtchief_id: this.medicalRecordHisId,
      user_id: this.userId,
    };
    const res = await cureOtherAdd(params);
    if (res.success) {
      this.$message.success("操作成功！");
      this.dialogShow11 = false;
      this.getClinicCureOtherList();
      Object.keys(this.otherForm).map((item: any) => {
        this.otherForm[item] = "";
      });
    } else {
      this.$message.error(res.message);
    }
  }
  async mediSubmit() {
    const params = {
      ...this.mediForm,
      trmtchief_id: this.medicalRecordHisId,
      user_id: this.userId,
    };
    const res = await cureMedicineAdd(params);
    if (res.success) {
      this.$message.success("操作成功！");
      this.dialogShow8 = false;
      this.getClinicCureMedicineList();
      Object.keys(this.mediForm).map((item: any) => {
        this.mediForm[item] = "";
      });
    } else {
      this.$message.error(res.message);
    }
  }
  async naojiyeSubmit() {
    const params = {
      ...this.naojiye,
      trmtchief_id: this.medicalRecordHisId,
      user_id: this.userId,
    };
    const res = await clinicChaticereAdd(params);
    if (res.success) {
      this.$message.success("操作成功！");
      this.dialogShow5 = false;
      res.data.isClip = this.shiyanshi.isClip;
      this.totalMedicalData.chaticere = res.data;

      console.log(this.totalMedicalData.chaticere, "---");

      Object.keys(this.naojiye).map((item: any) => {
        this.naojiye[item] = "";
      });
    } else {
      this.$message.error(res.message);
    }
  }
  async imageSubmit() {
    const params = {
      ...this.imageForm,
      trmtchief_id: this.medicalRecordHisId,
      user_id: this.userId,
    };
    const res = await clinicImageAdd(params);
    if (res.success) {
      this.$message.success("操作成功！");
      this.dialogShow4 = false;
      // this.totalMedicalData.image_list = res.data;
      this.getClinicImageList();
      Object.keys(this.imageForm).map((item: any) => {
        this.imageForm[item] = "";
      });
    } else {
      this.$message.error(res.message);
    }
  }

  userId = "";

  async created() {
    this.userId = this.$route.query.user_id as string;
  }

  async basicInfoSubmit(id: number) {
    let params = {};
    if (id == 1) {
      params = {
        user_id: this.userId,
        trmtchief_id: this.medicalRecordHisId,
        ...this.totalMedicalData.chatitg,
        ...this.basicInfoForm,
      };
    } else if (id == 2) {
      params = {
        user_id: this.userId,
        trmtchief_id: this.medicalRecordHisId,
        ...this.totalMedicalData.chatitg,
        chatitg_blood02:
          this.basicInfoForm2.chatitg_blood02_1 +
          "-" +
          this.basicInfoForm2.chatitg_blood02_2,
        chatitg_blood03:
          this.basicInfoForm2.chatitg_blood03_1 +
          "-" +
          this.basicInfoForm2.chatitg_blood03_2,
        chatitg_blood04:
          this.basicInfoForm2.chatitg_blood04_1 +
          "-" +
          this.basicInfoForm2.chatitg_blood04_2,
        chatitg_blood00:
          this.basicInfoForm2.chatitg_blood00_1 +
          "-" +
          this.basicInfoForm2.chatitg_blood00_2,
        chatitg_blood05:
          this.basicInfoForm2.chatitg_blood05_1 +
          "-" +
          this.basicInfoForm2.chatitg_blood05_2,
        chatitg_blood07:
          this.basicInfoForm2.chatitg_blood07_1 +
          "-" +
          this.basicInfoForm2.chatitg_blood07_2,
        chatitg_blood09:
          this.basicInfoForm2.chatitg_blood09_1 +
          "-" +
          this.basicInfoForm2.chatitg_blood09_2,
        ...this.basicInfoForm2,
      };
    } else if (id == 3) {
      const newObj = {} as any;
      for (let i = 2; i < 10; i++) {
        newObj["chatitg_nerve0" + i] = this.basicInfoForm3[
          "chatitg_nerve0" + i + "_desc"
        ]
          ? this.basicInfoForm3["chatitg_nerve0" + i] +
            "-" +
            this.basicInfoForm3["chatitg_nerve0" + i + "_desc"]
          : this.basicInfoForm3["chatitg_nerve0" + i];
      }
      for (let i = 10; i < 19; i++) {
        newObj["chatitg_nerve" + i] = this.basicInfoForm3[
          "chatitg_nerve" + i + "_desc"
        ]
          ? this.basicInfoForm3["chatitg_nerve" + i] +
            "-" +
            this.basicInfoForm3["chatitg_nerve" + i + "_desc"]
          : this.basicInfoForm3["chatitg_nerve" + i];
      }
      for (let i = 20; i <= 23; i++) {
        newObj["chatitg_nerve" + i] = this.basicInfoForm3[
          "chatitg_nerve" + i + "_desc"
        ]
          ? this.basicInfoForm3["chatitg_nerve" + i] +
            "-" +
            this.basicInfoForm3["chatitg_nerve" + i + "_desc"]
          : this.basicInfoForm3["chatitg_nerve" + i];
      }
      params = {
        user_id: this.userId,
        trmtchief_id: this.medicalRecordHisId,
        ...this.totalMedicalData.chatitg,
        ...this.basicInfoForm,
        ...this.basicInfoForm2,
        ...this.basicInfoForm3,
        ...newObj,
        chatitg_nerve01: this.basicInfoForm3.chatitg_nerve01,
        chatitg_nerve19: this.basicInfoForm3.chatitg_nerve19,
        chatitg_note: this.basicInfoForm3.chatitg_note,
        chatitg_id: this.totalMedicalData.chatitg.chatitg_id,
      };
      console.log(params, "===");
    }

    const res = await clinicChatAdd(params);
    if (res.success) {
      this.$message.success("操作成功！");
      res.data.isClip = false;
      this.totalMedicalData.chatitg = res.data;
      this.dialogShow2 = false;
      this.dialogShow22 = false;
      this.dialogShow21 = false;
    } else {
      this.$message.error(res.message);
    }
  }

  async basicMedSubmit(id: number) {
    let params = {};
    if (id == 1) {
      params = {
        user_id: this.userId,
        trmtchief_id: this.medicalRecordHisId,
        trmtchief_date: this.userTrmtchiefFrom.trmtchief_date,
        trmtchief_doctor: this.userTrmtchiefFrom.trmtchief_doctor,
        trmtchief_type: this.userTrmtchiefFrom.trmtchief_type,
        trmtchief_type2: this.userTrmtchiefFrom.trmtchief_type2,
      };
    } else if (id == 2) {
      params = {
        user_id: this.userId,
        trmtchief_id: this.medicalRecordHisId,
        trmtchief_zhusu: this.userTrmtchiefFrom2.trmtchief_zhusu,
        trmtchief_detail: this.userTrmtchiefFrom2.trmtchief_detail,
        trmtchief_yizhu: this.userTrmtchiefFrom2.trmtchief_yizhu,
      };
    } else if (id == 3) {
      params = {
        user_id: this.userId,
        trmtchief_id: this.medicalRecordHisId,
        trmtchief_note: this.userTrmtchiefFrom3.trmtchief_note,
        resource_path: JSON.stringify(this.enclosures),
      };
    }
    const res = await editChiefComplaint(params);
    if (res.success) {
      this.$message.success("操作成功！");
      this.getMedicalRecordDetailList();
      this.cancelInfo();
    } else {
      this.$message.error(res.message);
    }
  }

  medicineAdd() {
    //
  }

  cancelInfo() {
    this.dialogShow1 = false;
    this.dialogShow1_1 = false;
    this.dialogShow1_2 = false;
    this.dialogShow1_3 = false;
    this.dialogShow2 = false;
    this.dialogShow21 = false;
    this.dialogShow22 = false;
    this.dialogShow3 = false;
    this.dialogShow4 = false;
    this.dialogShow5 = false;
    this.dialogShow6 = false;
    this.dialogShow7 = false;
    this.dialogShow8 = false;
    this.dialogShow9 = false;
    this.dialogShow10 = false;
    this.dialogShow11 = false;
    this.getMedicalRecordDetailList();
    this.reset(this.imageForm);
    this.reset(this.exerForm);
    this.reset(this.acuqForm);
    this.reset(this.otherForm);
    this.reset(this.mediForm);
    this.reset(this.pharmacyForm);

    this.xindiantuForm = JSON.parse(
      JSON.stringify(this.totalMedicalData.chatielec)
    );
    this.naojiye = JSON.parse(JSON.stringify(this.totalMedicalData.chaticere));
    this.shiyanshi = JSON.parse(JSON.stringify(this.totalMedicalData.chatino));

    this.totalMedicalData.chatitg.isClip = false;
    this.basicInfoForm = JSON.parse(
      JSON.stringify(this.totalMedicalData.chatitg)
    );
    this.isHaveVal3(this.basicInfoForm);
    this.geneForm = this.totalMedicalData.chatigene;
    this.basicInfoForm2 = JSON.parse(
      JSON.stringify(this.totalMedicalData.chatitg)
    );
    this.basicInfoForm3 = JSON.parse(
      JSON.stringify(this.totalMedicalData.chatitg)
    );
  }

  reset(val: any) {
    Object.keys(val).map((item: any) => {
      val[item] = "";
    });
  }
  radioChangeNerve(val: any, item: any) {
    const temp = "chatitg_nerve" + item + "_desc";
    if (val !== "异常") {
      return (this.basicInfoForm3[temp] = "");
    }
  }

  // 上传前校验
  beforeAvatarUpload(file: any) {
    console.log(this.currentMenu);
    const extension = this.getExtension(file.name);
    if (extension == ".jpg" || extension == ".jpeg" || extension == ".png") {
      return true;
    } else {
      this.$message.warning("只支持jpg，png和jpeg格式文件！");
      return false;
    }
  }
  fileLists = [] as any;
  // 上传附件
  async uploadHttpRequest(params: any) {
    const file = params.file;
    const fileName = file.name;
    if (this.currentMenu == "image") {
      this.imageForm.chatiimg_resource = await this.uploadAttachment(
        "chatiimg_resource",
        file,
        DirectoryEnum.WEB_PATIENT_INFORMATION
      );
    } else if (this.currentMenu == "med3") {
      this.userTrmtchiefFrom3.resource_path = await this.uploadAttachment(
        "userTrmtchief_resource_path",
        file,
        DirectoryEnum.WEB_PATIENT_INFORMATION
      );
      // this.fileLists.push({
      //   url: temp,
      //   name: fileName
      // })
      // this.userTrmtchiefFrom.resource_path.push(temp);

      // console.log(this.fileLists,"===",this.userTrmtchiefFrom.resource_path);
    }
  }

  // 上传
  //      upload_dir: "web_patient_information/" + budgetName,

  async uploadAttachment(
    budgetName: string,
    file: File,
    directory: DirectoryEnum
  ) {
    const fileName = new Date().getTime().toString();
    const fileExt = file.name.split(".").pop();
    const extPath = budgetName == "" ? "" : `/${budgetName}`;
    const res = await uploadFile(
      directory,
      file,
      fileName + "." + fileExt,
      extPath
    );
    // if (!this.loading) {
    //   this.enclosures.push({
    //     url: res,
    //   });
    // }
    return res;
  }

  //获取文件后缀名
  getExtension(name: string) {
    return name.substring(name.lastIndexOf("."));
  }

  // 上传成功的回调
  uploadSuccess() {
    return 1;
  }
  videoUploadPercent = 0;
  videoFlag = false;
  videolist = [] as any;
  enclosures = [] as any;
  PicAndVideoList = [] as any;
  handleExceed(files: any, fileList: any) {
    if (files > 10) {
      this.$message.warning(`当前限制选择 10 个文件`);
    }
  }

  // 进度条钩子
  uploadVideoProcess(file: any, fileList: any) {
    if (file.status === "success") {
      this.videoFlag = true; //进度条显示
      const interval = setInterval(() => {
        if (this.videoUploadPercent >= 100) {
          clearInterval(interval);
          return;
        }
        this.videoUploadPercent += 1; //进度条进度
      }, 20);
    }
  }

  // 判断是视频么
  isViodeOrPic(url: any) {
    if (url) {
      const res = url.split(".");
      const video = res.some((item: any) => {
        return item == "mp4";
      });
      const videos = res.some((item: any) => {
        return item == "mov";
      });
      if (video) {
        return "视频";
      } else if (videos) {
        return "视频";
      } else {
        return "照片";
      }
    }
  }
  //在线预览视频
  videoClick(url: any) {
    window.open(
      `https://pins-gateway.pinsdev.com/kkfileview/onlinePreview?url=${encodeURIComponent(
        Base64.encode(url)
      )}`
    );
  }
  //在线图片
  imgClick(url: any) {
    window.open(
      `https://pins-gateway.pinsdev.com/kkfileview/onlinePreview?url=${encodeURIComponent(
        Base64.encode(url)
      )}`
    );
  }

  // 上传之后看是不是视频
  isVideo(file: any) {
    const isVideo = file.type == "video/mp4";
    if (isVideo) {
      return true;
    } else {
      return false;
    }
  }
  // 上传图片前模块
  beforeAvatarUpload2(file: any) {
    const isJpg = file.type == "image/jpg";
    const isPng = file.type == "image/png";
    const isJpeg = file.type == "image/jpeg";
    const isMp4 = file.type == "video/mp4";
    const isCan = file.size / 1024 / 1024 < 100;
    if (isJpg || isMp4 || isPng || isJpeg) {
      if (isCan) {
        return new Promise((resolve, reject) => {
          const isCan = file.size / 1024 / 1024 < 100;
          const isVideo = file.type == "video/mp4";
          const isJPG = file.type === "image/jpeg";
          const isPNG = file.type === "image/png";
          const fileSize = file.size / 1024; //换成kb
          let bool = false;
          if (isCan) {
            if (isJPG || isPNG || isVideo) {
              //判断是否符合格式要求
              bool = true;
            } else {
              return Promise.reject;
            }
            if (bool) {
              if (isVideo) {
                return resolve(file);
              } else {
                //如果格式符合要求，但是size过大，对图片进行处理
                const image = new Image();
                let resultBlob: any = "";
                image.src = URL.createObjectURL(file);
                image.onload = () => {
                  resultBlob = this.compressUpload(image, fileSize, 200); //Blob
                  resolve(resultBlob);
                };
              }
            } else {
              return Promise.reject;
            }
          } else {
            this.$message.warning("超出文件大小！");
            return false;
          }
        });
      } else {
        this.$message.warning("超出文件大小");
        return false;
      }
    } else {
      this.$message.warning("只支持jpg，png，jpeg和mp4格式文件！");
      return false;
    }
  }

  // 压缩图片
  compressUpload(image: any, fileSize: number, ratio: number): any {
    const canvas = document.createElement("canvas"); //创建画布元素
    const ctx = canvas.getContext("2d");
    const width = image.width;
    const height = image.height;
    canvas.width = width;
    canvas.height = height;
    let compressRatio: any;
    if (fileSize < 200) {
      compressRatio = 1;
    } else {
      compressRatio = ratio / fileSize;
    }

    if (ctx === null) {
      this.$message.error("画布创建失败");
    } else {
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(image, 0, 0, width, height);
      const compressData = canvas.toDataURL("image/jpeg", compressRatio); //等比压缩
      const blobImg = this.dataURItoBlob(compressData); //base64转Blob
      if (blobImg.size > 200 * 1024 && compressRatio < 1) {
        return this.compressUpload(image, fileSize, ratio - 30);
      }
      return blobImg;
    }
  }

  dataURItoBlob(data: any) {
    let byteString;
    if (data.split(",")[0].indexOf("base64") >= 0) {
      byteString = atob(data.split(",")[1]); //转二进制
    } else {
      byteString = unescape(data.split(",")[1]);
    }
    const mimeString = data.split(",")[0].split(":")[1].split(";")[0];
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i += 1) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], { type: mimeString });
  }

  // 上传附件--图片-----问题分析模块
  async uploadHttpRequestPicture(params: any) {
    const file = params.file;
    const fileName = file.name;
    const path = await this.uploadAttachment("", file, DirectoryEnum.PICTRUE);
    if (path) {
      setTimeout(() => {
        this.enclosures.push({
          url: path,
        });
      }, 3000);
    }
  }

  // 上传 给上传图片和文件的函数使用
  deletePic(data: any) {
    this.$confirm("是否删图片/视频?", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
      .then(() => {
        this.$message({
          type: "success",
          message: "删除成功!",
        });
        this.enclosures = this.enclosures.filter((item: any) => {
          return item != data;
        });
      })
      .catch((err: any) => {
        return err;
      });
  }

  delMedicalRecordClick(id: any) {
    this.$confirm("删除后录入的数据不可恢复，确认删除吗？", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    }).then(async () => {
      const res = await delMedicalRecordDetial(this.medicalRecordHisId);
      if (res.success) {
        this.$message.success("删除成功！");
        this.$emit("backCaseRecord");
      } else {
        this.$message.error(res.message);
      }
    });
  }

  clipStatus(item: any) {
    if (item.isClip === undefined || item.isClip === null) {
      item.isClip = false;
    }
    item.isClip = !item.isClip;
  }
  table1 = false;
  clipStatusTable() {
    this.table1 = !this.table1;
  }
  clipStatusTable2() {
    this.table2 = !this.table2;
  }
}
