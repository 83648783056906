































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import {
  computed,
  defineComponent,
  getCurrentInstance,
  onMounted,
  onUnmounted,
  reactive,
  ref,
  toRef,
  toRefs,
  watch,
} from "@vue/composition-api";
//逻辑模块
import { scrollModuleFun } from "@/views/EleMediRecord/Scale/ts/medicalHistory/scrollpsp";
import { mainSymptomsFun } from "@/views/EleMediRecord/Scale/ts/medicalHistory/mainSymptoms";
import { symptomDiagnosisModuleFu } from "@/views/EleMediRecord/Scale/ts/medicalHistory/SymptomDiagnosiss";
import { previousHistoryModuleFun } from "@/views/EleMediRecord/Scale/ts/medicalHistory/previousHistory";
import { environmentModuleFun } from "@/views/EleMediRecord/Scale/ts/medicalHistory/environment";
import { livingHabitModuleFun } from "@/views/EleMediRecord/Scale/ts/medicalHistory/livingHabit";
import { irritabilityModuleFun } from "@/views/EleMediRecord/Scale/ts/medicalHistory/irritability";
import { familyFun } from "@/views/EleMediRecord/Scale/ts/medicalHistory/family";
import { pharmacyFun } from "@/views/EleMediRecord/Scale/ts/medicalHistory/pharmacy";
import { exerciseModuleFun } from "@/views/EleMediRecord/Scale/ts/medicalHistory/exercise";
import { otherTreatmentModuleFun } from "@/views/EleMediRecord/Scale/ts/medicalHistory/otherTreatment";
import { eidemiologicalHistoryModuleFun } from "@/views/EleMediRecord/Scale/ts/medicalHistory/eidemiologicalHistory";

//接口
import {
  ApiPastMedicalHistoryDetails2,
  delMedicalHistory,
  ApiGetProvince,
  ApiGetCity,
  ApiGetArea,
} from "@/apis/index";
export default defineComponent({
  setup() {
    const detailsDataModule: any = reactive({
      hisdisease_name: [
        "高血压",
        "糖尿病",
        "心脏病",
        "甲亢",
        "麻疹",
        "脑卒中",
        "脑外伤",
        "脑炎",
        "癫痫",
        "一氧化碳(煤气)中毒",
        "其它",
      ],
      occupationList: ["工人", "教师", "农民", "干部", "学生", "职员", "其它"],

      migrationList: ["一次", "两次", "多于两次"],

      countryList: ["中国", "澳大利亚", "新加坡"],

      currentAddressProps: {
        lazy: true,
        lazyLoad(node: any, resolve: any) {
          let result = [];
          let param = {};
          //获取当前层级
          const { level } = node;
          if (level == 0) {
            ApiGetProvince(param).then((res) => {
              if (res.success) {
                // code: "34"
                // first_letter: "A"
                // name: "安徽省"
                result = res.data.map((item: any) => {
                  return {
                    value: item.code,
                    label: item.name,
                  };
                });
                resolve(result);
              }
            });
          } else if (level == 1) {
            param = {
              province_code: node.value,
            };
            ApiGetCity(param).then((res) => {
              if (res.success) {
                // code: "3408"
                // first_letter: "A"
                // name: "安庆市"
                // province_code: "34"
                result = res.data.map((item: any) => {
                  return {
                    value: item.code,
                    label: item.name,
                  };
                });
                resolve(result);
              }
            });
          } else if (level == 2) {
            param = {
              city_code: node.value,
            };
            ApiGetArea(param).then((res) => {
              if (res.success) {
                // city_code: "3408"
                // code: "340871"
                // first_letter: "A"
                // name: "安徽安庆经济开发区"
                // province_code: "34"
                result = res.data.map((item: any) => {
                  return {
                    value: item.code,
                    label: item.name,
                    leaf: level >= 2,
                  };
                });
                resolve(result);
              }
            });
          }
        },
      },

      options: ["未知", "痊愈", "好转", "未变", "有后遗症", "恶化"],
      exerciseList: ["骑车", "跑步", "打太极", "登山", "跳舞", "散步", "其它"],
      teaTypeList: ["红茶", "绿茶", "白茶", "黑茶", "其它"],
      hisfam_relation: ["父母", "子女", "夫妻", "其它"],
      hisfam_diagnosis: [
        "帕金森病",
        "帕金森综合征",
        "步态障碍",
        "肌张力障碍",
        "痉挛状态",
        "肌阵挛",
        "震颤",
        "舞蹈症",
        "投掷",
        "手足徐动",
        "抽动障碍",
        "偏侧面肌痉挛",
        "共济失调(行走不稳)",
        "发作性运动障碍",
        "夜间运动障碍",
        "混合型运动障碍",
        "其它",
      ],
      detailsDataModule: {
        trmtprst_id: 3493, // 现病史id
        user_id: 2, // 患者病历id
        trmtprst_indate: 1651023389000, // 起病情况--起病时间
        trmtprst_inage: 15, // 起病情况--起病年龄
        trmtprst_fstsymp: "首发症状", // 起病情况--首发症状
        trmtprst_fstposition: "1", // 起病情况--首发部位
        trmtprst_issymmetry: "是否", // 起病情况--是否对称起病
        trmtprst_symmetrydate: 1651023389000, // 起病情况--对称起病时间
        trmtprst_note: "备注1", // 起病情况--备注
        lu_limb_date: 1651022467534, // 进展顺序--左上肢时间
        ld_limb_date: 1651022467534, // 进展顺序--左下肢时间
        ru_limb_date: 1651022467534, // 进展顺序--右上肢时间
        rd_limb_date: 1651022467534, // 进展顺序--右下肢时间
        tremor: "震颤", // 主要症状--震颤
        tremor_type: "类型", // 主要症状--震颤--类型
        tremor_item: 1651022795322, // 主要症状--震颤--出现时间
        tremor_items: 1651022795322, // 主要症状--震颤--进展双侧时间
        tonic: "强直", // 主要症状--强直
        tonic_type: "类型", // 主要症状--强直--类型
        tonic_item: 1651022795322, // 主要症状--强直--出现时间
        slow_action: "运动迟缓", // 主要症状--运动迟缓
        slow_action_type: "类型", // 主要症状--运动迟缓--类型
        slow_action_item: 1651022795322, // 主要症状--运动迟缓--出现时间
        slow_action_items: 1651022795322, // 主要症状--运动迟缓--进展双侧时间
        abnormal_gait: "态异常", // 主要症状--步态异常
        abnormal_gait_type: "型", // 主要症状--步态异常--类型
        abnormal_gait_item: 1651022795322, // 主要症状--步态异常--出现时间
        panic_gait: "步态", // 其它运动症状--慌张步态
        panic_gait_item: 1651023164213, // 其它运动症状--慌张步态--出现时间
        lowercase: "写症", // 其他运动症状--小写症
        lowercase_item: 1651023164213, // 其他运动症状--小写症--出现时间
        difficulty_turning: "身困难", // 其他运动症状--翻身困难
        difficulty_turning_item: 1651023164213, // 其他运动症状--翻身困难--出现时间
        fall: "姿势不稳", // 其他运动症状--跌倒/姿势不稳
        fall_item: 1651023164213, // 其他运动症状--跌倒/姿势不稳-出现时间
        language_disability: "碍", // 其他运动症状--语言障碍
        language_disability_item: 1651023164213, // 其他运动症状--语言障碍--出现时间
        hard_to_swallow: "咽困难", // 其他运动症状--吞咽困难
        hard_to_swallow_item: 1651022795322, // 其他运动症状--吞咽困难--出现时间
        depress: "",
      },
      // 症状诊断
      symptomDiagnosisData: {
        data: [
          {
            chatidiag_id: "", // 症状诊断id
            user_id: "", // 患者病历id
            chatidiag_type: "", // 诊断
            chatidiag_second: "", // 二级诊断
            chatidiag_third: "", // 三级诊断
            chatidiag_date: 21651891372000, // 诊断日期
            chatidiag_doctor: "", // 诊断医生
            chatidiag_isclear: "", // 诊断是否明确
            chatidiag_sign1: "", // 标记：震颤
            chatidiag_sign2: "", // 标记：强直
            chatidiag_sign3: "", // 标记：认知
            chatidiag_sign4: "", // 标记：疗效减退
            chatidiag_sign5: "", // 标记：运动并发症
            chatidiag_sign6: "", // 标记：DBS术后
            chatidiag_sign7: "", // 标记：病程
            chatidiag_note: "", // 备注
          },
        ],
      },
      // 现病史
      medicalRecordDetailList: {
        user_id: "", // 患者病历id
        trmtprst_indate: null, // 起病情况--起病时间
        trmtprst_inage: null, // 起病情况--起病年龄
        trmtprst_fstsymp: "", // 起病情况--首发症状
        trmtprst_fstposition: "", // 起病情况--首发部位
        trmtprst_issymmetry: "", // 起病情况--是否对称起病
        trmtprst_symmetrydate: 1651023389000, // 起病情况--对称起病时间
        trmtprst_note: "", // 起病情况--备注
        lu_limb_date: "", // 进展顺序--左上肢时间
        ld_limb_date: "", // 进展顺序--左下肢时间
        ru_limb_date: "", // 进展顺序--右上肢时间
        rd_limb_date: "", // 进展顺序--右下肢时间
        tremor: "", // 主要症状--震颤
        tremor_type: "", // 主要症状--震颤--类型
        tremor_item: "", // 主要症状--震颤--出现时间
        tremor_items: "", // 主要症状--震颤--进展双侧时间
        tonic: "", // 主要症状--强直
        tonic_type: "", // 主要症状--强直--类型
        tonic_item: "", // 主要症状--强直--出现时间
        slow_action: "", // 主要症状--运动迟缓
        slow_action_type: "", // 主要症状--运动迟缓--类型
        slow_action_item: "", // 主要症状--运动迟缓--出现时间
        slow_action_items: "", // 主要症状--运动迟缓--进展双侧时间
        abnormal_gait: "", // 主要症状--步态异常
        abnormal_gait_type: "", // 主要症状--步态异常--类型
        abnormal_gait_item: "", // 主要症状--步态异常--出现时间
        panic_gait: "", // 其它运动症状--慌张步态
        panic_gait_item: "", // 其它运动症状--慌张步态--出现时间
        lowercase: "", // 其他运动症状--小写症
        lowercase_item: "", // 其他运动症状--小写症--出现时间
        difficulty_turning: "", // 其他运动症状--翻身困难
        difficulty_turning_item: "", // 其他运动症状--翻身困难--出现时间
        fall: "", // 其他运动症状--跌倒/姿势不稳
        fall_item: "", // 其他运动症状--跌倒/姿势不稳-出现时间
        language_disability: "", // 其他运动症状--语言障碍
        language_disability_item: "", // 其他运动症状--语言障碍--出现时间
        hard_to_swallow: "", // 其他运动症状--吞咽困难
        hard_to_swallow_item: "", // 其他运动症状--吞咽困难--出现时间
      },
      // 症状诊断
      symptomDiagnosisDetailData: {
        data: [
          {
            chatidiag_type: "", // 诊断
            chatidiag_second: "", // 二级诊断
            chatidiag_third: "", // 三级诊断
            chatidiag_date: "", // 诊断日期
            chatidiag_doctor: "", // 诊断医生
            chatidiag_isclear: "", // 诊断是否明确
            chatidiag_sign1: "", // 标记：震颤
            chatidiag_sign2: "", // 标记：强直
            chatidiag_sign3: "", // 标记：认知
            chatidiag_sign4: "", // 标记：疗效减退
            chatidiag_sign5: "", // 标记：运动并发症
            chatidiag_sign6: "", // 标记：DBS术后
            chatidiag_sign7: "", // 标记：病程
            chatidiag_note: "", // 备注
          },
        ],
      },
      // 既往史详情数据
      previousHistoryDetailsData: {
        data: [
          {
            hisdisease_name: "", //疾病名称
            hisdisease_year: "", //发病时间
            hisdisease_month: "", //发病时间
            hisdisease_day: "", //发病时间
            hisdisease_outcome: "", //转归
            hisdisease_pharmacy: "", //用药
            hisdisease_note: "", //备注
          },
        ],
      },
      //既往环境史
      environmentDetails: {
        hisenviro_occupation: "",
        hisenviro_occuyears: "",
        hisenviro_occu01years: "",
        hisenviro_occu03: "",
        hisenviro_occu01: "",
        hisenviro_occu05: "",
        hisenviro_occu04: "",
        hisenviro_occu02: "",
        hisenviro_occu06: "",
        hisenviro_country: "中国",
      },
      //生活习惯详情数据
      HabitDetailsData: {
        //吸烟
        hishabit_issmoke: "",
        hishabit_smoke04: "",
        hishabit_smoke01: "",
        hishabit_smoke05: "",
        hishabit_smoke02: "",
        hishabit_smoke07: "",
        hishabit_smoke08: "",
        //饮酒
        hishabit_isdrunk: "", // 是否饮酒
        hishabit_drunk01: "", // 平均每日饮酒量
        hishabit_drunk02: "", // 开始饮酒时间-年
        hishabit_drunk03: "", // 开始饮酒时间-月
        hishabit_drunk04: "", // 饮酒年限
        hishabit_drunk05: "", // 戒酒时间-年
        hishabit_drunk06: "", // 戒酒时间-月
        hishabit_drunk07: "", // 戒酒年限
        //锻炼
        hishabit_isexercise: "", // 是否锻炼
        hishabit_exercise01: "", // 锻炼频度
        hishabit_exercise02: "", // 锻炼方式
        hishabit_exercise03: "", // 每天锻炼时长
        //喝茶
        hishabit_istea: "", //		是否喝茶
        hishabit_tea01: "", //	频度
        hishabit_tea02: "", //浓度
        hishabit_tea03: "", //		开始喝茶年龄
        hishabit_tea04: "", //		停止喝茶年龄
        hishabit_tea05: "", //		喝茶种类
        //咖啡
        hishabit_iscoffee: "", // 是否喝咖啡
        hishabit_coffee01: "", // 咖啡--频度
        hishabit_coffee02: "", // 咖啡--浓度
        hishabit_coffee03: "", // 开始喝咖啡年龄
        hishabit_coffee04: "", // 停止喝咖啡年龄
      },
      //过敏史
      irritabilityDetailsData: {
        allergies: "", // 过敏史
      },
      //家族史
      familyDetailsData: {
        data: [
          {
            //家族史
            hisfam_id: "", // 家族史id

            hisfam_relation: "", // 与患者关系
            hisfam_indate: "", // 发病年龄
            hisfam_name: "", // 家属姓名
            hisfam_diagnosis: "", // 疾病诊断
            hisfam_issimilar: "", // 症状是否类似
            hisfam_isclear: "", // 诊断是否明确
            hisfam_isalive: "", // 家属是否健在
            hisfam_tele: "", // 家属联系方式
            hisfam_hospital: "", // 诊断医院
            hisfam_other: "", // 其它情况说明
          },
        ],
      },
      //用药史
      pharmacyDetailsData: {
        data: [
          {
            //一般用药史
            hismed_id: "", // 一般用药史id
            hismed_type: "", // 分类
            hismed_description: "", //服药说明
            hismed_name: "", // 药物名称
            hismed_iseffect: "", // 是否有效
            hismed_indate: "", // 开始服药时间
            hismed_isgoon: "", // 是否继续服用
            hismed_dose: "", // 日均总剂量
            hismed_offdate: "", // 停药时间
            hismed_isadverse: "", // 有无不良反应
            hismed_adversecnt: "", // 不良反应内容
            hismed_freq: "", // 服药方案
            hismed_offreason: "", // 停药原因
            hismed_note: "", // 备注
          },
        ],
      },

      addMedicineData: {
        data: [
          {
            //一般用药史
            hismed_id: "", // 一般用药史id
            hismed_type: "", // 分类
            hismed_description: "", //服药说明
            hismed_name: "", // 药物名称
            hismed_iseffect: "", // 是否有效
            hismed_indate: "", // 开始服药时间
            hismed_isgoon: "", // 是否继续服用
            hismed_dose: "", // 日均总剂量
            hismed_offdate: "", // 停药时间
            hismed_isadverse: "", // 有无不良反应
            hismed_adversecnt: "", // 不良反应内容
            hismed_freq: "", // 服药方案
            hismed_offreason: "", // 停药原因
            hismed_note: "", // 备注
          },
        ],
      },

      //并发运动症
      exerciseDetailsData: {
        //并发运动症
        hiscomp_yidong01: "", // 异动症--出现时间-年份
        hiscomp_yidong02: "", // 异动症--出现时间-月份
        hiscomp_yidong03: "", // 异动症--出现症状时服药方案
        hiscomp_yidong04: "", // 异动症--出现症状时等效美多芭剂量
        hiscomp_yidong05: "", // 异动症-与服药的关系
        hiscomp_jimo01: "", // 剂末现象--出现时间-年份
        hiscomp_jimo02: "", // 剂末现象--出现时间-月份
        hiscomp_jimo03: "", // 剂末现象--出现症状时服药方案
        hiscomp_jimo04: "", // 剂末现象--出现症状时等效美多芭剂量
        hiscomp_tongx01: "", // 痛性痉挛--出现时间-年份
        hiscomp_tongx02: "", // 痛性痉挛--出现时间-月份
        hiscomp_tongx03: "", // 痛性痉挛--出现症状时服药方案
        hiscomp_tongx04: "", // 痛性痉挛--出现症状时等效美多芭剂量
        hiscomp_chenj01: "", // 晨僵--出现时间-年份
        hiscomp_chenj02: "", // 晨僵--出现时间-月份
        hiscomp_chenj03: "", // 晨僵--出现症状时服药方案
        hiscomp_chenj04: "", // 晨僵--出现症状时等效美多芭剂量
        hiscomp_kaig01: "", // 开关现象--出现时间-年份
        hiscomp_kaig02: "", // 开关现象--出现时间-月份
        hiscomp_kaig03: "", // 开关现象--出现症状时服药方案
        hiscomp_kaig04: "", // 开关现象--出现症状时等效美多芭剂量
        hiscomp_kaig05: "", // 开关现象--有不可预测的关
        hiscomp_kaig06: "", // 开关现象--与服药的关系
      } as any,
      //其他治疗史
      otherDetailsData: {
        data: [
          {
            histrmt_measure: "", // 治疗措施
            histrmt_year: "", // 治疗年时间-年份
            histrmt_month: "", // 治疗时间-月份
            histrmt_hospital: "", // 治疗医院
            histrmt_description: "", // 治疗描述
            histrmt_note: "", // 备注
          },
        ],
      },
      //流行病学史;
      edieDetailsData: {
        data: {
          user_id: "",
          disease_history: "",
        },
      },
      //处理好后的 从事时间最长职业时长 字段
      careerTime: "",
      dashedLineShow: true,
    });
    //公用数据this
    const { proxy: _this } = getCurrentInstance() as any;
    // //详情页面数据接口调用
    const recordId = _this.$route.query.user_id;
    const addLoading = false;
    const isCollapse = computed(() => {
      return _this.$store.getters.sidebar.opened;
    });
    function MedicalHistoryDetailsFun() {
      ApiPastMedicalHistoryDetails2(Number(_this.$route.query.user_id)).then(
        (res) => {
          if (res.success) {
            if (res.data.user_hisdiseases.length != 0) {
              detailsDataModule.previousHistoryDetailsData.data =
                res.data.user_hisdiseases; //既往疾病史
            }
            if (res.data.user_chatidiags.length != 0) {
              detailsDataModule.symptomDiagnosisDetailData.data =
                res.data.user_chatidiags; // 症状诊断
            }
            // 现病史
            detailsDataModule.medicalRecordDetailList = res.data
              .user_medical_history_two || {
              trmtprst_id: "", // 现病史id
              user_id: "", // 患者病历id
              trmtprst_indate: null, // 起病情况--起病时间
              trmtprst_inage: null, // 起病情况--起病年龄
              trmtprst_fstsymp: "", // 起病情况--首发症状
              trmtprst_fstposition: "", // 起病情况--首发部位
              trmtprst_issymmetry: "", // 起病情况--是否对称起病
              trmtprst_symmetrydate: 1651023389000, // 起病情况--对称起病时间
              trmtprst_note: "", // 起病情况--备注
              lu_limb_date: "", // 进展顺序--左上肢时间
              ld_limb_date: "", // 进展顺序--左下肢时间
              ru_limb_date: "", // 进展顺序--右上肢时间
              rd_limb_date: "", // 进展顺序--右下肢时间
              tremor: "", // 主要症状--震颤
              tremor_type: "", // 主要症状--震颤--类型
              tremor_item: "", // 主要症状--震颤--出现时间
              tremor_items: "", // 主要症状--震颤--进展双侧时间
              tonic: "", // 主要症状--强直
              tonic_type: "", // 主要症状--强直--类型
              tonic_item: "", // 主要症状--强直--出现时间
              slow_action: "", // 主要症状--运动迟缓
              slow_action_type: "", // 主要症状--运动迟缓--类型
              slow_action_item: "", // 主要症状--运动迟缓--出现时间
              slow_action_items: "", // 主要症状--运动迟缓--进展双侧时间
              abnormal_gait: "", // 主要症状--步态异常
              abnormal_gait_type: "", // 主要症状--步态异常--类型
              abnormal_gait_item: "", // 主要症状--步态异常--出现时间
              panic_gait: "", // 其它运动症状--慌张步态
              panic_gait_item: "", // 其它运动症状--慌张步态--出现时间
              lowercase: "", // 其他运动症状--小写症
              lowercase_item: "", // 其他运动症状--小写症--出现时间
              difficulty_turning: "", // 其他运动症状--翻身困难
              difficulty_turning_item: "", // 其他运动症状--翻身困难--出现时间
              fall: "", // 其他运动症状--跌倒/姿势不稳
              fall_item: "", // 其他运动症状--跌倒/姿势不稳-出现时间
              language_disability: "", // 其他运动症状--语言障碍
              language_disability_item: "", // 其他运动症状--语言障碍--出现时间
              hard_to_swallow: "", // 其他运动症状--吞咽困难
              hard_to_swallow_item: "", // 其他运动症状--吞咽困难--出现时间
              depress: "",
            };

            detailsDataModule.environmentDetails = res.data.hisenviro; //既往环境习惯
            detailsDataModule.HabitDetailsData = res.data.user_hishabit; //生活习惯
            detailsDataModule.irritabilityDetailsData.allergies =
              res.data.allergies; //过敏史
            detailsDataModule.exerciseDetailsData = res.data.user_hiscomp; // 并发运动症
            if (res.data.user_hisfams.length != 0) {
              detailsDataModule.familyDetailsData.data = res.data.user_hisfams; //家族史
            }
            // edieDetailsData
            if (res.data.user_epidemic_disease) {
              detailsDataModule.edieDetailsData.data =
                res.data.user_epidemic_disease;
            }
            detailsDataModule.pharmacyDetailsData.data = res.data.user_hismeds; //用药史
            if (res.data.user_histrmts.length != 0) {
              detailsDataModule.otherDetailsData.data = res.data.user_histrmts; //其他治疗史
            }
            //既往病史
            const previousHistoryArr =
              detailsDataModule.previousHistoryDetailsData.data;
            previousHistoryArr.map((item: any, index: number) => {
              if (previousHistoryArr.length == index + 1) {
                detailsDataModule.dashedLineShow = true;
              } else {
                detailsDataModule.dashedLineShow = false;
              }
              for (const key in item) {
                if (item[key] === "") {
                  item[key] = null;
                }
              }
            });
            //环境接触史
            const environmentParam = detailsDataModule.environmentDetails;
            for (const key in environmentParam) {
              if (environmentParam[key] === "") {
                environmentParam[key] = null;
              }
            }
            //既往生活习惯
            const HabitParam = detailsDataModule.HabitDetailsData;
            for (const key in HabitParam) {
              if (HabitParam[key] === "") {
                HabitParam[key] = null;
              }
            }
            //家族史
            const familyArr = detailsDataModule.familyDetailsData.data;
            familyArr.map((item: any) => {
              for (const key in item) {
                if (item[key] === "") {
                  item[key] = null;
                }
              }
            });
            //用药史
            const pharmacyArr = detailsDataModule.pharmacyDetailsData.data;
            pharmacyArr.map((item: any) => {
              for (const key in item) {
                if (item[key] === "") {
                  item[key] = null;
                }
              }
            });
            //并发运动症
            const exerciseParam = detailsDataModule.exerciseDetailsData;
            for (const key in exerciseParam) {
              if (exerciseParam[key] === "") {
                exerciseParam[key] = null;
              }
            }
            //其他治疗史
            const otherArr = detailsDataModule.otherDetailsData.data;
            otherArr.map((item: any) => {
              for (const key in item) {
                if (item[key] === "") {
                  item[key] = null;
                }
              }
            });
            //处理年月字段拼接
            if (
              detailsDataModule.environmentDetails.hisenviro_occuyears &&
              detailsDataModule.environmentDetails.hisenviro_occu01years
            ) {
              detailsDataModule.careerTime =
                detailsDataModule.environmentDetails.hisenviro_occuyears +
                "年" +
                detailsDataModule.environmentDetails.hisenviro_occu01years +
                "个月";
            } else if (
              (detailsDataModule.environmentDetails.hisenviro_occuyears != "" &&
                detailsDataModule.environmentDetails.hisenviro_occuyears !=
                  null) ||
              (detailsDataModule.environmentDetails.hisenviro_occu01years ==
                null &&
                detailsDataModule.environmentDetails.hisenviro_occu01years ==
                  "")
            ) {
              detailsDataModule.careerTime =
                detailsDataModule.environmentDetails.hisenviro_occuyears + "年";
            } else if (
              (detailsDataModule.environmentDetails.hisenviro_occuyears == "" &&
                detailsDataModule.environmentDetails.hisenviro_occuyears ==
                  null) ||
              (detailsDataModule.environmentDetails.hisenviro_occu01years !=
                null &&
                detailsDataModule.environmentDetails.hisenviro_occu01years !=
                  "")
            ) {
              detailsDataModule.careerTime =
                detailsDataModule.environmentDetails.hisenviro_occu01years +
                "个月";
            } else {
              detailsDataModule.careerTime = "-";
            }
          }
        }
      );
    }

    onMounted(MedicalHistoryDetailsFun);

    // 现病史想滚数据
    const { mainSymptomsModule } = mainSymptomsFun(
      _this,
      MedicalHistoryDetailsFun
    );

    //既往病史相关数据和逻辑
    const { symptomDiagnosisModule } = symptomDiagnosisModuleFu(
      _this,
      MedicalHistoryDetailsFun
    );

    //既往病史相关数据和逻辑
    const { previousHistoryModule } = previousHistoryModuleFun(
      _this,
      MedicalHistoryDetailsFun
    );
    //既往环境接触史相关数据和逻辑
    const { environmentModule } = environmentModuleFun(
      _this,
      MedicalHistoryDetailsFun
    );
    //生活习惯相关数据和逻辑
    const { livingHabitModule } = livingHabitModuleFun(
      _this,
      MedicalHistoryDetailsFun
    );
    //过敏史相关数据和逻辑
    const { irritabilityModule } = irritabilityModuleFun(
      _this,
      MedicalHistoryDetailsFun
    );
    //家族史相关数据和逻辑
    const { familyModule } = familyFun(_this, MedicalHistoryDetailsFun);
    //用药史相关数据和逻辑
    const { pharmacyModule } = pharmacyFun(_this, MedicalHistoryDetailsFun);
    //并发运动症相关数据和逻辑
    const { exerciseModule } = exerciseModuleFun(
      _this,
      MedicalHistoryDetailsFun
    );
    //其他治疗史相关数据和逻辑
    const { otherTreatmentModule } = otherTreatmentModuleFun(
      _this,
      MedicalHistoryDetailsFun
    );
    //流行病学史相关数据和逻辑
    const { eidemiologicalHistoryModule } = eidemiologicalHistoryModuleFun(
      _this,
      MedicalHistoryDetailsFun
    );
    //滚动模块--相关数据和逻辑
    const { scrollModule } = scrollModuleFun(_this);
    // 起病情况
    const startMedicalDrawer = false;
    // 进展顺序
    const SequenceProgressDrawer = false;
    // 主要症状
    const mainMedicalDrawer = false;
    // 其它运动症状
    const otherMedicalDrawer = false;
    const zhiwulDrawer = false;
    const qitaDrawer = false;
    const feidianxingDrawer = false;
    // 症状诊断的抽屉
    const symptomDiagnosDrawer = false;
    //定义既往史抽屉open标识
    const previousHistoryDrawer = false;
    //既往环境接触史
    const environmentDrawer = false;
    //生活习惯
    const livingHabitDrawer = false;
    //过敏史
    const irritabilityDrawer = false;
    //家族史
    const familyDrawer = false;
    //用药史
    const pharmacyDrawer = false;
    //并发运动症
    const exerciseDrawer = false;
    //其他治疗史
    const otherTreatmentDrawer = false;
    // 流行病学史;
    const eidemiologicalHistoryDrawer = false;

    //编辑抽屉页-取消确认对话框
    const confirmDialogVisible = ref(false);

    function closeConfirmDialog() {
      confirmDialogVisible.value = false;
      //关闭抽屉页
      mainSymptomsModule.zhiwulDrawer = false;
      mainSymptomsModule.qitaDrawer = false;
      mainSymptomsModule.feidianxingDrawer = false;
      mainSymptomsModule.otherMedicalDrawer = false;
      mainSymptomsModule.zhiwuDrawer = false;
      mainSymptomsModule.startMedicalDrawer = false;
      mainSymptomsModule.SequenceProgressDrawer = false;
      mainSymptomsModule.mainMedicalDrawer = false;
      symptomDiagnosisModule.symptomDiagnosDrawer = false;
      previousHistoryModule.previousHistoryDrawer = false;
      environmentModule.environmentDrawer = false;
      livingHabitModule.livingHabitDrawer = false;
      irritabilityModule.irritabilityDrawer = false;
      familyModule.familyDrawer = false;
      pharmacyModule.pharmacyDrawer = false;
      exerciseModule.exerciseDrawer = false;
      otherTreatmentModule.otherTreatmentDrawer = false;
      eidemiologicalHistoryModule.eidemiologicalHistoryDrawer = false;
    }
    function completeConfirmConfirm() {
      confirmDialogVisible.value = false;
    }

    function delMedicalRecordClick() {
      _this
        .$confirm("删除后录入的数据不可恢复，确认删除吗？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(async () => {
          const res = await delMedicalHistory(recordId);
          if (res.success) {
            _this.$message.success("删除成功！");
            _this.$emit("backCaseRecord");
          } else {
            _this.$message.error(res.message);
          }
        });
    }

    //公用逻辑
    function init() {
      //对list主体内容元素监听滚动事件
      const contentDom: any = document.getElementById("content");
      contentDom.addEventListener("scroll", scrollModule.handleScroll);

      if ((document as any).querySelector(".footer_buttons")) {
        console.log(isCollapse.value);
        if (!isCollapse.value) {
          (document as any)
            .querySelector(".footer_buttons")
            .classList.remove("collapsed");
        } else {
          (document as any)
            .querySelector(".footer_buttons")
            .classList.add("collapsed");
        }
      }
    }
    const tableData = detailsDataModule.pharmacyDetailsData.data;

    onMounted(init);
    function leave() {
      const contentDom: any = document.getElementById("content");
      contentDom.removeEventListener("scroll", scrollModule.handleScroll);
    }

    onUnmounted(leave);
    return {
      recordId,
      ...toRefs(mainSymptomsModule),
      ...toRefs(scrollModule),
      ...toRefs(symptomDiagnosisModule),
      ...toRefs(previousHistoryModule),
      ...toRefs(environmentModule),
      ...toRefs(livingHabitModule),
      ...toRefs(irritabilityModule),
      ...toRefs(familyModule),
      ...toRefs(pharmacyModule),
      ...toRefs(exerciseModule),
      ...toRefs(otherTreatmentModule),
      ...toRefs(detailsDataModule),
      ...toRefs(eidemiologicalHistoryModule),
      completeConfirmConfirm,
      confirmDialogVisible,
      closeConfirmDialog,
      delMedicalRecordClick,
      tableData,
      addLoading,
    };
  },
});
