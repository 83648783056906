
























































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import {
  ApiBaseinfomationDetails,
  ApiBasicInformationSave,
  ApiBasicInfoCheckName,
  ApiGetllnessList,
  ApiGetProvince,
  ApiGetCity,
  ApiGetArea,
  getFamliyList,
  addFamily,
  delFamilyContant,
} from "@/apis";
import { checkIdCard, getAgeFromIDCard } from "@/utils";
import { breadcrumb } from "@/utils/breadcrumb";
import moment from "moment";
import * as utils from "@/utils";

import { Form } from "element-ui";
@Component({})
export default class MedicalInformation extends Vue {
  informationDetails: any = {
    user_name: "", //姓名
    user_sex: "", //性别
    user_tel: "", //联系电话
    user_idtype: "", //身份证件类型

    user_idcardnum: "", //证件号码
    // user_birthday: "-", //出生日期
    age: "",
    user_age: "", //年龄
    user_education: "", //文化程度

    user_height: "", //身高
    user_weight: "", //体重
    //现住址：为省市区+详细地址
    user_province: "",
    province_code: "",
    user_city: "",
    city_code: "",
    area: "",
    area_code: "",
    user_communicationaddr: "", //详细地址

    user_disease: [], //当前所患疾病
    disease_date: "", //发病年份
    user_process: "", //病程
    createdate: "", //病历创建时间
  } as any;

  mounted() {
    this.baseinfomationDetailsFun();
    this.initCurrentIllnessList();
    this.getFamliyLists();
  }
  async baseinfomationDetailsFun() {
    ApiBaseinfomationDetails(Number(this.$route.query.user_id)).then(
      (res: any) => {
        if (res.success) {
          if (res.data.user_disease) {
            res.data.user_disease = JSON.parse(res.data.user_disease);
          }
          this.informationDetails = res.data;
        }
      }
    );
  }
  formatAddr() {
    const detail = this.informationDetails;
    let result = "";
    if (detail.user_province && detail.user_city && detail.area) {
      result = result + detail.user_province + detail.user_city + detail.area;
    }
    if (detail.user_communicationaddr) {
      result = result + detail.user_communicationaddr;
    }
    if (result == "") {
      result = "-";
    }
    return result;
  }
  //基础信息编辑-抽屉页
  currentAddr: any[] = [];
  basicInformationForm = {
    user_id: "",
    user_name: "",
    user_sex: "",
    user_tel: "",
    user_idtype: "",
    user_idcardnum: "",
    // user_birthday: "",
    age: "",
    user_education: "",
    user_height: "",
    user_weight: "",
    province_code: "",
    city_code: "",
    area_code: "",
    user_communicationaddr: "",
    disease_date: "",
    user_disease: "",
    user_censusAddr: "",
    user_country: "",
    user_nationality: "汉族",
    user_province: "",
    user_city: "",
    user_occupation: "",
    user_marriage: "",
  } as any;
  drawerVisible = false;
  addShowDialog = false;
  dataList = [] as any;
  clickEdit() {
    /**
     * user_id需要单独拿
     *
     * currentAddr表单项现住地址需要特殊处理
     */
    //把详情数据 转换为 编辑表单数据
    this.basicInformationForm.user_id = this.$route.query.user_id;
    const detail: any = this.informationDetails;
    if (detail.province_code) {
      this.currentAddr = [
        detail.province_code,
        detail.city_code,
        detail.area_code,
      ];
    }
    for (const name in this.basicInformationForm) {
      if (detail[name] || detail[name] == 0) {
        this.basicInformationForm[name] = detail[name];
      } else {
        this.basicInformationForm[name] = "";
      }
    }
    //打开抽屉页
    this.drawerVisible = true;
  }
  closeDrawer() {
    this.confirmDialogVisible = true;
    this.currentAddr = [];
  }
  passportRule = [
    { required: false, message: "请输入证件号码", trigger: "blur" },
  ];
  idCardRule = [
    { required: false, message: "请输入证件号码", trigger: "blur" },
    {
      pattern:
        /^(^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$)|(^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])((\d{4})|\d{3}[Xx])$)$/,
      message: "请输入正确的身份证号",
      trigger: "blur",
    },
  ];
  sexList = [
    {
      label: "男",
      value: "男",
    },
    {
      label: "女",
      value: "女",
    },
  ];
  homeland = ["中国", "澳大利亚", "新加坡"];
  documentsList = [
    {
      label: "身份证",
      value: "身份证",
    },
    {
      label: "护照",
      value: "护照",
    },
  ];
  educationLevellist = [
    "未知",
    "本科",
    "研究生",
    "大专",
    "中专",
    "技校",
    "高中",
    "初中",
    "小学",
    "文盲",
  ];
  nation = ["汉族", "回族", "满族", "其它"];
  position = ["工人", "教师", "农民", "干部", "学生", "职员", "退休", "其它"];
  marry = ["未婚", "已婚", "初婚", "再婚", "复婚", "丧偶", "离婚", "未知"];

  currentIllnessList = [];
  currentAddressProps = {
    lazy: true,
    lazyLoad(node: any, resolve: any) {
      let result = [];
      let param = {};
      //获取当前层级
      const { level } = node;
      if (level == 0) {
        ApiGetProvince(param).then((res) => {
          if (res.success) {
            // code: "34"
            // first_letter: "A"
            // name: "安徽省"
            result = res.data.map((item: any) => {
              return {
                value: item.code,
                label: item.name,
              };
            });
            resolve(result);
          }
        });
      } else if (level == 1) {
        param = {
          province_code: node.value,
        };
        ApiGetCity(param).then((res) => {
          if (res.success) {
            // code: "3408"
            // first_letter: "A"
            // name: "安庆市"
            // province_code: "34"
            result = res.data.map((item: any) => {
              return {
                value: item.code,
                label: item.name,
              };
            });
            resolve(result);
          }
        });
      } else if (level == 2) {
        param = {
          city_code: node.value,
        };
        ApiGetArea(param).then((res) => {
          if (res.success) {
            // city_code: "3408"
            // code: "340871"
            // first_letter: "A"
            // name: "安徽安庆经济开发区"
            // province_code: "34"
            result = res.data.map((item: any) => {
              return {
                value: item.code,
                label: item.name,
                leaf: level >= 2,
              };
            });
            resolve(result);
          }
        });
      }
    },
  };
  basicInformationRules: any = {
    user_name: [{ required: true, message: "请输入患者姓名", trigger: "blur" }],
    user_sex: [{ required: true, message: "请选择患者性别", trigger: "blur" }],
    user_tel: [{ required: true, message: "请输入联系方式", trigger: "blur" }],
    // user_birthday: [
    //   { required: true, message: "请选择出生日期", trigger: "blur" },
    // ],
    age: [{ required: true, message: "请输入年龄", trigger: "blur" }],
    user_idcardnum: this.passportRule,
    disease_date: [
      { required: true, message: "请选择发病年份", trigger: "blur" },
    ],
    user_disease: [
      { required: true, message: "请选择当前所患疾病", trigger: "blur" },
    ],
  };
  userDisease(val: any) {
    console.log(val, "=========val");
    if (val) {
      return val.join(" ");
    } else {
      return "-";
    }
    // JSON.parse(informationDetails.user_disease).join(" ")
  }
  dash(data: string | number | undefined | null) {
    let result: string | number = "-";
    if (data || data === 0) {
      result = data;
    }
    return result;
  }
  saveBasicInformation() {
    const currentAddrList = this.currentAddr;
    if (currentAddrList.length == 3) {
      this.basicInformationForm.province_code = currentAddrList[0];
      this.basicInformationForm.city_code = currentAddrList[1];
      this.basicInformationForm.area_code = currentAddrList[2];
    } else {
      this.basicInformationForm.province_code = "";
      this.basicInformationForm.city_code = "";
      this.basicInformationForm.area_code = "";
    }
    const formRef = this.$refs.basicInformationRuleForm as any;
    formRef.validate((valid: boolean) => {
      if (valid) {
        const param = {
          ...this.basicInformationForm,
        };
        param.age = Number(param.age);
        param.user_disease = JSON.stringify(
          this.basicInformationForm.user_disease
        );
        this.basicInformationForm.user_id = this.$route.query.user_id;
        //如果有填写证件号码，需要判断证件类型，没有证件号码直接提交
        if (this.basicInformationForm.user_idcardnum) {
          //如果证件类型是护照，不进行验证,直接调用新增基础信息接口
          if (this.basicInformationForm.user_idtype == "护照") {
            const par = {
              doctor_id: (utils.get("doctorLoginInfo") as any).id,
              user_name: this.basicInformationForm.user_name,
              user_id: this.basicInformationForm.user_id,
            };
            ApiBasicInfoCheckName(par).then((ress) => {
              if (!ress.data.duplication_name) {
                ApiBasicInformationSave(param).then((res) => {
                  if (res.success) {
                    this.$message.success("保存成功！");
                    this.baseinfomationDetailsFun();
                    //关闭抽屉页
                    this.drawerVisible = false;
                  }
                });
              } else {
                this.$confirm("已有相同姓名的患者，确认保存吗？", "提示", {
                  confirmButtonText: "确定",
                  cancelButtonText: "取消",
                  type: "warning",
                }).then(async () => {
                  ApiBasicInformationSave(param).then((res) => {
                    if (res.success) {
                      this.$message.success("保存成功！");
                      this.baseinfomationDetailsFun();
                      //关闭抽屉页
                      this.drawerVisible = false;
                    }
                  });
                });
              }
            });
          } else {
            const self = this;
            //如果证件类型是身份证 校验身份证与姓名是否匹配
            checkIdCard(
              this.basicInformationForm.user_idcardnum,
              this.basicInformationForm.user_name,
              function (res: any) {
                if (res != "01") {
                  self.$message.warning(res);
                  return;
                }

                const par = {
                  doctor_id: (utils.get("doctorLoginInfo") as any).id,
                  user_name: self.basicInformationForm.user_name,
                  user_id: self.basicInformationForm.user_id,
                };
                ApiBasicInfoCheckName(par).then((ress) => {
                  if (!ress.data.duplication_name) {
                    ApiBasicInformationSave(param).then((res) => {
                      if (res.success) {
                        self.$message.success("保存成功！");
                        self.baseinfomationDetailsFun();
                        //关闭抽屉页
                        self.drawerVisible = false;
                      }
                    });
                  } else {
                    self
                      .$confirm("已有相同姓名的患者，确认保存吗？", "提示", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                      })
                      .then(async () => {
                        ApiBasicInformationSave(param).then((res) => {
                          if (res.success) {
                            self.$message.success("保存成功！");
                            self.baseinfomationDetailsFun();
                            //关闭抽屉页
                            self.drawerVisible = false;
                          }
                        });
                      });
                  }
                });
              }
            );
          }
        } else {
          const par = {
            doctor_id: (utils.get("doctorLoginInfo") as any).id,
            user_name: this.basicInformationForm.user_name,
            user_id: this.basicInformationForm.user_id,
          };
          ApiBasicInfoCheckName(par).then((ress) => {
            if (!ress.data.duplication_name) {
              ApiBasicInformationSave(param).then((res) => {
                if (res.success) {
                  this.$message.success("保存成功！");
                  this.baseinfomationDetailsFun();
                  //关闭抽屉页
                  this.drawerVisible = false;
                }
              });
            } else {
              this.$confirm("已有相同姓名的患者，确认保存吗？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              }).then(async () => {
                ApiBasicInformationSave(param).then((res) => {
                  if (res.success) {
                    this.$message.success("保存成功！");
                    this.baseinfomationDetailsFun();
                    //关闭抽屉页
                    this.drawerVisible = false;
                  }
                });
              });
            }
          });
        }
      }
    });
  }
  dealCard() {
    const idCardNum = this.basicInformationForm.user_idcardnum;
    if (
      idCardNum.length === 18 &&
      this.basicInformationForm.user_idtype === "身份证"
    ) {
      const age = getAgeFromIDCard(idCardNum);
      this.basicInformationForm.age = age;
    }
  }
  basicInformationSelectChange(content: string) {
    if (content == "身份证") {
      this.basicInformationRules.user_idcardnum = this.idCardRule;
    } else {
      this.basicInformationRules.user_idcardnum = this.passportRule;
    }
    this.basicInformationForm.user_idcardnum = "";
  }
  initCurrentIllnessList() {
    const param = {
      dc_typeid: 699,
    };
    ApiGetllnessList(param).then((res) => {
      if (res.success) {
        this.currentIllnessList = res.data;
      }
    });
  }
  //基础信息编辑-取消确认对话框
  confirmDialogVisible = false;
  closeConfirmDialog() {
    //关闭对话框
    this.confirmDialogVisible = false;
    //关闭抽屉页
    this.drawerVisible = false;
  }
  completeConfirmConfirm() {
    //关闭对话框
    this.confirmDialogVisible = false;
  }
  formatDate(timeStamp: number | string | null) {
    if (!timeStamp || timeStamp == "-") {
      return "-";
    }
    const time = new Date(timeStamp);
    //年-月-日
    return `${time.getFullYear()}-${this.format(
      time.getMonth() + 1
    )}-${this.format(time.getDate())}`;
  }
  formatYearMonth(timeStamp: number | string | null) {
    if (!timeStamp || timeStamp == "-") {
      return "-";
    }
    const time = new Date(timeStamp);
    console.log(this.format(time.getMonth()), "===========");
    //年-月
    return `${time.getFullYear()}-${this.format(time.getMonth() + 1)}`;
  }
  formatYear(timeStamp: number | string | null) {
    let result = "";
    result = this.formatDate(timeStamp);
    if (result == "-") {
      return result;
    } else {
      return result.slice(0, 4);
    }
  }
  format(date: number | string) {
    date = date.toString();
    return ("0" + date).slice(-2);
  }
  provinceList = [] as any;
  cityList = [] as any;
  //获取省下拉数据
  resultprovinceList() {
    ApiGetProvince().then((res: any) => {
      if (res.success) {
        this.provinceList = res.data;
        console.log(this.provinceList);
      }
    });
  }
  //省份change事件
  nationChange(val: any) {
    if (this.basicInformationForm.user_country != "中国") return;
    let objNation = {} as any;
    objNation = this.provinceList.find((item: any) => {
      return item.code === val;
    });
    this.basicInformationForm.user_province = objNation.name;
    const province_id = objNation.code;
    ApiGetCity({ province_code: province_id }).then((res) => {
      this.cityList = res.data;
    });

    this.basicInformationForm.user_city = "";
  }
  //市change事件
  cityChange(val: any) {
    if (this.basicInformationForm.user_country != "中国") return;
    let objCity = {} as any;
    objCity = this.cityList.find((item: any) => {
      return item.code === val;
    });
    this.basicInformationForm.user_city = objCity.name;
  }
  // 点击清空省
  clearProvince() {
    this.basicInformationForm.user_city = "";
    this.basicInformationForm.user_province = "";
    // 设置市为空
    this.cityList = [];
  }
  // 点击清空市
  clearCity() {
    // 设置市为空
    this.basicInformationForm.user_city = "";
    // setTimeout(() => {
    //   // 添加事件队列
    //   const formRef11 = this.$refs["ruleForm"] as any;
    //   formRef11.clearValidate(["province"]);
    //   formRef11.clearValidate(["county"]);
    //   formRef11.clearValidate(["city"]);
    // }, 0);
  }
  countryChangeHandle() {
    if (this.basicInformationForm.user_country == "中国") {
      this.basicInformationForm.user_province = "";
      this.basicInformationForm.user_city = "";
      this.resultprovinceList();
    } else if (this.basicInformationForm.user_country == "澳大利亚") {
      this.basicInformationForm.user_province = "澳大利亚";
      this.basicInformationForm.user_city = "澳大利亚";
      this.provinceList = [
        {
          code: "100000080",
          first_letter: "A",
          name: "澳大利亚",
        },
      ];
      this.cityList = [
        {
          province_code: "100000080",
          code: "1000000801",
          first_letter: "A",
          name: "澳大利亚",
        },
      ];
    } else if (this.basicInformationForm.user_country == "新加坡") {
      this.basicInformationForm.user_province = "新加坡";
      this.basicInformationForm.user_city = "新加坡";
      this.provinceList = [
        {
          code: "100000081",
          first_letter: "X",
          name: "新加坡",
        },
      ];
      this.cityList = [
        {
          province_code: "100000081",
          code: "1000000811",
          first_letter: "X",
          name: "新加坡",
        },
      ];
    }
  }
  currentAddrContant = "";
  addForm = {
    family_name: "",
    family_relation: "",
    family_tele: "",
    family_addr: "",
    family_email: "",
    family_qq: "",
    family_wechat: "",
    family_note: "",
  } as any;
  addFormRules = {
    family_relation: [
      { required: true, message: "请选择与患者关系", trigger: "change" },
    ],
    family_tele: [
      { required: true, message: "请填写联系电话", trigger: "blur" },
    ],
  } as any;
  cancelDialogFun() {
    this.addShowDialog = false;
    this.handleKeysBlank(this.addForm);
    setTimeout(() => {
      (this.$refs.addForm as Form).clearValidate();
    }, 20);
  }
  handleCatergoryChange() {
    const arr = (this.$refs.cascaderCatergoryRef as any).getCheckedNodes();
    const textArr = [] as any;
    arr.forEach((i: any) => {
      textArr.push(i.pathLabels);
    });
    console.log(textArr);
    this.currentAddrContant = textArr[0].join("");
  }
  addOperationHandle() {
    (this.$refs.addForm as Form).validate(async (val: any) => {
      if (val) {
        console.log(this.addForm, this.currentAddrContant);
        if (this.currentAddrContant) {
          this.addForm.family_addr =
            this.currentAddrContant + this.addForm.family_addr;
        }
        const param = {
          ...this.addForm,
          user_id: this.$route.query.user_id,
        };
        const res = await addFamily(param);
        if (res.success) {
          this.getFamliyLists();
        } else {
          this.$message.error(res.message);
        }
        this.handleKeysBlank(this.addForm);
        (this.$refs.addForm as Form).clearValidate();
        this.addShowDialog = false;
      }
    });
  }
  handleKeysBlank(val: any) {
    Object.keys(val).map((item: any) => {
      val[item] = "";
    });
  }
  async getFamliyLists() {
    const res = await getFamliyList(this.$route.query.user_id);
    if (res.success) {
      this.dataList = res.data;
    } else {
      this.$message.error(res.message);
    }
  }
  editButton(row: any) {
    Object.keys(this.addForm).map((item: any) => {
      this.addForm[item] = row[item];
      console.log(row[item], row);
    });
    this.addForm.family_id = row.family_id;
    console.log(this.addForm);
    this.addShowDialog = true;
  }
  async deleteButton(row: any) {
    console.log(row);
    this.$confirm("是否确认要删除?", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    }).then(async () => {
      const res = await delFamilyContant(row["family_id"]);
      if (res.success) {
        this.$message.success("删除成功！");
        this.getFamliyLists();
      } else {
        this.$message.error(res.message);
      }
    });
  }

  ageInputHandle() {
    if (isNaN(this.basicInformationForm.age)) {
      this.$message.error("请输入大于等于0的正整数。");
      this.basicInformationForm.age = "";
      return;
    }
    if (Number(this.basicInformationForm.age) < 0) {
      this.$message.error("请输入大于等于0的正整数。");
      this.basicInformationForm.age = "";
      return;
    }
  }
}
