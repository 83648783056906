
















































































































































































































import { Component, Vue } from "vue-property-decorator";
import { breadcrumb } from "@/utils/breadcrumb";
import MedicalInformation from "@/views/EleMediRecord/Scale/Details/MedicalInformation.vue";
import MedicalHistory from "@/views/EleMediRecord/Scale/Details/MedicalHistory.vue";
import MedicalHistory2 from "@/views/EleMediRecord/Scale/Details/MedicalHistory2.vue";
import MedicalHistory3 from "@/views/EleMediRecord/Scale/Details/MedicalHistory3.vue";
import MedicalRecords from "@/views/EleMediRecord/Scale/Details/medicalRecords/MedicalRecords.vue";
import ScaleAssessment from "@/views/EleMediRecord/Scale/Details/ScaleAssessment.vue";
import OperationRecord from "@/views/EleMediRecord/Scale/Details/OperationRecord.vue";
import ControlHistory from "@/views/EleMediRecord/Scale/Details/ControlHistory.vue";
import VideoData from "@/views/EleMediRecord/Scale/Details/VideoData.vue";
import InformedConsent from "@/views/EleMediRecord/Scale/Details/InformedConsent.vue";
import MedicalHistoryCard from "@/views/EleMediRecord/Scale/Details/MedicalHistoryCard.vue";

import {
  ApiLabelList,
  ApiBaseinfomationDetails,
  ApiCaseLabelSave,
  medicalLog,
} from "@/apis";
import { number } from "echarts";
@Component({
  name: "CaseDetails",
  components: {
    MedicalInformation,
    MedicalHistory,
    MedicalHistory2,
    MedicalHistory3,
    MedicalRecords,
    ScaleAssessment,
    OperationRecord,
    ControlHistory,
    VideoData,
    InformedConsent,
    MedicalHistoryCard,
  },
})
export default class CaseDetails extends Vue {
  activeName = localStorage.getItem("activeName") || "first";
  // activeName = "first";
  // tabIndex = 0;
  tabIndex = localStorage.getItem("tabIndex") || 0;
  showTagDialog = false;
  openDrawer = false;
  tabIndexMedicalRecord = 1 as any;
  logList = [] as any;

  clickTab(tab: any, event: any) {
    const index = tab.index;
    switch (index) {
      case "0": {
        this.tabIndex = 0;
        break;
      }
      case "1": {
        this.tabIndex = 1;
        break;
      }
      case "2": {
        this.tabIndex = 2;
        break;
      }
      case "3": {
        this.tabIndex = 3;
        break;
      }
      case "4": {
        this.tabIndex = 4;
        break;
      }
      case "5": {
        this.tabIndex = 5;
        break;
      }
      case "6": {
        this.tabIndex = 6;
        break;
      }
      case "7": {
        this.tabIndex = 7;
        break;
      }
      // case "8": {
      //   this.tabIndex = 7;
      //   break;
      // }
      // case "9": {
      //   this.tabIndex = 8;
      //   break;
      // }
    }
  }
  created() {
    this.tabIndexMedicalRecord = localStorage.getItem("addMedicalRecordIndex");
  }
  destroyed() {
    localStorage.removeItem("tabIndex");
    localStorage.removeItem("activeName");
  }
  setColor(val: number) {
    switch (val) {
      case 0:
        return "green";
      case 1:
        return "red";
      case 2:
        return "blue";
    }
  }
  drawerCloseHandle() {
    this.openDrawer = false;
  }
  openDrawerHandle() {
    this.openDrawer = true;
    medicalLog(Number(this.$route.query.user_id)).then((res) => {
      if (res.success) {
        this.logList = res.data;
      } else {
        this.$message.error(res.message);
      }
    });
  }
  addTag() {
    this.showTagDialog = true;

    // this.dialogForm.tags = this.labelListTag;
    this.labelListTag.forEach((item: any) => {
      this.dialogForm.tags.push(item.name);
    });

    if (this.labelList.length == 3) {
      this.showTagDialog = false;
    } else if (this.labelList.length == 0) {
      this.dialogForm.tags = [];
    }
  }
  dialogForm = {
    tags: [] as any,
  };
  name = "";
  labelList = [];
  tagList: any[] = [];
  labelListTag = [];
  mounted() {
    this.baseinfomationFun();
    this.getTagList();
    //病例详情-量表评估模块初始化-默认打开量表评估
    this.initScale();
  }
  //基础信息接口数据
  async baseinfomationFun() {
    ApiBaseinfomationDetails(Number(this.$route.query.user_id)).then(
      (res: any) => {
        if (res.success) {
          this.name = res.data.user_name;
          localStorage.setItem("database_id", res.data.user_id);
          if (res.data.tag && res.data.tag != "") {
            this.labelList = JSON.parse(res.data.tag);
            this.labelListTag = JSON.parse(res.data.tag);
          } else {
            this.labelList = [];
          }
        }
      }
    );
  }
  initScale() {
    let scaleSession: any = sessionStorage.getItem("case_scale-view");
    if (scaleSession) {
      scaleSession = JSON.parse(scaleSession);
      this.activeName = scaleSession.caseTabIndex;
      this.tabIndex = 3;
    }
  }
  //关闭表单对话框函数
  closeFormDialog() {
    this.showTagDialog = false;
    this.dialogForm.tags = [];
  }
  //标签下拉框列表
  getTagList() {
    const param = {};
    ApiLabelList(param).then((res: any) => {
      //color: 绿0 红1 蓝2
      if (res.success) {
        this.tagList = res.data.map((item: any) => {
          return {
            name: item.name,
            color: item.color,
          };
        });
      }
    });
  }
  //删除标签
  deleteTag(index: number) {
    this.labelList.splice(index, 1);
    let tagList = null;
    if (this.labelList.length == 0) {
      tagList = "[]";
    } else {
      tagList = JSON.stringify(this.labelList);
    }
    const param: any = {
      user_id: Number(this.$route.query.user_id),
      tag: tagList,
    };
    ApiCaseLabelSave(param).then((res: any) => {
      if (res.success) {
        this.baseinfomationFun();
      }
    });
  }
  //表单对话框确定按钮点击触发
  completeFormContinue() {
    const temp = JSON.parse(JSON.stringify(this.dialogForm.tags));

    temp.forEach((item: any, idx: number) => {
      if (typeof item == "string") {
        this.tagList.forEach((it: any) => {
          if (it.name == item) {
            const itemDetail = {
              color: it.color,
              name: item,
            };
            temp.splice(idx, 1, itemDetail);
          }
        });
      }
    });
    const tagList = JSON.stringify(temp);
    const param: any = {
      user_id: Number(this.$route.query.user_id),
      tag: tagList,
    };
    ApiCaseLabelSave(param).then((res: any) => {
      if (res.success) {
        this.baseinfomationFun();
        this.closeFormDialog();
      }
    });
  }
  //添加标签对话框-前去分组维护按钮触发事件
  goTagManagement() {
    breadcrumb.push(
      this,
      "/doctor-system/medical/tags",
      "分组维护",
      null,
      true
    );
  }
  formatDate(timeStamp: number | string | null) {
    if (timeStamp == null) {
      return "-";
    }
    const time = new Date(timeStamp);
    //年-月-日
    return `${time.getFullYear()}-${this.format(
      time.getMonth() + 1
    )}-${this.format(time.getDate())} ${this.format(
      time.getHours()
    )}:${this.format(time.getMinutes())}:${this.format(time.getSeconds())}`;
  }
  format(date: number | string) {
    date = date.toString();
    return ("0" + date).slice(-2);
  }
}
