//家族史
import {
  computed,
  defineComponent,
  getCurrentInstance,
  onMounted,
  reactive,
  toRefs,
  watch,
} from "@vue/composition-api";
import { ApiFamilyHistorySave } from "@/apis/index";
interface Module {
  familyForm: object;

  test: () => void;
}
function familyFun(ins: any, familyDetailsFun: any) {
  const familyModule: any = reactive({
    familyForm: {
      data: [
        {
          hisfam_id: "", // 家族史id
          user_id: "", // 患者病历id
          hisfam_relation: "", // 与患者关系
          hisfam_indate: "", // 发病年龄
          hisfam_name: "--", // 家属姓名
          hisfam_diagnosis: "--", // 疾病诊断
          hisfam_issimilar: "", // 症状是否类似
          hisfam_isclear: "", // 诊断是否明确
          hisfam_isalive: "", // 家属是否健在
          hisfam_tele: "", // 家属联系方式
          hisfam_hospital: "", // 诊断医院
          hisfam_other: "--", // 其它情况说明
        },
      ],
    } as any,
    familyRules: {
      hisfam_relation: [
        { required: true, message: "请选择与患者关系", trigger: "blur" },
      ],
      hisfam_diagnosis: [
        { required: true, message: "请选择疾病诊断", trigger: "blur" },
      ],
    },
    hisfam_relation: ["父母", "子女", "夫妻", "其它"],
    hisfam_diagnosis: ["未知", "痊愈", "好转", "未变", "有后遗症", "恶化"],

    familyDrawer: false,
    //保存
    saveFamily() {
      const formRef = ins.$refs.familyRuleForm as any;
      formRef.validate((valid: boolean) => {
        if (valid) {
          const param = familyModule.familyForm.data;
          param.map((item: any) => {
            item.user_id = Number(ins.$route.query.user_id);
          });
          ApiFamilyHistorySave(param).then((res) => {
            if (res.success) {
              //
              ins.$message.success("修改成功！");
              familyModule.familyDrawer = false;
              // //详情页面数据接口调用
              familyDetailsFun();
            } else {
              ins.$message.error(res.message);
            }
          });
        }
      });
    },
    //扩展
    addFamily() {
      const formRef = ins.$refs["familyRuleForm"] as any;
      formRef.validate((valid: boolean) => {
        if (valid) {
          const arr = {
            //家族史
            hisfam_id: "", // 家族史id
            user_id: Number(ins.$route.query.user_id), // 患者病历id
            hisfam_relation: "", // 与患者关系
            hisfam_indate: "", // 发病年龄
            hisfam_name: "", // 家属姓名
            hisfam_diagnosis: "", // 疾病诊断
            hisfam_issimilar: "", // 症状是否类似
            hisfam_isclear: "", // 诊断是否明确
            hisfam_isalive: "", // 家属是否健在
            hisfam_tele: "", // 家属联系方式
            hisfam_hospital: "", // 诊断医院
            hisfam_other: "", // 其它情况说明
          };
          ins.familyForm.data.push(arr);
        }
      });
    },
    flag: true,
    // 表单减少一行
    reduceFamily(index: number) {
      const h = ins.$createElement;
      ins
        .$msgbox({
          title: "提示",
          message: h("p", undefined, [
            h("img", {
              attrs: {
                src: require("../../../../../assets/img/icon-confirm-tips.png"),
              },
            }),
            h("span", { class: "message_text" }, " 确认删除吗？ "),
          ]),
          showCancelButton: true,
          confirmButtonText: "确 认",
          cancelButtonText: "取 消",
          cancelButtonClass: "message_cancel",
          confirmButtonClass: "message_confirm",
          closeOnClickModal: false,
        })
        .then(() => {
          ins.familyForm.data.splice(index, 1);
        })
        .catch(() => {
          console.log("取消");
        });
    },

    //抽屉open事件
    familyClick(familyEditForm: any) {
      familyModule.familyForm = JSON.parse(JSON.stringify(familyEditForm));
      familyModule.familyDrawer = true;
    },

    familyCanelBtn() {
      ins.confirmDialogVisible = true;
    },
    familyRadio(property: string, content: string, index: number) {
      if (familyModule.familyForm.data[index][property] == content) {
        familyModule.familyForm.data[index][property] = "";
      } else {
        familyModule.familyForm.data[index][property] = content;
      }
      familyModule.familyForm = { ...familyModule.familyForm };
    },
  });
  return {
    familyModule,
  };
}
export { familyFun };
