// 既往生活习惯
import {
  computed,
  reactive,
  watch,
  getCurrentInstance,
} from "@vue/composition-api";
import { ApiPastLivingHabitsSave } from "@/apis/index";
interface Module {
  livingHabitForm: object;
  test: () => void;
}
function livingHabitModuleFun(ins: any, livingHabitDetailsFun: any) {
  const livingHabitModule: any = reactive({
    // 表单数据
    livingHabitForm: {
      //吸烟
      hishabit_issmoke: "", //是否吸烟
      hishabit_smoke01: "", //日均吸烟量
      hishabit_smoke_date01: "", //开始吸烟时间
      hishabit_smoke04: "", //吸烟年限
      hishabit_smoke_date02: "", //戒烟时间
      hishabit_smoke07: "", //戒烟年限
      hishabit_smoke08: "", //有无被动吸烟
      //饮酒
      hishabit_isdrunk: "", // 是否饮酒
      hishabit_drunk01: "", // 平均每日饮酒量
      hishabit_drunk_date01: "", // 开始饮酒时间
      hishabit_drunk04: "", // 饮酒年限
      hishabit_drunk_date02: "", // 戒酒时间
      hishabit_drunk07: "", // 戒酒年限
      //锻炼
      hishabit_isexercise: "", // 是否锻炼
      hishabit_exercise01: "", // 锻炼频度
      hishabit_exercise02: "", // 锻炼方式
      hishabit_exercise03: "", // 每天锻炼时长
      //喝茶
      hishabit_istea: "", //		是否喝茶
      hishabit_tea01: "", //	频度
      hishabit_tea02: "", //浓度
      hishabit_tea03: "", //		开始喝茶年龄
      hishabit_tea04: "", //		停止喝茶年龄
      hishabit_tea05: "", //		喝茶种类
      //咖啡
      hishabit_iscoffee: "", // 是否喝咖啡
      hishabit_coffee01: "", // 咖啡--频度
      hishabit_coffee02: "", // 咖啡--浓度
      hishabit_coffee03: "", // 开始喝咖啡年龄
      hishabit_coffee04: "", // 停止喝咖啡年龄
    } as any,
    exerciseList: ["骑车", "跑步", "打太极", "登山", "跳舞", "散步", "其它"],
    teaTypeList: ["红茶", "绿茶", "白茶", "黑茶", "其它"],
    livingHabitDrawer: false,

    // //抽屉打开事件
    // openLivingHabitDrawer() {
    //   ins.$nextTick(() => {
    //     const livingHabitRef = ins.$refs[
    //       "livingHabitRuleForm"
    //     ] as any;
    //     livingHabitRef.resetFields();
    //   });
    // },
    //抽屉open事件
    livingHabitClick(livingHabitEditForm: any) {
      const smokeDate =
        livingHabitEditForm.hishabit_smoke02 +
        "-" +
        livingHabitEditForm.hishabit_smoke03;
      const quitSmokeDate =
        livingHabitEditForm.hishabit_smoke05 +
        "-" +
        livingHabitEditForm.hishabit_smoke06;
      const drinkDate =
        livingHabitEditForm.hishabit_drunk02 +
        "-" +
        livingHabitEditForm.hishabit_drunk03;
      const quitDrinkDate =
        livingHabitEditForm.hishabit_drunk05 +
        "-" +
        livingHabitEditForm.hishabit_drunk06;

      livingHabitEditForm.hishabit_smoke_date01 = new Date(smokeDate).getTime(); // 年月日转化成时间戳 吸烟
      livingHabitEditForm.hishabit_smoke_date02 = new Date(
        quitSmokeDate
      ).getTime(); // 年月日转化成时间戳   戒烟
      livingHabitEditForm.hishabit_drunk_date01 = new Date(drinkDate).getTime(); // 年月日转化成时间戳   开始饮酒时间
      livingHabitEditForm.hishabit_drunk_date02 = new Date(
        quitDrinkDate
      ).getTime(); // 年月日转化成时间戳   开始戒酒时间

      livingHabitModule.livingHabitForm = JSON.parse(
        JSON.stringify(livingHabitEditForm)
      );

      livingHabitModule.livingHabitDrawer = true;
    },
    SavelivingHabit() {
      const param = {
        user_id: Number(ins.$route.query.user_id),
        ...livingHabitModule.livingHabitForm,
      };

      ApiPastLivingHabitsSave(param).then((res) => {
        if (res.success) {
          //
          ins.$message.success("修改成功！");
          livingHabitModule.livingHabitDrawer = false;
          // //详情页面数据接口调用
          livingHabitDetailsFun();
        }
      });
    },
    livingHabitCanelBtn() {
      ins.confirmDialogVisible = true;
    },
    livingHabitRadio(property: string, content: string) {
      if (livingHabitModule.livingHabitForm[property] == content) {
        livingHabitModule.livingHabitForm[property] = "";
      } else {
        livingHabitModule.livingHabitForm[property] = content;
      }
      livingHabitModule.livingHabitForm = {
        ...livingHabitModule.livingHabitForm,
      };
    },
  });
  return {
    livingHabitModule,
  };
}
export { livingHabitModuleFun };
