//其它治疗史
import {
  computed,
  defineComponent,
  getCurrentInstance,
  onMounted,
  reactive,
  toRefs,
  watch,
} from "@vue/composition-api";
import { ApiOtherTreatmentHistorySave } from "@/apis/index";
interface Module {
  otherTreatmentForm: object;
  test: () => void;
}
function otherTreatmentModuleFun(ins: any, MedicalHistoryDetailsFun: any) {
  const otherTreatmentModule: any = reactive({
    otherTreatmentForm: {
      data: [
        {
          histrmt_measure: "", // 治疗措施
          histrmt_date: "", // 治疗年时间
          histrmt_hospital: "", // 治疗医院
          histrmt_description: "", // 治疗描述
          histrmt_note: "", // 备注
        },
      ],
    } as any,
    treatmentMeasuresList: [
      {
        value: "毁损术",
        label: "毁损术",
      },
      {
        value: "肉毒毒素注射",
        label: "肉毒毒素注射",
      },
      {
        value: "中药针灸",
        label: "中药针灸",
      },
      {
        value: "DBS手术",
        label: "DBS手术",
      },
      {
        value: "其它",
        label: "其它",
      },
    ],
    otherTreatmentDrawer: false,
    //新增
    addOther() {
      const formRef = ins.$refs["otherTreatmentRuleForm"] as any;
      formRef.validate((valid: boolean) => {
        if (valid) {
          const arr = {
            hismed_id: "", // 一般用药史id
            user_id: Number(ins.$route.query.user_id),
            histrmt_measure: "", // 治疗措施
            histrmt_date: "", // 治疗年时间-年份
            histrmt_hospital: "", // 治疗医院
            histrmt_description: "", // 治疗描述
            histrmt_note: "", // 备注
          };
          ins.otherTreatmentForm.data.push(arr);
        }
      });
    },

    // 表单减少一行
    reduceOther(index: number) {
      const h = ins.$createElement;
      ins
        .$msgbox({
          title: "提示",
          message: h("p", undefined, [
            h("img", {
              attrs: {
                src: require("../../../../../assets/img/icon-confirm-tips.png"),
              },
            }),
            h("span", { class: "message_text" }, " 确认删除吗？ "),
          ]),
          showCancelButton: true,
          confirmButtonText: "确 认",
          cancelButtonText: "取 消",
          cancelButtonClass: "message_cancel",
          confirmButtonClass: "message_confirm",
          closeOnClickModal: false,
        })
        .then(() => {
          ins.otherTreatmentForm.data.splice(index, 1);
        })
        .catch(() => {
          console.log("取消");
        });
    },
    //抽屉open事件
    otherTreatmentClick(otherTreatmentEditForm: any) {
      otherTreatmentModule.otherTreatmentDrawer = true;
      setTimeout(() => {
        (ins.$refs.otherTreatmentRuleForm as any).clearValidate();
      }, 20);
      for (let index = 0; index < otherTreatmentEditForm.data.length; index++) {
        const Val = otherTreatmentEditForm.data[index];
        Val.user_id = Number(ins.$route.query.user_id);
        if (Val.histrmt_year != undefined && Val.histrmt_year != "") {
          Val.histrmt_date =
            new Date(Val.histrmt_year + "-" + Val.histrmt_month).getTime() + "";
        }
      }
      otherTreatmentModule.otherTreatmentForm = JSON.parse(
        JSON.stringify(otherTreatmentEditForm)
      );
    },
    //保存
    otherTreatmentConfirmBtn() {
      const formRef = ins.$refs["otherTreatmentRuleForm"] as any;

      formRef.validate((valid: boolean) => {
        if (valid) {
          const param = otherTreatmentModule.otherTreatmentForm.data;
          ApiOtherTreatmentHistorySave(param).then((res) => {
            if (res.success) {
              //
              ins.$message.success("修改成功！");
              otherTreatmentModule.otherTreatmentDrawer = false;
              // //详情页面数据接口调用
              MedicalHistoryDetailsFun();
            }
          });
        } else {
          console.log("未通过");
        }
      });
    },
    //取消
    otherTreatmentCanelBtn() {
      ins.confirmDialogVisible = true;
      setTimeout(() => {
        (ins.$refs.otherTreatmentRuleForm as any).clearValidate();
      }, 20);
    },
  });
  return {
    otherTreatmentModule,
  };
}
export { otherTreatmentModuleFun };
