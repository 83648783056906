//既往疾病史
import {
  computed,
  defineComponent,
  getCurrentInstance,
  onMounted,
  reactive,
  toRefs,
  watch,
} from "@vue/composition-api";
import {
  ApiPastMedicalHistorySave,
  ApiPastMedicalHistoryDetails,
} from "@/apis/index";
interface Module {
  previousHistoryForm: object;
}
function previousHistoryModuleFun(ins: any, MedicalHistoryDetailsFun: any) {
  const previousHistoryModule: any = reactive({
    // 表单数据
    previousHistoryForm: {
      data: [
        {
          hisdisease_name: "", //疾病名称
          hisdisease_date: "", //发病时间
          hisdisease_outcome: "", //转归
          hisdisease_pharmacy: "", //用药
          hisdisease_note: "", //备注
        },
      ],
    },
    //抽屉页中间数据
    detailForm: {},
    previousHistoryRules: {
      hisdisease_name: [
        { required: true, message: "请选择就诊时间", trigger: "blur" },
      ],
    },
    hisdiseaseNameList: [
      "高血压",
      "糖尿病",
      "心脏病",
      "甲亢",
      "麻疹",
      "脑卒中",
      "脑外伤",
      "脑炎",
      "癫痫",
      "一氧化碳(煤气)中毒",
      "其它",
    ],
    fateList: ["未知", "痊愈", "好转", "未变", "有后遗症", "恶化"],

    previousHistoryDrawer: false,

    //保存既往病例史
    savePreviousHistory() {
      const formRef = ins.$refs["previousHistoryRuleForm"] as any;
      formRef.validate((valid: boolean) => {
        if (valid) {
          const param = previousHistoryModule.previousHistoryForm.data;
          param.map((item: any) => {
            item.user_id = Number(ins.$route.query.user_id);
          });
          ApiPastMedicalHistorySave(param).then((res) => {
            if (res.success) {
              ins.$message.success("修改成功！");
              previousHistoryModule.previousHistoryDrawer = false;
              // //详情页面数据接口调用
              MedicalHistoryDetailsFun();
            }
          });
        }
      });
    },
    flag: true,
    //扩展
    addPreviousHistory() {
      const formRef = ins.$refs["previousHistoryRuleForm"] as any;
      formRef.validate((valid: boolean) => {
        if (valid) {
          const arr = {
            hisdisease_id: "",
            user_id: Number(ins.$route.query.user_id),
            hisdisease_name: "", //疾病名称
            hisdisease_date: "", //发病时间
            hisdisease_outcome: "", //转归
            hisdisease_pharmacy: "", //用药
            hisdisease_note: "", //备注
          };
          ins.previousHistoryForm.data.push(arr);
        }
      });
    },
    // 表单减少一行
    reducePreviousHistory(index: number) {
      const h = ins.$createElement;
      ins
        .$msgbox({
          title: "提示",
          message: h("p", undefined, [
            h("img", {
              attrs: {
                src: require("../../../../../assets/img/icon-confirm-tips.png"),
              },
            }),
            h("span", { class: "message_text" }, " 确认删除吗？ "),
          ]),
          showCancelButton: true,
          confirmButtonText: "确 认",
          cancelButtonText: "取 消",
          cancelButtonClass: "message_cancel",
          confirmButtonClass: "message_confirm",
          closeOnClickModal: false,
        })
        .then(() => {
          ins.previousHistoryForm.data.splice(index, 1);
        })
        .catch(() => {
          console.log("取消");
        });
    },
    //弹窗打开，数据回显操作
    previousHistoryClick(previousHistoryEditForm: any) {
      setTimeout(() => {
        (ins.$refs.previousHistoryRuleForm as any).clearValidate();
      }, 20);
      for (let i = 0; i < previousHistoryEditForm.data.length; i++) {
        const item = previousHistoryEditForm.data[i];
        const date =
          item.hisdisease_year +
          "-" +
          item.hisdisease_month +
          "-" +
          item.hisdisease_day;
        item.hisdisease_date = new Date(date).getTime(); // 年月日转化成时间戳
      }
      previousHistoryModule.previousHistoryForm = JSON.parse(
        JSON.stringify(previousHistoryEditForm)
      );
      // previousHistoryModule.detailForm = JSON.parse(
      //   JSON.stringify(previousHistoryEditForm)
      // );
      previousHistoryModule.previousHistoryDrawer = true;
    },

    previousHistoryCanelBtn() {
      ins.confirmDialogVisible = true;
      setTimeout(() => {
        (ins.$refs.previousHistoryRuleForm as any).clearValidate();
      }, 20);
    },
  });
  return {
    previousHistoryModule,
  };
}
export { previousHistoryModuleFun };
