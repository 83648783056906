



























































































































































































































































import { Component, Vue } from "vue-property-decorator";
import AddMedicalRecord from "@/views/EleMediRecord/Scale/Details/medicalRecords/AddMedicalRecord.vue";
import InfoMedicalRecord from "@/views/EleMediRecord/Scale/Details/medicalRecords/InfoMedicalRecord.vue";
import {
  getRecordInfo,
  editChiefComplaint,
  delMedicalRecordDetial,
  medicalArchived,
} from "@/apis";
import { Form } from "element-ui";

@Component({
  components: {
    AddMedicalRecord,
    InfoMedicalRecord,
  },
})
export default class CaseRecord extends Vue {
  // 页面显隐属性
  caseRecordType = "card";

  medicalRecordHisId = "";
  archived = false;
  userTrmtchiefFrom = {
    trmtchief_type: "",
    trmtchief_type2: "",
    trmtchief_date: "",
    trmtchief_doctor: "",
  } as any;
  get userId(): any {
    return this.$route.query.user_id;
  }
  chiefComplaintRules = {
    trmtchief_type: [
      { required: true, message: "请选择就诊类型1", trigger: "change" },
    ],
    trmtchief_type2: [
      { required: true, message: "请选择就诊类型2", trigger: "change" },
    ],
    trmtchief_date: [
      { required: true, message: "请选择就诊时间", trigger: "change" },
    ],
  };
  dialogAdd = false;
  addMedicalRecordClick() {
    // this.caseRecordType = "addMedicalRecord";
    this.dialogAdd = true;
    setTimeout(() => {
      (this.$refs.userTrmtchiefFrom as Form).clearValidate();
    }, 10);

    this.userTrmtchiefFrom = {
      trmtchief_type: "",
      trmtchief_type2: "",
      trmtchief_date: "",
      trmtchief_doctor: "",
    };
  }
  delMedicalRecordClick(item: any) {
    if (item.archived) {
      this.$message.error("该记录已归档，无法删除！");
      return;
    }
    this.$confirm("删除后录入的数据不可恢复，确认删除吗？", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    }).then(async () => {
      const res = await delMedicalRecordDetial(item.trmtchief_id);
      if (res.success) {
        this.$message.success("删除成功！");
        this.getVisitRecordList();
      } else {
        this.$message.error(res.message);
      }
    });
  }
  cancelInfo() {
    this.dialogAdd = false;
  }
  watchMedicalRecordDetial(item: any) {
    this.medicalRecordHisId = item.trmtchief_id;
    this.archived = item.archived;
    this.caseRecordType = "infoMedicalRecord";
  }
  confirmArchived(item: any) {
    this.$confirm("归档后将无法编辑/删除患者就诊详情，确认归档吗？", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    }).then(async () => {
      const res = await medicalArchived(item.trmtchief_id, this.userId);
      if (res.success) {
        this.$message.success("归档成功");
        this.getVisitRecordList();
      } else {
        this.$message.error(res.message);
      }
    });
  }
  async addFormFunTotal() {
    (this.$refs.userTrmtchiefFrom as Form).validate(async (val: any) => {
      if (val) {
        const params = {
          ...this.userTrmtchiefFrom,
          user_id: this.userId,
        };
        const res = await editChiefComplaint(params);
        if (res.success) {
          this.$message.success("添加成功");
          this.dialogAdd = false;
          (this.$refs.userTrmtchiefFrom as Form).clearValidate();
          Object.keys(this.userTrmtchiefFrom).map((item: any) => {
            this.userTrmtchiefFrom[item] = "";
          });
          this.getVisitRecordList();
        }
      }
    });
  }

  backCaseRecord() {
    this.caseRecordType = "card";
    this.getVisitRecordList();
  }
  dash(data: any) {
    if (data == null || data == "") {
      data = "-";
    }
    return data;
  }

  textShow(item: any) {
    if (item.trmtchief_type2 == "首诊" || item.trmtchief_type2 == "其他") {
      return "主诉：";
    } else {
      return "病情变化：";
    }
  }

  // 就诊记录卡片信息
  visitRecordList: Array<any> = [];
  // 获取就诊卡片信息
  async getVisitRecordList() {
    try {
      const result = await getRecordInfo(this.userId);
      if (result.success) {
        this.visitRecordList = result.data;
      }
    } catch (error) {
      console.log(error);
    }
  }

  formatDate(timeStamp: number | string | null) {
    if (timeStamp == null) {
      return "-";
    }
    const time = new Date(timeStamp);
    //年-月-日
    return `${time.getFullYear()}-${this.format(
      time.getMonth() + 1
    )}-${this.format(time.getDate())}`;
  }

  format(date: number | string) {
    date = date.toString();
    return ("0" + date).slice(-2);
  }

  created() {
    this.getVisitRecordList();
  }
}
