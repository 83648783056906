// 症状诊断
import { reactive } from "@vue/composition-api";
import {
  ApiSymptomDiagnosisSave,
  ApiPastMedicalHistorySave,
  ApiPastMedicalHistoryDetails,
} from "@/apis/index";
import { Form } from "element-ui";
interface Module {
  symptomDiagnosisForm: object;
}
function symptomDiagnosisModuleFu(ins: any, symptomDiagnosisDetailsFun: any) {
  const symptomDiagnosisModule: any = reactive({
    chiefComplaintId: null,
    // 表单数据
    symptomDiagnosisForm: {
      data: [
        {
          chatidiag_id: "",
          user_id: "",
          chatidiag_type: "", // 诊断
          chatidiag_second: "", // 二级诊断
          chatidiag_third: "", // 三级诊断
          chatidiag_date: "", // 诊断日期
          chatidiag_doctor: "", // 诊断医生
          chatidiag_isclear: "", // 诊断是否明确
          chatidiag_sign1: "", // 标记：震颤
          chatidiag_sign2: "", // 标记：强直
          chatidiag_sign3: "", // 标记：认知
          chatidiag_sign4: "", // 标记：疗效减退
          chatidiag_sign5: "", // 标记：运动并发症
          chatidiag_sign6: "", // 标记：DBS术后
          chatidiag_sign7: "", // 标记：病程
          chatidiag_note: "", // 备注
        },
      ],
    },
    //抽屉页中间数据
    detailForm: {},
    symptomDiagnosisRules: {
      chatidiag_type: [
        { required: true, message: "请选择诊断类型", trigger: "blur" },
      ],
    },
    // 一级诊断
    diagnosisType: [
      "原发性帕金森病",
      "帕金森叠加综合征",
      "继发性帕金森综合征",
      "遗传性帕金森综合征",
      "原发性震颤",
      "其它",
    ],
    // 二级诊断 下拉框
    diagnose1: [
      "⽆体征",
      "1.0=单侧患病",
      "1.5=单侧患病,并影响到中轴的肌⾁",

      "2.0=双侧患病,为损害平衡",

      "2.5=轻度双侧患病,姿势反射稍差,但是能自己纠正",

      "3.0=双侧患病,有姿态平衡障碍,后拉试验阳性",

      "4.0=严重的残疾,但是能⾃⼰站⽴或者行走",

      "5.0=不能起床,或⽣活在轮椅上",

      "其它",
    ],
    diagnose2: [
      "进⾏性核上性⿇痹",
      "MSA-C",
      "MSA-P",
      "⽪质基底节变性",
      "Shy-Darger综合征",
      "其它",
    ],
    diagnose3: [
      "脑外伤后",
      "脑⾎管",
      "脑炎后",
      "毒物性",
      "药物性",
      "代谢性",
      "其它",
    ],
    // 是否是这三种病历是打开二级诊断下拉框
    secondaryDiagnosis(item: any) {
      let result = [];
      if (item.chatidiag_type == "原发性帕金森病") {
        result = ins.diagnose1;
      }
      if (item.chatidiag_type == "帕金森叠加综合征") {
        result = ins.diagnose2;
      }
      if (item.chatidiag_type == "继发性帕金森综合征") {
        result = ins.diagnose3;
      }
      return result;
    },

    //关闭症状诊断抽屉
    symptomDiagnosCanelBtn() {
      ins.confirmDialogVisible = true;
    },

    symptomDiagnosDrawer: false,

    // 打开症状诊断抽屉
    // openSymptomDiagnos() {

    // },

    // 诊断下拉框的changge
    selectChange(index: number) {
      symptomDiagnosisModule.symptomDiagnosisForm.data[index].chatidiag_second =
        "";
    },

    // 症状诊断的删除
    deleteSymptomDiagnosisFormItem(index: number) {
      const h = ins.$createElement;
      ins
        .$msgbox({
          title: "提示",
          message: h("p", undefined, [
            h("img", {
              attrs: {
                src: require("../../../../../assets/img/icon-confirm-tips.png"),
              },
            }),
            h("span", { class: "message_text" }, " 确认删除吗？ "),
          ]),
          showCancelButton: true,
          confirmButtonText: "确 认",
          cancelButtonText: "取 消",
          cancelButtonClass: "message_cancel",
          confirmButtonClass: "message_confirm",
          closeOnClickModal: false,
        })
        .then(() => {
          ins.symptomDiagnosisForm.data.splice(index, 1);
        })
        .catch(() => {
          console.log("取消");
        });
    },

    // 新增症状诊断项
    addSymptomDiagnosisFormItem() {
      const formRef = ins.$refs.symptomDiagnosisRuleForm as Form;
      formRef.validate((valid: boolean) => {
        if (valid) {
          ins.symptomDiagnosisForm.data.push({
            user_id: "",
            trmtchief_id: "",
            chatidiag_id: null,
            chatidiag_doctor: "",
            chatidiag_type: "",
            chatidiag_second: "",
            chatidiag_third: "",
            chatidiag_date: "",
            chatidiag_isclear: "",
            chatidiag_sign1: "",
            chatidiag_sign2: "",
            chatidiag_sign3: "",
            chatidiag_sign4: "",
            chatidiag_sign5: "",
            chatidiag_sign6: "",
            chatidiag_sign7: "",
            chatidiag_note: "",
          });
        }
      });
    },

    //保存症状诊断
    onSaveSymptomDiagnos() {
      const formRef = ins.$refs["symptomDiagnosisRuleForm"] as any;
      formRef.validate((valid: boolean) => {
        if (valid) {
          const param = symptomDiagnosisModule.symptomDiagnosisForm.data;
          param.map((item: any) => {
            item.user_id = Number(ins.$route.query.user_id);
          });
          ApiSymptomDiagnosisSave(param).then((res) => {
            if (res.success) {
              ins.$message.success("修改成功！");
              ins.symptomDiagnosDrawer = false;
              // //详情页面数据接口调用
              symptomDiagnosisDetailsFun();
            }
          });
        }
      });
    },

    formatDate(timeStamp: number | string | null | undefined) {
      if (timeStamp == null || timeStamp == undefined) {
        return "-";
      }
      const time = new Date(timeStamp);
      //年-月-日
      return `${time.getFullYear()}-${this.format(
        time.getMonth() + 1
      )}-${this.format(time.getDate())}`;
    },

    format(date: number | string) {
      date = date.toString();
      return ("0" + date).slice(-2);
    },
    flag: true,

    // 底部单选的click事件
    symptomDiagnosisRadio(property: string, content: string, index: number) {
      if (
        symptomDiagnosisModule.symptomDiagnosisForm.data[index][property] ==
        content
      ) {
        symptomDiagnosisModule.symptomDiagnosisForm.data[index][property] = "";
      } else {
        symptomDiagnosisModule.symptomDiagnosisForm.data[index][property] =
          content;
      }
      symptomDiagnosisModule.symptomDiagnosisForm = {
        ...symptomDiagnosisModule.symptomDiagnosisForm,
      };
    },

    //弹窗打开，数据回显操作
    symptomDiagnosisClick(previousHistoryEditForm: any, id: any) {
      symptomDiagnosisModule.symptomDiagnosisForm = JSON.parse(
        JSON.stringify(previousHistoryEditForm)
      );
      symptomDiagnosisModule.symptomDiagnosisForm.data.forEach((item: any) => {
        item.user_id = id;
      });
      symptomDiagnosisModule.symptomDiagnosDrawer = true;
    },

    symptomDiagnosisCanelBtn() {
      ins.confirmDialogVisible = true;
    },
  });
  return {
    symptomDiagnosisModule,
  };
}
export { symptomDiagnosisModuleFu };
